import { Button, Col, Form, Input, Row, Select, Spin, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { ViewGameList } from 'components/advanced.component';
import { InquiryWindow, LayoutNav, LayoutPagination } from 'components/layout.component';
import { GameCategorySelect } from 'components/member.component';
import { RESPONSE_CODE_GAME_UPDATE } from 'constants/response';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { selectFuzzyFilter, specialProviderName } from 'utils/common';

// 遊戲管理

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const [form] = useForm();
  const [isRefresh, setIsRefresh] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState({});
  const [selectProvider, setSelectProvider] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [batchUpdateValue, setBatchUpdateValue] = useState<number | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { data: provider } = $get({ url: 'api/provider/code/list' });
  const { data: gameList, isValidating, mutate } = $get({
    url: 'api/game/cc/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  useEffect(() => {
    setSelectProvider('');
  }, []);

  useEffect(() => {
    if (provider) {
      form.setFieldsValue({
        gameProvider: null,
        gameCategory: null,
        gameCode: null,
      })
    }
  }, [provider])

  const onFinish = (formData: any) => {
    setParams({
      ProviderCode: formData.gameProvider,
      CategoryCode: formData.gameCategory,
      Code: formData.gameCode
    });
    setPage([1, page[1]]);
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setSelectProvider('');
    setBatchUpdateValue(null);
    setSelectedRowKeys([]);
  }

  const onRefresh = () => {
    $post({
      url: `api/game/${selectProvider.toLowerCase()}/refresh`,
      send: {},
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
    }, setIsRefresh)
  }

  const onBatchUpdate = (select: number) => {
    setBatchUpdateValue(select);
    setIsOpenModal(!isOpenModal);
  }

  const batchUpdateSubmit = () => {
    $post({
      url: 'api/game/cc/batch/update',
      send: {
        Id: selectedRowKeys,
        Status: batchUpdateValue
      },
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_GAME_UPDATE
    })
  }

  const onClose = () => {
    setIsOpenModal(false);
    setSelectedRowKeys([]);
    setBatchUpdateValue(null);
  }

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish}>
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item className="w-12" name="gameProvider">
                <Select
                  showSearch
                  filterOption={selectFuzzyFilter}
                  placeholder={i18n.t('gameProvider')}
                  onChange={setSelectProvider}
                  options={provider && [
                    { value: '', label: i18n.t('ALL') },
                    ...provider?.Data.map((item: any) => ({
                      value: item.ProviderCode,
                      label: specialProviderName(item.ProviderName, site.SiteName, item.ProviderCode)
                    }))]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="gameCategory">
                <GameCategorySelect name="gameCategory" form={form} code={true} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="gameCode">
                <Input placeholder={`${i18n.t('gameCode')}`} />
              </Form.Item>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" loading={isValidating}>
                {i18n.t('search')}
              </Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
            {/* 批次更新 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                {/* <Col>
                  <Button type="primary" onClick={onRefresh} loading={isRefresh}
                    disabled={!selectProvider}>
                    {i18n.t('refresh')}{selectProvider}{i18n.t('game')}
                  </Button>
                </Col> */}
                <Col>
                  <Select
                    className="w-8"
                    placeholder={i18n.t('batchUpdateStatus')}
                    onChange={onBatchUpdate}
                    value={batchUpdateValue}
                    disabled={selectedRowKeys.length === 0}
                    options={[
                      { label: 'ON', value: 1 },
                      { label: 'OFF', value: 0 },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewGameList data={gameList} mutate={mutate} selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys} />
                <LayoutPagination total={gameList && gameList.TotalRecord} page={page} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>

      <InquiryWindow isOpen={isOpenModal} close={onClose} action={batchUpdateSubmit}
        msg={`${i18n.t('confirmationBatch')} ${batchUpdateValue ? i18n.t('release') : i18n.t('disable')} ${selectedRowKeys && selectedRowKeys.length} ${i18n.t('counts')}${i18n.t('isData')}`} />
    </div>
  );
};

export default PageMain;