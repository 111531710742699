// 狀態
export enum MEMBER_STATE {
  waitApproval = 0,
  approvaled = 1
}
export enum MEMBER_FREEZE {
  normal = 2,
  frozen = 3
}
export enum MEMBER_FREEZE_REPORT {
  normal = 0,
  frozen = 1
}
export enum AGENT_STATE {
  waitApproval = 0,
  normal = 1,
  frozen = 2
}
export enum AGENT_ADJUST_STATE {
  waitApproval = 0,
  approvaled = 1,
  reject = 2,
  fail = 3
}
export enum DISCOUNT_TYPE {
  waitApproval = 0,
  approvaling = 1,
  rejected = 2,
  dispatched = 3,
  received = 4,
  duplicateReceipt = 5
}
export enum PROMOTION_VIP_STATE {
  notDispatch = 0,
  dispatched = 1,
  received = 2
}
export enum PROMOTION_REBATE_STATE {
  pendingDistribution = 0,
  reject = 2,
  dispatched = 3,
  received = 4
}
export enum PROMOTION_JACKPOT_STATE {
  dispatched = 1,
  received = 4,
}
export enum AGENT_WITHDRAW_STATE {
  pendingDistribution = 0,
  distributing = 1,
  reject = 2,
  dispatched = 3,
  received = 4
}

export enum WITHDRAW_STATE {
  pendingServiceReview = 0,
  pendingSupervisorReview = 1,
  pendingFinancialReview = 2,
  financialReview = 9,
  processing = 3,
  paymentTerminalWaiting = 4,
  approvaled = 5,
  fail = 6,
  reject = -2
}
export enum DEPOSIT_STATE {
  pendingFinancialReview = 0,
  approvaled = 1,
  reject = 7,
  processing = 2,
  paying = 3,
  success = 5,
  fail = 6
}
export enum SEARCH_DATE_TYPE {
  createTime = 0,
  auditTime = 1
}
export enum MESSAGE_STATE {
  notRead = 0,
  alreadyRead = 1
}
export enum REPORT_STATE {
  notSettle = 0,
  settled = 1,
  unsettledCancellation = -1,
  settledCancellation = 2
}
export enum FRONT_COPY_TYPE {
  qa = 1,
  registerGamble = 2,
  registerTerms = 3,
  promotionPromotion = 4,
  promotionReward = 6,
  privacy = 5
}
export enum BONUS_TICKET_TYPE {
  dddPromotion = 1,
  deductPromotion = 2,
  systemDistribution = 3,
  manualDispatch= 4
}
export enum MEMBER_BANK_STATE {
  verificationFailed = -1,
  waitVerify = 0,
  verificationSuccessfulOpen = 1,
  verificationSuccessfulClose = 2,
  delete = 9
}
export enum PERMISSION_STATE {
  enabled = 0,
  disabled = 1
}
export enum ENABLE {
  disabled = 0,
  enabled = 1
}
export enum TRANSFER_SETTING_STATUS {
  disable = 0,
  enabled = 1,
  delete = -1
}
export enum ADJUST_TYPE {
  addBalance = 1,
  deductBalance = -1
}
export enum GATEWAY_TYPE {
  thirdParty = 1,
  transfer = 2
}
export enum IS_OFFLINE {
  thirdParty = 0,
  transfer = 1
}
export enum MERCHANT_TYPE {
  withdraw = 1,
  deposit = 2
}
export enum TRANSFER_TYPE {
  deposit = 1,
  withdrawal = 2
}
export enum SOURCE_ROLE {
  member = 0,
  agent = 1,
  company = 2
}
export enum CONTACT_INFO {
  Line,
  WeChat,
  QQ,
  Zalo,
  Messenger,
  Whatsapp,
  Telegram
}
export enum SEO_PAGE {
  home = 0,
  discountActivity = 1
}
export enum SLIDE_TYPE {
  pcVersion = 1,
  mobileVersion = 2
}
export enum SETTLEMENT {
  revenue = 1,
  revenueshare = 2
}
export enum MODE {
  add = 1,
  edit = 2,
  detail = 3,
  review = 4
}
export enum CHECKBOX_DISPLAY {
  empty = 0,
  square = 1,
  tick = 2
}
export enum PROMOTION_TIME {
  eventTime = 0,
  createTime = 1
}
export enum UPDATE_TAG_TYPE {
  delete = 1,
  add = 2
}

export enum PROMOTION_CYCLE {
  promotionPeriod = 0,
  everyDay = 1,
  everyWeek = 2
}

export enum PROMOTION_ONE_WEEK_CYCLE {
  monday = 1,
  tuesday = 2,
  wednesday = 3,
  thursday = 4,
  friday = 5,
  saturday = 6,
  sunday = 7,
}
export enum REDENVELOPE_CYCLE {
  everyDay = 1,
  everyWeek = 2
}
export enum REBATE_PERIOD {
  dailyStatement = 0,
  weeklyStatement = 1,
  monthlySettlement = 2
}
export enum STATE {
  pass = 1,
  reject = 2,
  padding = 3
}

export enum SMS_STATE {
  processing = 0,
  success = 1,
  fail = 2,
}
export enum WHETHER {
  yes = 1,
  none = 0
}

// 登入記錄需求目前只有存登入成功的帳號，如之後有登入失敗也需顯示的需求再另行開發
export enum LOGIN_RECORD_STATE {
  success = 1,
  fail = 2,
}

export enum Game_STATE {
  disabled = 0,
  enabled = 1
}

export enum Game_ISOPEN {
  disable = 0,
  shelved = 1
}

export enum MESSAGE_TEMPLATE_STATE {
  enabled = 1,
  disabled = 0
}

export enum PEOPLE_AGENT_OPERATE_STATUS {
  idle = 0,
  executing = 1,
  recountResultUnread = 2
}

export enum COMMISSION_SETTLE_CYCLE {
  oneDay = 0,
  oneWeek = 1,
  oneMonth = 2
}

export enum MEMBER_GROUP_LOCK_STATUS {
  unlock = 0,
  lock = 1
}

export enum MEMBER_GROUP_LOCK_STATUS_YES_NONE {
  none = 0,
  yes = 1,
}

export enum PEOPLE_AGENT_STATUS {
  delete = -1,
  close,
  open
}

export enum RECEIVE_COMMISSION_STATUS {
  canCollectRebate = 1,
  cannotCollectRebate = 0
}

export enum ROLLOVER_STATE {
  notPass = 0,
  pass = 1,
  passManualRelease = 2,
}