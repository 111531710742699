import i18n from 'i18n';
import { Form, InputNumber } from 'antd';
import { AMOUNT_MAX } from 'constants/system';
import { verify } from 'utils/common';

// 表單元件標準化 全站一致
// 參數只能是樣式(className)跟禁用(disabled) 如果限制條件也客製化就沒有標準化意義

// 排序
export const FormInputPosition = (
  { className = 'w-full', disabled }: 
  { className?:string, disabled?: boolean }
) => {
  return (
    <Form.Item name="Position" label={i18n.t('sort')} className={className}
      rules={[...verify({ point: 0 }), { max: 99, type: 'number', message: `${i18n.t('maximumValueIs99')}` }]}
    >
      <InputNumber className={className} placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
    </Form.Item>
  )
}

// 每日簽到: 優惠金額
export const FormInputPromotionAmount = (
  { name, className = 'w-full', disabled }: 
  { name?: string, className?:string, disabled?: boolean }
) => {
  return (
    name ?
    <Form.Item name={name} rules={verify({ zero: false, point: 4, max: AMOUNT_MAX })}>
      <InputNumber className={className} placeholder={`${i18n.t('inputData')}`} disabled={disabled} />
    </Form.Item>
    : <></>
  )
}
