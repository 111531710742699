import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import type { ActionFunctionArgs } from "react-router-dom";
import { useRouteError } from "react-router-dom";

import PageFinanceAdjust from "pages/admin/finance/adjust";
import PageFinanceDeposit from "pages/admin/finance/deposit";
import PageFinancePayment from "pages/admin/finance/payment";
import PageFinancePaymentDepositTotal from "pages/admin/finance/payment-depositTotal";
import PageFinancePaymentWithdrawTotal from "pages/admin/finance/payment-withdrawTotal";
import PageFinanceTransfer from "pages/admin/finance/transfer";
import PageFinanceTransferDepositTotal from "pages/admin/finance/transfer-depositTotal";
import PageFinanceTransferWithdrawTotal from "pages/admin/finance/transfer-withdrawTotal";
import PageFinanceWithdraw from "pages/admin/finance/withdraw";
import PageFinanceGateway from "pages/admin/finance/gateway";
import PageFinanceRollover from "pages/admin/finance/rollover";

async function loader() {
  try {

    return {
      test: {}
    };

  } catch (e) {
    throw new Error("無法取得loader資料");
  };
};

async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  switch (formData.get("action")) {
    case "add":
      break;
    case "delete":
      break;

    default:
      break;
  }

  return { ok: true };
}

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      {/*<LayoutNav />*/}
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const financeModule = [
  {
    path: '/', ErrorBoundary,
    children: [
      { path: '/finance/rollover', element: <PageFinanceRollover/> },
      { path: '/finance/withdraw', element: <PageFinanceWithdraw/> },
      { path: '/finance/deposit', element: <PageFinanceDeposit/> },
      { path: '/finance/adjust', element: <PageFinanceAdjust/> },
      { path: '/finance/payment', element: <PageFinancePayment/> },
      { path: '/finance/payment-depositTotal/:id', element: <PageFinancePaymentDepositTotal/> },
      { path: '/finance/payment-withdrawTotal/:id', element: <PageFinancePaymentWithdrawTotal/> },
      { path: '/finance/transfer', element: <PageFinanceTransfer/> },
      { path: '/finance/transfer-depositTotal/:id', element: <PageFinanceTransferDepositTotal/> },
      { path: '/finance/transfer-withdrawTotal/:id', element: <PageFinanceTransferWithdrawTotal/> },
      { path: '/finance/gateway/:id?', element: <PageFinanceGateway/> },
    ],
  },
]