import { message } from 'antd';
import { RESPONSE_CODE } from 'constants/response';
import i18n from 'i18n';
import QueryString from 'qs';
import Cookies from "js-cookie";
import { COOKIE } from "constants/cookie";
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

// 是否啟用mock(全站)

const MOCK_ENABLE = 0;

const requestInformation = (data: any) => {
  const query = QueryString.stringify(data, { addQueryPrefix: true });
  const token = Cookies.get(COOKIE.TOKEN);
  const headers: any = {
    'Content-Type': 'application/json'
  }
  if (token) headers.Authorization = `${token}`;
  return {
    query,
    headers,
    token
  }
}

// 回應狀態碼判斷
const responseProcessing = async (response: any) => {
  const body = await response.text();
  const bodyObject = JSON.parse(body)
  const res: any = body ? bodyObject : {};
  return res;
}

// 錯誤訊息及處理
const errorAlert = (errorCode: string, resCode: any = {}, showMessage: boolean) => {
  
  // 白名單
  if (errorCode === 'WHITEIP_FORBIDDEN') {
    window.location.href = '/#/error';
    return;
  }
  // 沒有權限
  else if (errorCode === 'PERMISSION_DENIED') {
    window.location.href = '/#/';
    return;
  }
  else if (errorCode === 'UNAUTHORIZATION') {
    window.location.href = '/#/login/UNAUTHORIZATION';
    Cookies.remove(COOKIE.TOKEN);
    return;
  }
  else if (errorCode === 'ACCOUNT_BLOCKED') {
    if (window.location.hash.includes('#/login')) {
      if (showMessage) message.error(RESPONSE_CODE['ACCOUNT_BLOCKED']);
      return;
    } else {
      window.location.href = '/#/login/ACCOUNT_BLOCKED';
      Cookies.remove(COOKIE.TOKEN);
      return;
    }
  }

  const codeI18n: any = {
    ...RESPONSE_CODE,
    ...resCode
  }

  if (showMessage) message.error(codeI18n[errorCode] || i18n.t('incorrectRequest'));
}

interface GetParameters {
  url: string;
  params?: Record<string, any>;
  allow?: boolean;
  showMessage?: boolean;
  resCode?: Record<string, any>;
  auth?: boolean;
  fail?: (data: any) => void;
  success?: (data: any) => void;
  mock?: () => GetResponse;
}

interface GetResponse {
  Data: any[];
  State: string;
  TotalRecord: number;
  Message?: string;
}

type Swr = {
  data?: any;
  isValidating: boolean;
  mutate: () => void;
}

export const $get = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
  mock
}: GetParameters): Swr => {

  const { query, headers, token } = requestInformation(params);

  return useSWR(allow && ((auth && token) || (!auth)) ? `/${url}${query}` : null, async () => {
    return fetch(`/${url}${query}`, {
      method: 'GET',
      headers
    })
      .then(async response => {
        let res = await responseProcessing(response);

        if (MOCK_ENABLE && process.env.NODE_ENV === 'development' && mock) res = mock();

        // 成功
        if (res.State === "Success") {
          success(res);
          const result: GetResponse = res;
          return result;

        // 失敗
        } else {
          errorAlert(res.Message, resCode, showMessage);
          fail(res);
          const result: GetResponse = res;
          return result;
        }
      })

  }, { revalidateOnFocus: false });
}

// 不使用useSWR的get 還在研發中 別使用
// 如果從同一個hook呼叫 需要cache
export const $get2 = ({
  url = '',
  params = {},
  allow = true,
  showMessage = true,
  resCode = {},
  auth = true,
  fail = (data: any) => {},
  success = (data: any) => {},
  cache,
  mock
}: any): Swr => {

  const { query, headers, token } = requestInformation(params);

  const [data, setData] = useState();
  const [isValidating, setIsValidating] = useState(false);
  const [timestamp, setTimestamp] = useState<number>(new Date().getTime());

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      get();
    }
  }, []);

  useEffect(() => {
    if (allow && timestamp) {
      get();
    }
  }, [allow, timestamp]);

  function get() {
    if (allow && ((auth && token) || (!auth))) {
      setIsValidating(true);

      fetch(`/${url}${query}`, {
        method: 'GET',
        credentials: 'omit',
        headers
      })
      .then(async response => {
        let res = await responseProcessing(response);

        if (MOCK_ENABLE && process.env.NODE_ENV === 'development' && mock) res = mock();

        // 成功
        if (res.State === "Success") {
          success(res);
          const result: any = {
            Data: res,
          }
          setData(result.Data);
    
        // 失敗
        } else {
          errorAlert(res.Message, resCode, showMessage);
          fail(res);
          const result: any = {
            Data: [],
          }
          setData(result.Data);
        }
  
        setIsValidating(false);
      })
    }
  }

  return {
    data,
    isValidating,
    mutate: () => setTimestamp(new Date().getTime())
  }
}

interface PostParameters {
  url: string;
  send?: Record<string, any>; 
  allow?: boolean;
  success?: (data: any) => void; 
  fail?: (data: any) => void;
  showMessage?: boolean; 
  resCode?: Record<string, any>; 
  auth?: boolean;
  queryHeader?: boolean;
  mock?: () => void;
}

export const $post = async (
  {
    url,
    send = {},
    showMessage = true,
    resCode = {},
    queryHeader = false,
    success = (data: any) => {},
    fail = (data: any) => {},
    mock
  }: PostParameters,
  setLoading = (b: boolean) => {}
) => {

  setLoading(true);

  const { query, headers } = requestInformation(send);

  let res = await fetch(`/${url}${queryHeader ? query : ''}`, {
    method: 'POST',
    credentials: 'omit',
    headers,
    body: JSON.stringify(send)
  })
    .then(async response => await responseProcessing(response))

  if (MOCK_ENABLE && process.env.NODE_ENV === 'development' && mock) res = mock();

  if (res.State === "Success") {
    success(res);
  } else {
    errorAlert(res.Message, resCode, showMessage);
    fail(res);
  }

  setLoading(false);
}
