export enum CHAT_TYPE {
  waiting = '1',
  myConversation = '2',
  colleagueConversation = '3',
}
export enum SERVICE_STATUS {
  online = 1,
  offline = 0
}
export enum SERVICE_NOTIFY {
  enableNotifications = 1,
  disableNotifications = 0
}
export enum CHAT_ROOM {
  own = 1,
  colleague = 2
}
export enum AVATAR_TYPE {
  customAvatar = 1,
  defaultAvatar = 0
}