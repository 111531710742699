export enum BONUS_CATEGORY {
  referralReward = 1,
  dailyCheck = 2,
  depositPromotions = 4,
  firstDepositPromotions = 11,
  otherPromotions = 99,
  // jackpot = 5,
  redEnvelopePromotions = 6,
  couponOffer = 7,
  signupBonus = 8,
  allAgent = 9
}
export enum BONUS_TYPE {
  dailyCheck = 1,
  referralReward = 3,
  promotionAdjustment = 4,
  upgradeBonus = 5,
  weeklyBonus = 6,
  amountMonthly = 7,
  otherPromotions = 8,
  depositPromotions = 10,
  firstDepositPromotions = 17,
  redEnvelopePromotions = 12,
  rebate = 9,
  // jackpot = 11,
  signupBonus = 14,
  couponOffer = 13,
  personalRebate = 15,
  rebateForDirectDownlineValidMembers = 16
}
export enum PROMOTION_STATE {
  shelved = 1,
  notShelved = 0,
}
export enum SETTLE_CYCLE {
  dailyStatement = 0,
  weeklyStatement = 1,
  monthlySettlement = 2
}
export enum PROMOTION_TYPE {
  otherPromotions = 1,
  firstDepositPromotions = 2,
  redEnvelopePromotions = 3,
  depositPromotions = 4,
}
export enum PRIZE_TYPE {
  grandPrize = 0,
  generalPrize = 1
}