import { Button, Col, Form, Input, Row, Select, Space, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PopupDepositAudit, PopupDepositInfo } from 'components/finance.component';
import { DatePickerCol, Export, LayoutNav, LayoutPagination, LayoutUpdateControl } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import { WALLET_TRANSLATE } from 'constants/gateway';
import { $get } from 'services';
import { timeL2S, timeS2L } from "utils/common";
import { DATE_RANGE_LIMIT } from 'enum/date';
import { DEPOSIT_STATE, SEARCH_DATE_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import * as common from 'utils/common';

// 財務: 入款查詢

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState('CreateStartDate');
  const [endDate, setEndDate] = useState('CreateEndDate');
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [id, setId] = useState(0);
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [params, setParams] = useState<any>({
    CreateStartDate: timeL2S(date[0]),
    CreateEndDate: timeL2S(date[1])
  })

  const { data: depositList, isValidating, mutate } = $get({
    url: 'api/depositticket/member/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const defaultDate = SEARCH_DATE_TYPE.createTime;
  const handleDateType = (type: number) => {
    if (type === defaultDate) {
      setStartDate('CreateStartDate');
      setEndDate('CreateEndDate');
    } else {
      setStartDate('AuditedStartDate');
      setEndDate('AuditedEndDate');
    }
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Account: formData.account,
        UpMemberAccount: formData.upMemberAccount,
        Code: formData.code,
        Status: formData.status,
        GatewayId: formData.depositmerchantName,
        AGAccount: formData.agAccount,
        [startDate]: timeL2S(date[0]),
        [endDate]: timeL2S(date[1]),
      })
    }
    mutate();
  }

  const onClear = () => {
    setReset(!reset);
    form.resetFields();
    setStartDate('CreateStartDate');
    setEndDate('CreateEndDate');
    setPage([1, 10]);
  }

  const handleInfo = (id: number) => {
    setId(id);
    setIsOpenInfo(true);
  }

  const handleDeposit = (id: number) => {
    setId(id);
    setIsOpenDeposit(true);
  }

  const stateColor = (type: number) => {
    switch (type) {
      case DEPOSIT_STATE.success:
      case DEPOSIT_STATE.approvaled:
        return 'color-pass'
      case DEPOSIT_STATE.processing:
        return 'color-padding'
      case DEPOSIT_STATE.paying:
        return 'color-up'
      case DEPOSIT_STATE.pendingFinancialReview:
        return 'color-reviewing'
      case DEPOSIT_STATE.fail:
      case DEPOSIT_STATE.reject:
        return 'color-reject'
    }
  }

  const { data: gatewayList } = $get({
    url: 'api/gateway/all/list',
    params: { Type: 0 }
  })

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: defaultDate
        }}>
          <Row align="middle" gutter={10}>
            <Col className="w-12">
              <Form.Item name="code" rules={[{
                type: 'string',
                min: 25,
                message: `${i18n.t('orderNumberMustBe25Characters')}`
              }]}>
                <Input maxLength={25} placeholder={`${i18n.t('order')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="account">
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="depositmerchantName">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('depositMethod')}`}
                  options={[
                    { value: '', label: i18n.t('ALL') },
                    ...((gatewayList && gatewayList.Data) || []).map((item: any) => (
                      { value: item.Id, label: WALLET_TRANSLATE[item.Code] }
                    ))
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="status">
                <Select
                  className="w-full"
                  placeholder={i18n.t('status')}
                  options={[
                    { value: '', label: `${i18n.t('ALL')}` },
                    ...common.enumToOptions(DEPOSIT_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="upMemberAccount">
                <Input placeholder={`${i18n.t('recommendedMembers')}`} />
              </Form.Item>
            </Col>
            <Col className="w-12">
              <Form.Item name="agAccount">
                <Input placeholder={`${i18n.t('agentAccount')}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" justify="space-between">
            <Col>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      className="w-full"
                      onChange={handleDateType}
                      options={common.enumToOptions(SEARCH_DATE_TYPE)}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={181} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
                <Col>
                  <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col>
              <LayoutUpdateControl callback={mutate} />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1">
            <Col span={24}>
              <Table
                loading={isValidating}
                size="middle"
                dataSource={
                  depositList ? [
                    {
                      key: 1,
                      count: depositList.TotalRecord,
                      amount: depositList.TotalRealAmount
                    }
                  ] : []
                }
                columns={[
                  {
                    title: i18n.t('count'),
                    dataIndex: 'count',
                    align: 'right',
                    width: '50%'
                  },
                  {
                    title: i18n.t('totalOfDepositAmount'),
                    dataIndex: 'amount',
                    align: 'right',
                    width: '50%',
                    render: (_, { amount }) => common.toFormatNumber(amount)
                  },
                ]}
                pagination={false}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-1" justify="space-between">
            <Col>
              {
                $p('20205') &&
                <Space>
                  <Export url={'/depositticket/member/list/export'} param={{
                    ...params, PageSize: depositList && depositList.TotalRecord, PageIndex: 1
                  }} sheetName={i18n.t('depositQuery')} columns={ExcelColumns.MemberDeposit} />
                </Space>
              }
            </Col>
          </Row>
        </Form>
        <Table
          loading={isValidating}
          size="middle"
          className="mt-1"
          scroll={{ x: 1500 }}
          dataSource={depositList?.Data?.map((item: Deposit) => ({ key: item.Id, ...item }))}
          columns={[
            {
              dataIndex: 'Code',
              title: i18n.t('order'),
              fixed: "left",
              width: 260
            },
            {
              dataIndex: 'MemberAccount',
              title: i18n.t('memberAccount'),
              width: 160
            },
            {
              dataIndex: 'MemberGroupName',
              title: i18n.t('memberGroup'),
              width: 160
            },
            {
              dataIndex: 'UpMemberAccount',
              title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}`,
              width: 200,
              render: (val, record: any) => (
                <>
                  <span>{val || '-'}</span>
                  <span> / </span>
                  <span>{record.AGAccount ? record.AGAccount : '-'}</span>
                </>
              )
            },
            {
              dataIndex: 'GatewayCode',
              title: i18n.t('depositMethod'),
              width: 150,
              render: (val) => WALLET_TRANSLATE[val]
            },
            {
              dataIndex: 'Balance',
              title: i18n.t('previousBalance'),
              align: 'right',
              width: 150,
              render: (val) => val ? common.toFormatNumber(val) : '-'
            },
            {
              // 入款金額
              dataIndex: 'Amount',
              title: i18n.t('depositAmountMoney'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
            {
              dataIndex: 'HandlingFee',
              title: i18n.t('paymentProcessingFee'),
              align: 'right',
              width: 150,
              render: (val, { Status }) =>
                [DEPOSIT_STATE.approvaled, DEPOSIT_STATE.success].includes(Status)
                  ? common.toFormatNumber(val)
                  : '-'
            },
            {
              dataIndex: 'ExchangeRate',
              title: i18n.t('exchangeRate'),
              align: 'right',
              width: 100,
              render: (val, { GatewayCode }) => GatewayCode.includes('CRYPTO') 
                ? common.toFormatNumber(val || 0, 2 ) : '-'
            },
            {
              dataIndex: 'AfterAmount',
              title: i18n.t('BalanceAfterModification'),
              align: 'right',
              width: 150,
              render: (val, { Status, Balance, Amount }) =>
                [DEPOSIT_STATE.approvaled, DEPOSIT_STATE.success].includes(Status)
                  ? common.toFormatNumber(Number(Balance) + Number(Amount)) : '-'
            },
            {
              dataIndex: 'IsFirstDeposit',
              title: i18n.t('firstDeposit'),
              align: 'center',
              width: 80,
              render: (val, { Status }) =>
                [DEPOSIT_STATE.approvaled, DEPOSIT_STATE.success].includes(Status)
                  ? (val ? i18n.t('yes') : i18n.t('none'))
                  : ('-')
            },
            {
              dataIndex: 'CreateDate',
              title: i18n.t('createTime'),
              width: 150,
              render: (val) => timeS2L(val)
            },
            {
              dataIndex: 'AuditedDate',
              title: i18n.t('auditTime'),
              width: 150,
              render: (val) => timeS2L(val)
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              width: 150,
              fixed: 'right',
              render: (val, record: any) => (
                <>
                  <div className={stateColor(val)}>{i18n.t(DEPOSIT_STATE[val])}</div>
                  {
                    val !== DEPOSIT_STATE.pendingFinancialReview &&
                    <div>{i18n.t('from')} {record.FinanceAccount || 'System'} {i18n.t('operation')}</div>
                  }
                </>
              )
            },
            {
              title: i18n.t('operation'),
              width: 100,
              fixed: 'right',
              render: (_, { Status, key }) => (
                <>
                  {
                    (Status === DEPOSIT_STATE.pendingFinancialReview && $p('20203')) &&
                    <Button className="size-12" type="link" onClick={() => handleDeposit(key)}>{i18n.t('deposit')}</Button>
                  }
                  {
                    (Status !== DEPOSIT_STATE.pendingFinancialReview || !$p('20203')) &&
                    <Button className="size-12" type="link" onClick={() => handleInfo(key)}>{i18n.t('detail')}</Button>
                  }
                </>
              ),
            },
          ]}
          pagination={false}
        />
        <LayoutPagination total={depositList?.TotalRecord} setPage={setPage} page={page} />
      </Content>

      {/* 代理的詳細審核是合併的 會員應該也能合併 */}
      <PopupDepositAudit isOpen={isOpenDeposit} close={() => setIsOpenDeposit(false)} id={id} mutate={mutate} />
      <PopupDepositInfo isOpen={isOpenInfo} close={() => setIsOpenInfo(false)} id={id} mutate={mutate} />
    </div>
  );
};

export default PageMain;