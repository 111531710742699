const main = function() {

  const Data = [
    {
      "Id": 100001,
      "Code": "ORD123456",
      "Category": 1,
      "Name": "FootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootballFootball",
      "TableId": 20001,
      "MemberId": 3001,
      "GameProviders": {
        "provider1": "Bet365",
        "provider2": "William Hill"
      },
      "Amount": 1200.50,
      "OriginalValidBetAmount": 1300.50,
      "ActualValidBetAmount": 1300.25,
      "AccumulatedValidBetAmount": 1400.50,
      "Status": 0,
      "HasCommission": 1,
      "Detail": {
        "betType": "Single",
        "odds": 2.5,
        "win": false
      },
      "CompleteTime": "2024-03-24T12:30:45Z",
      "CreateDate": "2025-03-24 17:05:09",
      "UpdateDate": "2025-03-24 17:05:09",
      "CategoryCodes": ["SPRT", "FBT"]
    },
  ];
  
  return {
    State: "Success",
    Message: "apple",
    TotalRecord: 20,
    TotalOriginalValidBetAmount: 20,
    TotalAccumulatedValidBetAmount: 30,
    TotalGameProviderOriginalValidBetAmount: 40,
    TotalGameProviderAccumulatedValidBetAmount: 50,
    Data
  }
}

export default main;
