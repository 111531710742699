import PageAgent from "pages/admin/agent";
import PageAgentAdd from "pages/admin/agent/add";
import PageAddAgent from "pages/admin/agent/add-agent";
import PageAgentAdjust from "pages/admin/agent/adjust";
import PageAgentCard from "pages/admin/agent/card";
import PageAgentConfirm from "pages/admin/agent/confirm";
import PageAgentDaily from "pages/admin/agent/daily";
import PageAgentDeposit from "pages/admin/agent/deposit";
import PageAgentEdit from "pages/admin/agent/edit";
import PageAgentSettle from "pages/admin/agent/settle";
import PageAgentTag from "pages/admin/agent/tag";
import PageAgentWallet from "pages/admin/agent/wallet";
import PageAgentWithdraw from "pages/admin/agent/withdraw";

export const agentModule = [
  { path: '/agent', element: <PageAgent/> },
  { path: '/agent/add', element: <PageAgentAdd/> },
  { path: '/agent/edit/:id', element: <PageAgentEdit/> },
  { path: '/agent/settle/:id/:account', element: <PageAgentSettle/> },
  { path: '/agent/daily/:id/:account', element: <PageAgentDaily/> },
  { path: '/agent/add-agent/:id', element: <PageAddAgent/> },
  { path: '/agent/withdraw', element: <PageAgentWithdraw/> },
  { path: '/agent/deposit', element: <PageAgentDeposit/> },
  { path: '/agent/adjust', element: <PageAgentAdjust/> },
  { path: '/agent/card', element: <PageAgentCard/> },
  { path: '/agent/confirm', element: <PageAgentConfirm/> },
  { path: '/agent/tag', element: <PageAgentTag/> },
  { path: '/agent/wallet', element: <PageAgentWallet/> },
]