import { Button, Col, Row, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PopupAddAgentTag, PopupAgentTagList, PopupEditAgentTag } from 'components/agent.component';
import { LayoutNav } from 'components/layout.component';
import { ENABLE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';

// 代理標籤

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [tagId, setTagId] = useState<number | null>(null);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenTagList, setIsOpenTagList] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { data: TagList, isValidating, mutate } = $get({ url: 'api/tag/list' });

  const stateColor = (state: number) => {
    switch (state) {
      case ENABLE.enabled:
        return 'color-pass'
      case ENABLE.disabled:
        return 'color-reject'
    };
  };

  const onEdit = (id: number) => {
    setTagId(id);
    setIsOpenEditModal(true);
  }

  const onAgentAccount = (id: number) => {
    setTagId(id);
    setIsOpenTagList(true);
  }

  return (
    <div id="container">
      <Content className="p-2">
        <Row gutter={[12, 20]}>
          {/* 新增 */}
          {
            $p('41102') &&
            <Col span={24}>
              <Button type="primary" onClick={() => setIsOpenAddModal(true)}>{i18n.t('add')}</Button>
            </Col>
          }
          {/* 數據 */}
          <Col span={24}>
            <Table
              loading={isValidating}
              size="small"
              dataSource={
                TagList?.Data?.map((item: TagInfo) => ({
                  key: item.Id, tag: {
                    name: item.Name,
                    color: item.TextColor,
                    background: item.Color,
                    IsShowName: item.IsShowName
                  }, ...item
                }
                )) || []
              }
              columns={[
                {
                  title: i18n.t('sort'),
                  dataIndex: 'Position',
                  align: 'center',
                  width: 80,
                  defaultSortOrder: 'ascend',
                  sorter: (a: any, b: any) => a.Position - b.Position
                },
                {
                  title: i18n.t('tagName'),
                  dataIndex: 'tag',
                  width: 300,
                  render: (_, { tag }: any) => (
                    <Tag style={{
                      color: tag.color,
                      background: tag.background,
                      minWidth: 80,
                      minHeight: 20,
                      textAlign: 'center',
                    }}>
                      {tag.IsShowName ? tag.name : ''}
                    </ Tag>
                  )
                },
                {
                  title: i18n.t('description'),
                  dataIndex: 'Description',
                  width: 500
                },
                {
                  title: i18n.t('status'),
                  dataIndex: 'Status',
                  align: 'center',
                  width: 80,
                  render: (_, { Status }) => <div className={stateColor(Status)}>{i18n.t(ENABLE[Status])}</div>
                },
                {
                  title: i18n.t('userCount'),
                  dataIndex: 'AgentCount',
                  width: 150,
                  align: 'right'
                },
                {
                  title: i18n.t('operation'),
                  width: 150,
                  render: (_, record: any) => (
                    <>
                      {
                        !$p('41103') &&
                        <Button className="size-12" type="link" onClick={() => onEdit(record.key)}>{i18n.t('detail')}</Button>
                      }
                      {
                        $p('41103') &&
                        <Button className="size-12" type="link" onClick={() => onEdit(record.key)}>{i18n.t('edit')}</Button>
                      }
                      {
                        $p('41105') &&
                        <Button className="size-12" type="link" onClick={() => onAgentAccount(record.key)}>{i18n.t('agentAccount')}</Button>
                      }
                    </>
                  )
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        <PopupAddAgentTag isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} mutate={mutate} />
        <PopupEditAgentTag isOpen={isOpenEditModal} close={() => setIsOpenEditModal(false)} id={tagId} mutate={mutate} refresh={refresh} setRefresh={setRefresh} />
        <PopupAgentTagList isOpen={isOpenTagList} close={() => setIsOpenTagList(false)} id={tagId} mutate={mutate} refresh={refresh} />
      </Content>
    </div >
  );
};

export default PageMain;