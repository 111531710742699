import { Button, Col, Descriptions, Form, Image, Modal, Row, Select, Spin, Table, message } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { GATEWAY_CODE_DEPOSIT, GATEWAY_CODE_WITHDRAW, WALLET_TRANSLATE } from "constants/gateway";
import { RESPONSE_CODE_WITHDRAW, RESPONSE_CODE_FINANCE } from "constants/response";
import { AGENT_ADJUST_STATE, DEPOSIT_STATE, GATEWAY_TYPE, WITHDRAW_STATE } from "enum/state";
import useAccount from "hooks/account.hook";
import useSite from "hooks/site.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { $get, $post } from "services";
import * as common from 'utils/common';
import { timeS2L } from "utils/common";
import { PopupOrderLock } from "./finance.component";
import { CopyButton, LayoutTextArea } from "./layout.component";

// 代理入款審核詳細
export const PopupAgentDeposit = ({ isOpen, close, id, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const { data: depositDetail, mutate: mutateOne, isValidating } = $get({
    url: 'api/depositticket/one',
    params: { Id: id },
    allow: !!id
  })

  const [form] = useForm();

  useEffect(() => {
    if (isOpen) {
      mutateOne()
    }
  }, [isOpen]);

  useEffect(() => {
    if (depositDetail && isOpen) {
      form.setFieldsValue({
        bnRemarks: depositDetail.Data.BnRemark
      })
    }
  }, [depositDetail, isOpen]);

  const handlePass = () => {
    const { bnRemarks } = form.getFieldsValue();
    $post({
      url: 'api/depositticket/agent/resolve',
      send: {
        Id: depositDetail?.Data.Id,
        RealAmount: depositDetail?.Data.Amount,
        BnRemarkFromFin: bnRemarks
      },
      success: () => {
        message.success(i18n.t('approvaled'));
        mutate();
        onClose();
      },
      fail: (response: any) => {
        mutate();
        onClose();
      },
      resCode: RESPONSE_CODE_FINANCE
    })
  }

  const handleReject = () => {
    const { bnRemarks } = form.getFieldsValue();
    $post({
      url: 'api/depositticket/agent/reject',
      send: {
        Id: depositDetail?.Data.Id,
        RemarkFromFin: bnRemarks
      },
      success: () => {
        message.success(i18n.t('reject'));
        mutate();
        onClose();

      },
      fail: (response: any) => {
        mutate();
        onClose();
      },
      resCode: RESPONSE_CODE_FINANCE
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
  }

  const info = (depositDetail?.Data.DepositMerchantGatewayCode.includes('OFFLINE') 
    ? depositDetail?.Data.OfflineInfo : depositDetail?.Data.MerchantInfo) || {}; 
  const isVirtual = depositDetail?.Data.DepositMerchantGatewayCode.includes('CRYPTO');
  const memo = JSON.parse(depositDetail?.Data.Memo || '{}');
  // m-938 要排除 Toppay
  const isToppay = depositDetail?.Data.MerchantInfo?.Name === 'TOPPAY';

  let buttonDisplay = 0;

  if ($p('40603')) {
    if (depositDetail?.Data.Status === DEPOSIT_STATE.pendingFinancialReview) {
      buttonDisplay = 1;
    } else if (
      depositDetail?.Data.Status === DEPOSIT_STATE.paying || 
     (depositDetail?.Data.Status === DEPOSIT_STATE.fail && !isToppay)
    ) {
      buttonDisplay = 2;
    } else if (depositDetail?.Data.Status === DEPOSIT_STATE.fail) {
      buttonDisplay = 3;
    }
  }

  return (
    <Modal
      title={depositDetail?.Data?.Status === DEPOSIT_STATE.pendingFinancialReview ? `${i18n.t('deposit')}` : `${i18n.t('detail')}`}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          {buttonDisplay === 0 && <>
            <Col><Button type="primary" onClick={close}>{i18n.t('close')}</Button></Col>
          </>}
          {buttonDisplay === 1 && <>
            <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('reject')}</Button></Col>
            <Col><Button type="primary" onClick={handlePass}>{i18n.t('approvaled')}</Button></Col>
          </>}
          {buttonDisplay === 2 && <>
            <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('forceReject')}</Button></Col>
            <Col><Button type="primary" onClick={handlePass}>{i18n.t('forceApproval')}</Button></Col>
          </>}
          {buttonDisplay === 3 && <>
            <Col><Button type="primary" onClick={close}>{i18n.t('close')}</Button></Col>
            <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('forceReject')}</Button></Col>
          </>}
        </Row>
      }
      width={900}
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {depositDetail?.Data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col> {depositDetail?.Data?.OwnerAccount}</Col>
                <Col><CopyButton text={depositDetail?.Data?.OwnerAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('agentName')}`}>
              {depositDetail?.Data?.AgentName || '-'}
            </Descriptions.Item>
            <Descriptions.Item><></></Descriptions.Item>
          </Descriptions>

          {/* 第二排 */}
          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('depositAmountMoney')}>
              {common.toFormatNumber(depositDetail?.Data?.Amount)}
            </Descriptions.Item>
            {isVirtual
            ? <>
            <Descriptions.Item label={i18n.t('currency')}>
              {info.Crypto}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('networkType')}>
              {info.CryptoType}
            </Descriptions.Item>
            </>
            : <>
            <Descriptions.Item label={i18n.t('depositMethod')}>
              {depositDetail?.Data.DepositMerchantGatewayCode
              ? WALLET_TRANSLATE[depositDetail.Data.DepositMerchantGatewayCode]
              : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('depositInformation')}>
              {depositDetail && (
               depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_CRYPTO_OFFLINE ||
               depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK_OFFLINE)
              ? <>
              <div>{info.Name || '-'}</div>
              <div>
                <span>{info.BankName}</span>／
                <span>{common.displayLastFourCharacters(info.Account) || '-'}</span>／
                <span>{info.PhoneNumber || '-'}</span>
              </div>
              </>
              : <>
              <div>{info.CustomName || '-'}</div>
              </>
              }
            </Descriptions.Item>
            </>
            }
            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber(depositDetail?.Data.HandlingFee)}／{
              isVirtual ? common.toFormatNumber(depositDetail?.Data.ExchangeRate || 0, 2) : '-'}
            </Descriptions.Item>
            {isVirtual &&
            <>
            <Descriptions.Item label={`≈ ${info.Crypto}`}>
              {common.toFormatNumber(depositDetail?.Data.Amount / depositDetail?.Data.ExchangeRate, 2, 'ceil')}
            </Descriptions.Item>
            <Descriptions.Item label={''}>
              <></>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('receiveAddress')} span={2}>
              {info.CryptoAddress || memo.Address}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('QR Code')}>
              {info.QR_Code
                ? <Image src={info.QR_Code || ''} height={100} />
                : '-'
              }
            </Descriptions.Item>
            <Descriptions.Item label={''}><></></Descriptions.Item>
            </>
            }
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {depositDetail && depositDetail?.Data.Status === DEPOSIT_STATE.success && (
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_ATM ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_CVS ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK
              ) ? 'System' : (depositDetail?.Data.FinanaceAccount || 'System')
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              {i18n.t(DEPOSIT_STATE[depositDetail?.Data.Status])}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>
              {timeS2L(depositDetail?.Data.CreateDate)}
            </Descriptions.Item>
            <Descriptions.Item><></></Descriptions.Item>
            {/* 虛擬貨幣 備註在此 */}
            {isVirtual &&
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              {depositDetail?.Data.Status !== DEPOSIT_STATE.pendingFinancialReview
                ? depositDetail?.Data.BnRemark || ''
                : <LayoutTextArea formName="bnRemarks" disabled={!$p('40603')} />
              }
            </Descriptions.Item>}
          </Descriptions>

          {/* 銀行卡 備註在此 */}
          {!isVirtual &&
          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              {depositDetail?.Data.Status !== DEPOSIT_STATE.pendingFinancialReview
                ? depositDetail?.Data.BnRemark || ''
                : <LayoutTextArea formName="bnRemarks" disabled={!$p('40603')} />
              }
            </Descriptions.Item>
            {depositDetail && (
              depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_ATM ||
              depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_CVS ||
              depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK ||
              depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK_OFFLINE
            ) &&
            <Descriptions.Item label={i18n.t('certificate')}>
              {depositDetail?.Data.ReceiptPhoto
                ? <Image className="center" src={depositDetail?.Data.ReceiptPhoto} height={100} />
                : '-'
              }
            </Descriptions.Item>
            }
          </Descriptions>}
        </Spin>
      </Form>
    </Modal>
  )
}

// 代理提款總計
export const ViewAgentWithdrawOverview = ({ data }: {
  data: {
    State: string;
    TotalAmount: number;
    TotalHandlingFee: number;
    TotalRealAmount: number;
    TotalRecord: number;
  };
}) => {

  return (
    <Table
      className="mt-1"
      size="middle"
      dataSource={[{
        count: data?.TotalRecord,
        WithdrawalTotalAmount: data?.TotalAmount,
        totalProcessingFee: data?.TotalHandlingFee,
        totalActualWithdrawalAmount: data?.TotalRealAmount
      }]}
      columns={[
        {
          title: i18n.t('count'),
          dataIndex: 'count',
          align: 'right',
          width: '25%',
        },
        {
          title: i18n.t('withdrawalTotalAmount'),
          dataIndex: 'WithdrawalTotalAmount',
          align: 'right',
          width: '25%',
          render: (_, { WithdrawalTotalAmount }) => common.toFormatNumber(WithdrawalTotalAmount)
        },
        {
          title: i18n.t('totalProcessingFee'),
          dataIndex: 'totalProcessingFee',
          align: 'right',
          width: '25%',
          render: (_, { totalProcessingFee }) => common.toFormatNumber(totalProcessingFee)
        },
        {
          title: i18n.t('totalActualWithdrawalAmount'),
          dataIndex: 'totalActualWithdrawalAmount',
          align: 'right',
          width: '25%',
          render: (_, { totalActualWithdrawalAmount }) => common.toFormatNumber(totalActualWithdrawalAmount)
        },
      ]}
      pagination={false}
    />
  )
}
// 代理提款列表
export const ViewAgentWithdrawList = ({ data, mutate }: {
  data: {
    State: string;
    Data: AgentWithdrawListType[];
  },
  mutate: () => void;
}) => {
  const { info, permissions: $p } = useAccount();
  const [id, setId] = useState<number | null>(0);
  const [financeId, setFinanceId] = useState<number | null>(0);
  const [orderState, setOrderState] = useState<number>(0);
  const [orderType, setOrderType] = useState<string | null>('');
  const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isModalOpenWithdraw, setIsModalOpenWithdraw] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  const showModalConfirm = (id: number, orderState: number) => {
    setId(id);
    setOrderState(orderState);
    setIsModalOpenConfirm(true);
  };

  const showModalDetail = (id: number, GatewayCode: string) => {
    setId(id);
    setOrderType(GatewayCode);
    setIsModalOpenDetail(true);
  };

  const showModalWithdraw = (id: number, orderState: number, GatewayCode: string) => {
    setFinanceId(id);
    setOrderState(orderState);
    setOrderType(GatewayCode);
    setIsModalOpenWithdraw(true);
  };

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map(item => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left'
          },
          {
            dataIndex: 'AgentAccount',
            title: i18n.t('account'),
            width: 200,
            fixed: 'left',
            render: (val: any, { AgentLevel }: any) => {
              const word = common.agentLevelWord(AgentLevel);
              return `${word}／${val}`
            }
          },
          {
            dataIndex: 'SAAccount',
            title: i18n.t('generalAgent'),
            width: 200,
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('withdrawalAmount'),

            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('actualWithdrawalAmount'),
            align: 'right',
            width: 200,
            render: (val, { Status }) => Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 200,
            render: (val, { Status }) =>
              Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'HandlingFee',
            title: i18n.t('paymentProcessingFee'),
            align: 'right',
            width: 200,
            render: (val, { Status }) => Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'ExchangeRate',
            title: i18n.t('exchangeRate'),
            align: 'right',
            width: 100,
            render: (val, { GatewayCode }) => GatewayCode.includes('CRYPTO')
              ? common.toFormatNumber(val || 0, 2 ) : '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'AuditedDate',
            title: i18n.t('auditTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 180,
            fixed: 'right',
            render: (val, { FinanceAccount, ServiceAccount, AuditorAccount, ApproverAccount, GatewayCode }) => (
              <>
                {/* 狀態 */}
                {
                  <div className={common.numColor04(val)}>{i18n.t(WITHDRAW_STATE[val])}</div>
                }
                {/* 操作者 */}
                {
                  val === WITHDRAW_STATE.pendingSupervisorReview &&
                  <>{i18n.t('from')} {ServiceAccount || 'System'} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.pendingFinancialReview &&
                  <>{i18n.t('from')} {AuditorAccount || 'System'} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.financialReview &&
                  <>{i18n.t('from')} {FinanceAccount || 'System'} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.approvaled && (GatewayCode === GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK || GatewayCode === GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO) &&
                  <>{i18n.t('from')} {ApproverAccount || 'System'} {i18n.t('approvaled')}</>
                }
                {(
                  val === WITHDRAW_STATE.approvaled && (GatewayCode !== GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK && GatewayCode !== GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO) ||
                  val === WITHDRAW_STATE.processing ||
                  val === WITHDRAW_STATE.paymentTerminalWaiting) &&
                  <>{i18n.t('from')} {ApproverAccount || FinanceAccount || 'System'} {i18n.t('approvaled')}</>
                }
                {
                  val === WITHDRAW_STATE.reject &&
                  <>{i18n.t('from')} {ApproverAccount || ServiceAccount || AuditorAccount || FinanceAccount || 'System'} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.fail &&
                  <>{i18n.t('from')} {'System'} {i18n.t('operation')}</>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, { Status, key, FinanceAccountId, GatewayCode }: any) => (
              <>
                {(
                  (Status === WITHDRAW_STATE.pendingServiceReview && $p('40504')) ||
                  (Status === WITHDRAW_STATE.pendingSupervisorReview && $p('40506'))) &&
                  <Button type="link" onClick={() => showModalConfirm(key, Status)}>
                    {i18n.t('audit')}
                  </Button>
                }
                {(
                  (Status === WITHDRAW_STATE.pendingFinancialReview && $p('40508')) ||
                  (Status === WITHDRAW_STATE.financialReview && FinanceAccountId === info.Id)) &&
                  <Button type="link" onClick={() => showModalWithdraw(key, Status, GatewayCode)}>
                    {i18n.t('withdrawing')}
                  </Button>
                }
                {((
                  (Status === WITHDRAW_STATE.pendingServiceReview && !$p('40504')) ||
                  (Status === WITHDRAW_STATE.pendingSupervisorReview && !$p('40506')) ||
                  (Status === WITHDRAW_STATE.pendingFinancialReview && !$p('40508')) ||
                  Status === WITHDRAW_STATE.approvaled ||
                  Status === WITHDRAW_STATE.reject ||
                  Status === WITHDRAW_STATE.processing ||
                  Status === WITHDRAW_STATE.fail ||
                  Status === WITHDRAW_STATE.paymentTerminalWaiting ||
                  (Status === WITHDRAW_STATE.financialReview && FinanceAccountId !== info.Id))) &&
                  <Button type="link" onClick={() => showModalDetail(key, GatewayCode)}>
                    {i18n.t('detail')}
                  </Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />
      {/* 前置審核 */}
      <PopupAgentWithdrawAudit isOpen={isModalOpenConfirm} close={() => setIsModalOpenConfirm(false)} id={id} orderState={orderState} mutate={mutate} />
      {/* 財務出款審核 */}
      <PopupAgentWithdrawWithdrawal isOpen={isModalOpenWithdraw} close={() => setIsModalOpenWithdraw(false)} id={financeId} orderState={orderState} mutate={mutate} orderType={orderType} setErrorPopup={setErrorPopup} errorPopup={errorPopup} />
      {/* 明細 */}
      <PopupAgentWithdrawDetail isOpen={isModalOpenDetail} close={() => setIsModalOpenDetail(false)} id={id} orderType={orderType} mutate={mutate} />
      {/* 鎖單錯誤訊息視窗 */}
      <PopupOrderLock isOpen={errorPopup} close={() => setErrorPopup(false)} />
    </>
  )
}
// 代理前置審核
export const PopupAgentWithdrawAudit = ({ isOpen, close, id, orderState, mutate }: any) => {
  const [form] = useForm();
  const { data, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/withdrawticket/one',
    params: { Id: id },
    allow: !!id
  });

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);
  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data?.Data.AuditorAccessLogRemark || data?.Data.ServiceAccessLogRemark
      })
    }
  }, [data, isOpen]);

  const handlePass = () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.pendingServiceReview:
        $post({
          url: 'api/withdrawticket/agent/service/resolve',
          send: {
            Id: id,
            Remark: bnRemarks
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            mutate();
            onClose();
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
      case WITHDRAW_STATE.pendingSupervisorReview:
        $post({
          url: 'api/withdrawticket/agent/auditor/resolve',
          send: {
            Id: id,
            Remark: bnRemarks
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            mutate();
            onClose();
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
    }
  }

  const handleReject = () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.pendingServiceReview:
        $post({
          url: 'api/withdrawticket/agent/service/reject',
          send: {
            Id: id,
            FnRemark: fnRemarks,
            BnRemark: bnRemarks
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            mutate();
            onClose();
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
      case WITHDRAW_STATE.pendingSupervisorReview:
        $post({
          url: 'api/withdrawticket/agent/auditor/reject',
          send: {
            Id: id,
            FnRemark: fnRemarks,
            BnRemark: bnRemarks
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            mutate();
            onClose();
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('audit')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={900}
      forceRender
    >
      <Form form={form} onFinish={handleReject}>
        <Spin spinning={isValidating}>
          <Descriptions
            className="pb-1"
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.AgentAccount}</Col>
                <Col><CopyButton text={data?.Data?.AgentAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={''}><></></Descriptions.Item>
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {data?.Data?.Status === WITHDRAW_STATE.pendingSupervisorReview &&
                (data?.Data?.ServiceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.pendingFinancialReview &&
                (data?.Data?.AuditorAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.approvaled &&
                (data?.Data?.FinanceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting &&
                (data?.Data?.FinanceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.reject &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.fail &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              <div className={common.numColor04(data?.Data?.Status)}>{i18n.t(WITHDRAW_STATE[data?.Data?.Status])}</div>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{timeS2L(data?.Data?.CreateDate)}</Descriptions.Item>
            <Descriptions.Item label={''}><></></Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="fnRemarks" formRules={[{ required: true, message: `${i18n.t('rejectionRequiresFrontendRemark')}` }]} />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="bnRemarks" />
            </Descriptions.Item>
          </Descriptions>

          <Row className="mt-1" gutter={[12, 12]} align="middle" justify="center">
            <Col>
              <Button type="primary" danger htmlType="submit" >{i18n.t('reject')}</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={handlePass}>{i18n.t('approvaled')}</Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal >
  )
}
// 代理財務出款審核
export const PopupAgentWithdrawWithdrawal = ({ isOpen, close, id, mutate, orderType, setErrorPopup, errorPopup }: any) => {
  const isVirtual = orderType.includes('CRYPTO');

  const { data: $s } = useSite();

  const [form] = useForm();
  const [submitType, setSubmitType] = useState(AGENT_ADJUST_STATE.approvaled);
  const [withdrawalType, setWithdrawalType] = useState<GATEWAY_TYPE>();
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });
  const { data, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/withdrawticket/finance/one',
    params: { Id: id },
    allow: !!id
  });

  const { data: merchantBank, isValidating: merchantBankValidating } = $get({
    url: 'api/withdrawmerchant/bank/resolve/list',
    params: {
      IsOffline: withdrawalType === GATEWAY_TYPE.thirdParty ? 0 : 1,
      TicketId: id
    },
    allow: isOpen && !!id && !isVirtual
  });
  const { data: merchantCrypto, isValidating: merchantCryptoValidating } = $get({
    url: 'api/withdrawmerchant/crypto/resolve/list',
    params: {
      IsOffline: withdrawalType === GATEWAY_TYPE.thirdParty ? 0 : 1,
      TicketId: id,
    },
    allow: isOpen && !!cryptoParams.Crypto && !!cryptoParams.CryptoType && !!id && isVirtual
  })

  useEffect(() => {
    if (isOpen) {
      RefreshInfo();
      setSubmitType(AGENT_ADJUST_STATE.approvaled);
    }
    if (data?.Data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data.Data.AuditorAccessLogRemark
      })
      setCryptoParams({
        Crypto: data.Data.Crypto,
        CryptoType: data.Data.CryptoType,
      })
    }
    if (data?.Data.Message === 'ANOTHER_FINANCE_IS_HANDLING') {
      close();
      mutate();
      setErrorPopup(true);
    }
  }, [data, isOpen]);

  const [range, setRange] = useState({
    min: 0,
    max: 0,
    fee: 0,
    rate: 0
  });

  const withdrawalTypeChanged = (val: number) => {
    setWithdrawalType(val);
    form.setFieldsValue({
      withdrawalChannel: null,
      cryptoAddress: null
    })
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    })
  }

  const onSelectChannel = (selected: number) => {
    (merchantBank || merchantCrypto)?.Data.filter((item: { Id: number }) => (
      item.Id === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const onSelectCrypto = (selected: number) => {
    merchantCrypto?.Data.filter((item: { WithdrawMerchantId: number }) => (
      item.WithdrawMerchantId === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const handleSubmit = (action: AGENT_ADJUST_STATE) => {
    setSubmitType(action);
    form.validateFields();
    const formData = form.getFieldsValue();

    // 通過
    if (action === AGENT_ADJUST_STATE.approvaled) {
      const { withdrawalType, withdrawalChannel, bnRemarks, cryptoAddress } = formData;

      if (!withdrawalType) return;
      if (withdrawalType === GATEWAY_TYPE.thirdParty && !withdrawalChannel) return;
      if (withdrawalType === GATEWAY_TYPE.transfer && (isVirtual ? !cryptoAddress : !withdrawalChannel)) return;
  
      if (data?.Data.Amount > range.max && range.max) {
        return message.error(i18n.t('withdrawalAmountExceedsMaximumLimit'));
      }
      if (data?.Data.Amount < range.min) {
        return message.error(i18n.t('withdrawalAmountBelowMinimumLimit'));
      }
  
      $post({
        url: 'api/withdrawticket/agent/finance/resolve',
        send: {
          Id: id,
          WithdrawMerchantId: withdrawalChannel || cryptoAddress,
          BnRemark: bnRemarks,
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        },
        fail: (response: any) => {
          mutate();
          onClose();
        },
        resCode: RESPONSE_CODE_WITHDRAW
      })
    // 拒絕
    } else {
      const { fnRemarks, bnRemarks } = formData;
      if (!fnRemarks) return;
  
      $post({
        url: 'api/withdrawticket/agent/finance/reject',
        send: {
          Id: id,
          FnRemark: fnRemarks,
          BnRemark: bnRemarks
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          mutate();
          onClose();
        },
        fail: (response: any) => {
          mutate();
          onClose();
        },
        resCode: RESPONSE_CODE_WITHDRAW
      })
    }
  }

  const onClose = () => {
    close();
    setWithdrawalType(undefined);
    form.resetFields();
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    })
  }

  return (
    <Modal
      title={i18n.t('withdrawing')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      width={900}
      footer={false}
      forceRender
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.AgentAccount}</Col>
                <Col><CopyButton text={data?.Data?.Account} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>

            {/* 出款方式 */}
            <Descriptions.Item label={i18n.t('withdrawalType')}>
              <Form.Item name="withdrawalType" rules={[{
                required: submitType === AGENT_ADJUST_STATE.approvaled,
                message: `${i18n.t('required')}`
              }]}>
                <Select
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={common.enumToOptions(GATEWAY_TYPE)}
                  onChange={withdrawalTypeChanged}
                />
              </Form.Item>
            </Descriptions.Item>
            {/* 渠道 */}
            <Descriptions.Item label={i18n.t('withdrawalChannel')}>
              <Form.Item name="withdrawalChannel" rules={[{
                required: submitType === AGENT_ADJUST_STATE.approvaled &&
                  (!isVirtual || (isVirtual && withdrawalType === GATEWAY_TYPE.thirdParty)),
                message: `${i18n.t('required')}`
              }]}>
                {(!isVirtual || (isVirtual && withdrawalType === GATEWAY_TYPE.thirdParty))
                ? <Select
                  loading={merchantBankValidating || merchantCryptoValidating}
                  className="w-full"
                  onChange={onSelectChannel}
                  placeholder={i18n.t('pleaseSelect')}
                  options={
                    (merchantBank || merchantCrypto)?.Data.map((item: { Id: number, CustomName: string }) => (
                      { value: item.Id, label: `${item.CustomName}` }
                    )) || []
                  }
                />
              : '-'}
              </Form.Item>
            </Descriptions.Item>

            {isVirtual && <>
            <Descriptions.Item label={i18n.t('currency')}>
              {data?.Data?.Crypto}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('networkType')}>
              {data?.Data?.CryptoType}
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('userAddress')}`}>
            <Form.Item name="cryptoAddress" rules={[{
                required: withdrawalType === GATEWAY_TYPE.transfer,
                message: `${i18n.t('required')}`
              }]}>
                <Select
                  loading={merchantCryptoValidating}
                  disabled={withdrawalType !== GATEWAY_TYPE.transfer}
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  onChange={onSelectCrypto}
                  options={
                    merchantCrypto?.Data.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                      {
                        value: item.WithdrawMerchantId,
                        label: `${item.CustomName}-${item.CryptoAddress}`
                      }
                    )) || []
                  }
                />
              </Form.Item>
            </Descriptions.Item>
            </>}

            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber((data?.Data?.Amount * range.rate) + range.fee)}／{
              isVirtual ? common.toFormatNumber(data?.Data?.ExchangeRate || 0, 2) : '-'}
            </Descriptions.Item>
            {isVirtual &&
            <Descriptions.Item label={`≈ ${data?.Data?.Crypto}`}>
              {common.toFormatNumber(data?.Data?.Amount / data?.Data?.ExchangeRate, 2, 'floor')}
            </Descriptions.Item>
            }
            <Descriptions.Item label={i18n.t('withdrawalAmountLimit')}>
              {`${common.toFormatNumber(range.min)} - ${range.max === null ? i18n.t('unlimited') : common.toFormatNumber(range.max)}`}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            {isVirtual
            ? <>
            <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.CryptoAddress}</Col>
                <Col><CopyButton text={data?.Data?.CryptoAddress} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('bankAccountName')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.Account || '-'}</Col>
                <Col><CopyButton text={data?.Data?.Account} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('QR Code')}>
              {data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]
                ? <Image src={data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]} height={100} />
                : '-'
              }
            </Descriptions.Item>
            </>
            : <>
            <Descriptions.Item label={i18n.t('bankName')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.BankName}</Col>
                <Col><CopyButton text={data?.Data?.BankName} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('accountNumber')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.Account}</Col>
                <Col><CopyButton text={data?.Data?.Account} /></Col>
              </Row>
            </Descriptions.Item>
            {$s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></>
            : <>
            <Descriptions.Item label={i18n.t('branchName')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.Branch}</Col>
                <Col><CopyButton text={data?.Data?.Branch} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('bankCounty')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.State}</Col>
                <Col><CopyButton text={data?.Data?.State} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('city')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.City}</Col>
                <Col><CopyButton text={data?.Data?.City} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('phoneNumber')}>
              <Row align="middle" justify="space-between">
                <Col>{`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`}</Col>
                <Col><CopyButton text={`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`} /></Col>
              </Row>
            </Descriptions.Item>
            </>
            }
            </>
            }
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {data?.Data?.Status === WITHDRAW_STATE.pendingSupervisorReview &&
                (data?.Data?.ServiceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.pendingFinancialReview &&
                (data?.Data?.AuditorAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.approvaled &&
                (data?.Data?.FinanceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting &&
                (data?.Data?.FinanceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.reject &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)}
              {data?.Data?.Status === WITHDRAW_STATE.fail &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              <div className={common.numColor04(data?.Data?.Status)}>{i18n.t(WITHDRAW_STATE[data?.Data?.Status])}</div>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{timeS2L(data?.Data?.CreateDate)}</Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="fnRemarks"  formRules={[{
                required: submitType === AGENT_ADJUST_STATE.reject,
                message: `${i18n.t('rejectionRequiresFrontendRemark')}`
              }]} />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="bnRemarks" />
            </Descriptions.Item>
          </Descriptions>
          <Row gutter={[12, 12]} align="middle" justify="center" className="mt-1">
            <Col>
              <Button type="primary" onClick={() => handleSubmit(AGENT_ADJUST_STATE.reject)} danger>{i18n.t('reject')}</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleSubmit(AGENT_ADJUST_STATE.approvaled)}>{i18n.t('approvaled')}</Button>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}
// 代理出款明細
export const PopupAgentWithdrawDetail = ({ isOpen, close, id, orderType, mutate }: any) => {
  const isVirtual = orderType.includes('CRYPTO');

  const [form] = useForm();
  const [withdrawalType, setWithdrawalType] = useState<GATEWAY_TYPE>();
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });

  const { info, permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const { data, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/withdrawticket/one',
    params: { Id: id },
    allow: !!id
  });
  const { data: merchantBank, isValidating: merchantBankValidating } = $get({
    url: 'api/withdrawmerchant/bank/resolve/list',
    params: {
      IsOffline: withdrawalType === GATEWAY_TYPE.thirdParty ? 0 : 1,
      TicketId: id
    },
    allow: isOpen && !!id && !isVirtual
  });
  const { data: merchantCrypto, isValidating: merchantCryptoValidating } = $get({
    url: 'api/withdrawmerchant/crypto/resolve/list',
    params: {
      IsOffline: withdrawalType === GATEWAY_TYPE.thirdParty ? 0 : 1,
      TicketId: id,
    },
    allow: isOpen && !!cryptoParams.Crypto && !!cryptoParams.CryptoType && !!id && isVirtual
  })

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
    if (data?.Data && isOpen) {
      switch (data?.Data?.WithdrawMerchantGatewayCode) {
        case GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK_OFFLINE:
          setWithdrawalType(GATEWAY_TYPE.transfer);
          form.setFieldsValue({
            withdrawalChannel: data.Data.WithdrawMerchantId || null,
            withdrawalType: data.Data.WithdrawMerchantGatewayCode || null
          })
          break;
        case GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO_OFFLINE:
          setWithdrawalType(GATEWAY_TYPE.transfer);
          form.setFieldsValue({
            cryptoAddress: data.Data.WithdrawMerchantId || null,
            withdrawalType: data.Data.WithdrawMerchantGatewayCode || null
          })
          break;
        case GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK:
        case GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO:
          setWithdrawalType(GATEWAY_TYPE.thirdParty);
          form.setFieldsValue({
            withdrawalChannel: data.Data.WithdrawMerchantId || null,
            withdrawalType: data.Data.WithdrawMerchantGatewayCode || null
          })
          break;
        default:
          setWithdrawalType(undefined);
          form.setFieldsValue({
            cryptoAddress: null,
            withdrawalChannel: null,
            withdrawalType: null
          })
          break;
      }
      setCryptoParams({
        Crypto: data?.Data.Crypto,
        CryptoType: data?.Data.CryptoType,
      })
    }
  }, [data, isOpen]);

  const handlePass = () => {
    $post({
      url: 'api/withdrawticket/agent/finance/force/resolve',
      send: { Id: data?.Data.Id },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
      },
      fail: (response: any) => {
        mutate();
        onClose();
      },
      resCode: RESPONSE_CODE_WITHDRAW
    })
  }

  const handleReject = () => {
    $post({
      url: 'api/withdrawticket/agent/finance/reject',
      send: {
        Id: data?.Data.Id,
        FnRemark: data?.Data.FnRemark,
        BnRemark: data?.Data.FinanceAccessLogRemark
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
      },
      fail: (response: any) => {
        mutate();
        onClose();
      },
      resCode: RESPONSE_CODE_WITHDRAW
    })
  }

  const onClose = () => {
    form.resetFields();
    setCryptoParams({
      Crypto: null,
      CryptoType: null,
    })
    close();
  }

  const code = data?.Data.GatewayCode;
  let buttonDisplay = 0;

  if ($p('40508') && data?.Data?.FinanceAccount === info.Account) {
    if (
      data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting ||
     (data?.Data?.Status === WITHDRAW_STATE.fail && code.includes('OFFLINE'))
    ) {
      buttonDisplay = 1;
    } else if (data?.Data?.Status === WITHDRAW_STATE.fail && !(code.includes('OFFLINE'))) {
      buttonDisplay = 2;
    }
  }

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered={true}
      width={900}
      footer={
        <Row align="middle" justify="center" gutter={[12, 12]}>
          {buttonDisplay === 0 && <>
            <Col><Button type="primary" onClick={close}>{i18n.t('close')}</Button></Col>
          </>}
          {buttonDisplay === 1 && <>
            <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('forceReject')}</Button></Col>
            <Col><Button type="primary" onClick={handlePass}>{i18n.t('forceApproval')}</Button></Col>
          </>}
          {buttonDisplay === 2 && <>
            <Col><Button type="primary" onClick={close}>{i18n.t('close')}</Button></Col>
            <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('forceReject')}</Button></Col>
          </>}
        </Row>
      }
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          {/* 訂單資訊 */}
          <Descriptions
            className="pb-1"
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.AgentAccount}</Col>
                <Col><CopyButton text={data?.Data?.AgentAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>

            {/* 出款方式 */}
            <Descriptions.Item label={i18n.t('withdrawalType')}>
              <Form.Item name="withdrawalType">
                <Select
                  disabled
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={[
                    { value: GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO, label: i18n.t('thirdParty') },
                    { value: GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK, label: i18n.t('thirdParty') },
                    { value: GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO_OFFLINE, label: i18n.t('transfer') },
                    { value: GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK_OFFLINE, label: i18n.t('transfer') },
                  ]}
                />
              </Form.Item>
            </Descriptions.Item>
            {/* 出款渠道 */}
            <Descriptions.Item label={i18n.t('withdrawalChannel')}>
              <Form.Item name="withdrawalChannel">
                {(!isVirtual || (isVirtual && withdrawalType === GATEWAY_TYPE.thirdParty))
                ? <Select
                  loading={merchantBankValidating || merchantCryptoValidating}
                  disabled
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  options={
                    (merchantBank || merchantCrypto)?.Data.map((item: { Id: number, CustomName: string }) => (
                      { value: item.Id, label: `${item.CustomName}` }
                    )) || []
                  }
                />
              : '-'}
              </Form.Item>
            </Descriptions.Item>

            {isVirtual && <>
            <Descriptions.Item label={i18n.t('currency')}>
              {data?.Data?.Crypto}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('networkType')}>
              {data?.Data?.CryptoType}
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('userAddress')}`}>
              <Form.Item name="cryptoAddress">
                <Select
                  loading={merchantCryptoValidating}
                  disabled
                  className="w-full"
                  placeholder={i18n.t('pleaseSelect')}
                  defaultValue={data?.Data?.WithdrawMerchantId}
                  options={
                    merchantCrypto?.Data.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                      {
                        value: item.WithdrawMerchantId,
                        label: `${item.CustomName}-${item.CryptoAddress}`
                      }
                    )) || []
                  }
                />
              </Form.Item>
            </Descriptions.Item>
            </>}

            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber(data?.Data?.HandlingFee)}／{
              isVirtual ? common.toFormatNumber(data?.Data?.ExchangeRate || 0, 2) : '-'}
            </Descriptions.Item>
            {isVirtual &&
            <Descriptions.Item label={`≈ ${data?.Data?.Crypto}`}>
              {common.toFormatNumber(data?.Data?.Amount / data?.Data?.ExchangeRate, 2, 'floor')}
            </Descriptions.Item>
            }
          </Descriptions>

          {/* 提款資訊 */}
          <Descriptions
            className="pb-1"
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            {isVirtual
            ? <>
            <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.CryptoAddress}</Col>
                <Col><CopyButton text={data?.Data?.CryptoAddress} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('bankAccountName')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.Account || '-'}</Col>
                <Col><CopyButton text={data?.Data?.Account} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('QR Code')}>
              {data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]
                ? <Image src={data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]} height={100} />
                : '-'
              }
            </Descriptions.Item>
            </>
            : <>
            <Descriptions.Item label={i18n.t('bankName')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.BankName}</Col>
                <Col><CopyButton text={data?.Data?.BankName} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('accountNumber')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.Account}</Col>
                <Col><CopyButton text={data?.Data?.Account} /></Col>
              </Row>
            </Descriptions.Item>
            {$s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></>
            : <>
            <Descriptions.Item label={i18n.t('branchName')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.Branch}</Col>
                <Col><CopyButton text={data?.Data?.Branch} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('bankCounty')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.State || '-'}</Col>
                <Col><CopyButton text={data?.Data?.State} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('city')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.City || '-'}</Col>
                <Col><CopyButton text={data?.Data?.City} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('phoneNumber')}>
              <Row align="middle" justify="space-between">
                <Col>{`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`}</Col>
                <Col><CopyButton text={`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`} /></Col>
              </Row>
            </Descriptions.Item>
            </>
            }
            </>
            }
          </Descriptions>

          {/* 審核 */}
          <Descriptions
            className="pb-1"
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {data?.Data?.Status === WITHDRAW_STATE.pendingSupervisorReview &&
                (data?.Data?.ServiceAccount || 'System')}
              {data?.Data?.Status === WITHDRAW_STATE.pendingFinancialReview &&
                (data?.Data?.AuditorAccount || 'System')}
              {data?.Data?.Status === WITHDRAW_STATE.approvaled &&
                (data?.Data?.ApproverAccount || data?.Data?.FinanceAccount || 'System')}
              {data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting &&
                (data?.Data?.FinanceAccount || 'System')}
              {data?.Data?.Status === WITHDRAW_STATE.reject &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount || 'System')}
              {data?.Data?.Status === WITHDRAW_STATE.fail &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount || 'System')}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              <div className={common.numColor04(data?.Data?.Status)}>{i18n.t(WITHDRAW_STATE[data?.Data?.Status])}</div>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>
              {timeS2L(data?.Data?.CreateDate)}
            </Descriptions.Item>
          </Descriptions>

          {/* 備註 */}
          <Descriptions
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
              <TextArea value={data?.Data?.FnRemark} placeholder={`${i18n.t('inputData')}`} disabled
                autoSize={{ minRows: 3, maxRows: 3 }} />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              <TextArea value={data?.Data && (data?.Data.FinanceAccessLogRemark || data?.Data.AuditorAccessLogRemark || data?.Data.ServiceAccessLogRemark)} placeholder={`${i18n.t('inputData')}`} disabled
                autoSize={{ minRows: 3, maxRows: 3 }} />
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Form>
    </Modal>
  )
}
