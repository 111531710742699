import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import dayjs from 'dayjs';
import {SMS_STATE } from "enum/state";
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { enumToOptions, timeL2S, tzShift, verify } from "utils/common";
import { SMS_ROLE } from "../../../enum/role";
import { ViewSMSList } from "../../../components/report.component";

// 短信紀錄

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);

  const [params, setParams] = useState<any>({
    OwnerRole: 0,
    StartDate: timeL2S(dayjs(date[0]).format('YYYY-MM-DD HH:mm:ss')),
    EndDate: timeL2S(dayjs(date[1]).format('YYYY-MM-DD HH:mm:ss'))
  })

  const { data: merchantList } = $get({
    url: 'api/sms/merchant/list'
  })

  const { data: smsHistoryList, mutate, isValidating } = $get({
    url: 'api/sms/history/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        Merchant: formData.Merchant,
        OwnerRole: formData.OwnerRole,
        Account: formData.Account,
        PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
        PhoneNumber: formData.PhoneNumber,
        Status: formData.Status,
        StartDate:timeL2S(date[0]as string).toString(),
        EndDate:timeL2S(date[1]as string).toString(),
      })
    }
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setReset(!reset);
  }

  return (
    <div id="container">
      <Content className="p-2">
        <Form form={form} onFinish={onFinish} initialValues={{
          Merchant: '',
          OwnerRole: 0,
        }}>
          {/* 搜尋列 */}
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item className="w-12" name="Merchant">
                <Select options={[
                  { value: '', label: `${i18n.t('ALL')}` },
                  ...(merchantList?.Data.map((item: any) => (
                  { value: item.Code, label: item.Name }
                  )) || [])
                ]}/>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="w-12" name="OwnerRole">
                <Select
                  options={enumToOptions(SMS_ROLE)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="Account">
                <Input className="w-12" placeholder={`${i18n.t('account')}`} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Row>
                <Col span={8}>
                  <Form.Item name="PhonePrefix" rules={verify({ point: 7, required: false })}>
                    <Input autoComplete="off" placeholder={`${i18n.t('enterCountryCode')}`} addonBefore={'+'} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item name="PhoneNumber">
                    <Input placeholder={`${i18n.t('phoneNumber')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col>
              <Form.Item className="w-12" name="Status">
                <Select
                  placeholder={i18n.t('status')}
                  options={[
                    { value: "", label: `${i18n.t('ALL')}` },
                    ...enumToOptions(SMS_STATE)
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1" align="middle" gutter={[12, 12]}>
            <DatePickerCol width={191} date={date} setDate={setDate} initDate={reset} />
            <Col>
              <Button htmlType="submit" type="primary">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          {/* 搜尋結果 */}
          <Row className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewSMSList data={smsHistoryList}/>
                <LayoutPagination total={smsHistoryList ? smsHistoryList.TotalRecord : 0} setPage={setPage} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;