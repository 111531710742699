import PageReport from "pages/admin/report";
import PageReportAgent from "pages/admin/report/agent";
import PageReportBusiness from "pages/admin/report/business";
import PageReportGame from "pages/admin/report/game";
import PageReportLadder from "pages/admin/report/ladder";
import PageReportPromotion from "pages/admin/report/promotion";
import PageReportSettlement from "pages/admin/report/settlement";
import PageReportSettlementSetting from "pages/admin/report/settlement-setting";
import PageReportWinlose from "pages/admin/report/winlose";
import PageHome from 'pages/home';
import PageReportMessage from "pages/admin/report/sms";

export const reportModule = [
  { path: '/report/:account?', element: <PageReport/> },
  { path: '/report/summary', element: <PageHome/> },
  { path: '/report/business', element: <PageReportBusiness/> },
  { path: '/report/game', element: <PageReportGame/> },
  { path: '/report/agent', element: <PageReportAgent/> },
  { path: '/report/promotion', element: <PageReportPromotion/> },
  { path: '/report/ladder', element: <PageReportLadder/> },
  { path: '/report/winlose', element: <PageReportWinlose/> },
  { path: '/report/settlement', element: <PageReportSettlement/> },
  { path: '/report/settlement/setting', element: <PageReportSettlementSetting/> },
  { path: '/report/sms', element: <PageReportMessage/> },
]