import { Button, Col, Descriptions, Form, Input, InputNumber, Popover, Row, Select, Spin, Table, Tag, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { SelectAgentPermission } from 'components/agent.component';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_AGENT } from 'constants/response';
import { SETTLE_CYCLE } from 'enum/promotion';
import { AGENT_STATE, ENABLE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import {
  decimalNumber as $cc,
  toFormatNumber as $f,
  convertedToPercentage01 as $g,
  enumToOptions,
  verify,
  timeS2L,
  convertedToPercentage02 as $p2n
} from "utils/common";

// 編輯總代 / 編輯代理

const PageMain: React.FC = () => {
  const navigate = useNavigate();

  const { permissions: $p } = useAccount();
  const { id } = useParams();
  const [form] = useForm();
  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [isOpenRebatePopover, setIsOpenRebatePopover] = useState(false);
  const [isOpenShareRatePopover, setIsOpenShareRatePopover] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isEnterPassword, setIsEnterPassword] = useState(false);
  const [isDisConfirmPassword, setDisConfirmPassword] = useState(true);

  // 代理標籤
  const { data: tagList, isValidating: tagValidating } = $get({ url: 'api/tag/list' });
  const enableTagList= tagList?.Data.filter((item: any)=>item.Status === ENABLE.enabled)

  // 取得這代基本資料
  const { data: agent, mutate, isValidating } = $get({
    url: 'api/agent/one',
    params: { Id: id },
    allow: !!id
  });
  useEffect(() => {
    if (agent && tagList) {
      const Tags = JSON.parse(agent.Data.TagsId)
        .filter((tag: any) => enableTagList.some((item: any) => item.Id === tag));
      form.setFieldsValue({
        Account: agent.Data.Account,
        Remark: agent.Data.Remark,
        Status: agent.Data.Status,
        TagsId: Tags,
        RefCode: agent.Data.RefCode,

        // 返水占成週期
        CommissionSettleCycle: agent.Data.CommissionSettleCycle,
        RevenueShareSettleCycle: agent.Data.RevenueShareSettleCycle,
      })
    }
  }, [agent, tagList])

  // 取得上代返水(最大值)
  const [parentRebate, setParentRebate] = useState<any>({
    BonusCostRate: 100,
    CashFlowFeeRate: 100,

    EGamesCommissionRate: 100,
    LiveCommissionRate: 100,
    SportsCommissionRate: 100,
    ESportsCommissionRate: 100,
    PokerCommissionRate: 100,
    FishingCommissionRate: 100,
    CockfightCommissionRate: 100,
    LotteryCommissionRate: 100,
    SlotCommissionRate: 100,

    EGamesRevenueShareRate: 100,
    LiveRevenueShareRate: 100,
    SportsRevenueShareRate: 100,
    ESportsRevenueShareRate: 100,
    PokerRevenueShareRate: 100,
    FishingRevenueShareRate: 100,
    CockfightRevenueShareRate: 100,
    LotteryRevenueShareRate: 100,
    SlotRevenueShareRate: 100,
  });
  const { data: parentRebateInfo } = $get({
    url: 'api/agentoperationparams/one',
    params: { AgentId: agent?.Data?.AGId },
    allow: !!agent?.Data?.AGId && agent?.Data?.Level !== 0
  })
  useEffect(() => {
    if (parentRebateInfo) {
      setParentRebate({
        BonusCostRate: $g(parentRebateInfo?.Data.BonusCostRate),
        CashFlowFeeRate: $g(parentRebateInfo?.Data.CashFlowFeeRate),

        EGamesCommissionRate: $g(parentRebateInfo?.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(parentRebateInfo?.Data.LiveCommissionRate),
        SportsCommissionRate: $g(parentRebateInfo?.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(parentRebateInfo?.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(parentRebateInfo?.Data.PokerCommissionRate),
        FishingCommissionRate: $g(parentRebateInfo?.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(parentRebateInfo?.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(parentRebateInfo?.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(parentRebateInfo?.Data.SlotCommissionRate),

        EGamesRevenueShareRate: $g(parentRebateInfo?.Data.EGamesRevenueShareRate),
        LiveRevenueShareRate: $g(parentRebateInfo?.Data.LiveRevenueShareRate),
        SportsRevenueShareRate: $g(parentRebateInfo?.Data.SportsRevenueShareRate),
        ESportsRevenueShareRate: $g(parentRebateInfo?.Data.ESportsRevenueShareRate),
        PokerRevenueShareRate: $g(parentRebateInfo?.Data.PokerRevenueShareRate),
        FishingRevenueShareRate: $g(parentRebateInfo?.Data.FishingRevenueShareRate),
        CockfightRevenueShareRate: $g(parentRebateInfo?.Data.LiveRevenueShareRate),
        LotteryRevenueShareRate: $g(parentRebateInfo?.Data.LotteryRevenueShareRate),
        SlotRevenueShareRate: $g(parentRebateInfo?.Data.SlotRevenueShareRate),
      })
    }
  }, [parentRebateInfo]);

  // 取得這代返水
  const { data: RebateInfo, isValidating: rebateValidating } = $get({
    url: 'api/agentoperationparams/one',
    params: { AgentId: id }
  })
  useEffect(() => {
    if (RebateInfo) {
      form.setFieldsValue({
        BonusCostRate: $g(RebateInfo?.Data.BonusCostRate),
        CashFlowFeeRate: $g(RebateInfo?.Data.CashFlowFeeRate),

        EGamesCommissionRate: $g(RebateInfo?.Data.EGamesCommissionRate),
        LiveCommissionRate: $g(RebateInfo?.Data.LiveCommissionRate),
        SportsCommissionRate: $g(RebateInfo?.Data.SportsCommissionRate),
        ESportsCommissionRate: $g(RebateInfo?.Data.ESportsCommissionRate),
        PokerCommissionRate: $g(RebateInfo?.Data.PokerCommissionRate),
        FishingCommissionRate: $g(RebateInfo?.Data.FishingCommissionRate),
        CockfightCommissionRate: $g(RebateInfo?.Data.CockfightCommissionRate),
        LotteryCommissionRate: $g(RebateInfo?.Data.LotteryCommissionRate),
        SlotCommissionRate: $g(RebateInfo?.Data.SlotCommissionRate),

        EGamesRevenueShareRate: $g(RebateInfo?.Data.EGamesRevenueShareRate),
        LiveRevenueShareRate: $g(RebateInfo?.Data.LiveRevenueShareRate),
        SportsRevenueShareRate: $g(RebateInfo?.Data.SportsRevenueShareRate),
        ESportsRevenueShareRate: $g(RebateInfo?.Data.ESportsRevenueShareRate),
        PokerRevenueShareRate: $g(RebateInfo?.Data.PokerRevenueShareRate),
        FishingRevenueShareRate: $g(RebateInfo?.Data.FishingRevenueShareRate),
        CockfightRevenueShareRate: $g(RebateInfo?.Data.CockfightRevenueShareRate),
        LotteryRevenueShareRate: $g(RebateInfo?.Data.LotteryRevenueShareRate),
        SlotRevenueShareRate: $g(RebateInfo?.Data.SlotRevenueShareRate),
      })
    }
  }, [RebateInfo])

  const handleSetAllRebate = (value: string) => {
    if ($cc(value)) {
      form.setFieldsValue({
        EGamesCommissionRate: value,
        LiveCommissionRate: value,
        SportsCommissionRate: value,
        ESportsCommissionRate: value,
        PokerCommissionRate: value,
        FishingCommissionRate: value,
        CockfightCommissionRate: value,
        LotteryCommissionRate: value,
        SlotCommissionRate: value
      })
    }
  }

  const handleSetAllShareRate = (value: string) => {
    if ($cc(value)) {
      form.setFieldsValue({
        SlotRevenueShareRate: value,
        EGamesRevenueShareRate: value,
        LiveRevenueShareRate: value,
        SportsRevenueShareRate: value,
        ESportsRevenueShareRate: value,
        PokerRevenueShareRate: value,
        FishingRevenueShareRate: value,
        CockfightRevenueShareRate: value,
        LotteryRevenueShareRate: value
      })
    }
  }

  // 更新資料
  const [permissions, setPermissions] = useState([]);
  const save = (formData: any) => {
    if (isEnterPassword && formData.confirmNewPassword === '') return message.error(i18n.t('pleaseEnterConfirmationPassword'));
    if (permissions.length === 0) {
      return message.error(i18n.t('pleaseCheckTheBackendPermissions'));
    }
    $post({
      url: 'api/agent/update',
      send: {
        Id: id,
        Status: formData.Status,
        Password: (formData.password && formData.password !== '') ? formData.password : null,
        ConfirmPassword: (formData.confirmNewPassword && formData.confirmNewPassword !== '') ? formData.confirmNewPassword : null,
        TagsId: formData.TagsId || [],
        Remark: formData.Remark,
        RefCode: formData.RefCode,
        Permission: permissions
      },
      success: (response: any) => {
        if (response.State === 'Success') {
          $post({
            url: 'api/agentoperationparams/update',
            send: {
              AgentId: id,
              CashFlowFeeRate: parseFloat($p2n(formData.CashFlowFeeRate)),
              BonusCostRate: parseFloat($p2n(formData.BonusCostRate)),
              EGamesCommissionRate: parseFloat($p2n(formData.EGamesCommissionRate)),
              SlotCommissionRate: parseFloat($p2n(formData.SlotCommissionRate)),
              LiveCommissionRate: parseFloat($p2n(formData.LiveCommissionRate)),
              SportsCommissionRate: parseFloat($p2n(formData.SportsCommissionRate)),
              ESportsCommissionRate: parseFloat($p2n(formData.ESportsCommissionRate)),
              PokerCommissionRate: parseFloat($p2n(formData.PokerCommissionRate)),
              FishingCommissionRate: parseFloat($p2n(formData.FishingCommissionRate)),
              CockfightCommissionRate: parseFloat($p2n(formData.CockfightCommissionRate)),
              LotteryCommissionRate: parseFloat($p2n(formData.LotteryCommissionRate)),
              EGamesRevenueShareRate: parseFloat($p2n(formData.EGamesRevenueShareRate)),
              SlotRevenueShareRate: parseFloat($p2n(formData.SlotRevenueShareRate)),
              LiveRevenueShareRate: parseFloat($p2n(formData.LiveRevenueShareRate)),
              SportsRevenueShareRate: parseFloat($p2n(formData.SportsRevenueShareRate)),
              ESportsRevenueShareRate: parseFloat($p2n(formData.ESportsRevenueShareRate)),
              PokerRevenueShareRate: parseFloat($p2n(formData.PokerRevenueShareRate)),
              FishingRevenueShareRate: parseFloat($p2n(formData.FishingRevenueShareRate)),
              CockfightRevenueShareRate: parseFloat($p2n(formData.CockfightRevenueShareRate)),
              LotteryRevenueShareRate: parseFloat($p2n(formData.LotteryRevenueShareRate)),
            },
            success: () => {
              mutate();
              message.success(i18n.t('updateSuccess'));
              setConfirm(true);
              setIsAllDisabled(true);
              setIsOpenRebatePopover(false);
              setIsOpenShareRatePopover(false);
              navigate('/agent');
            },
            resCode: RESPONSE_CODE_AGENT
          })
        }
      },
      resCode: RESPONSE_CODE_AGENT
    })
  }

  const handleClickManualVerification = () => {
    $post({
      url: 'api/agent/manual/verify',
      send: { Id: agent?.Data?.Id, },
      success: () => {
        message.success(i18n.t('verifySuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_AGENT
    })
  }

  const handlePassword = () => {
    setDisConfirmPassword(true);
    form.setFieldValue('confirmNewPassword', '');
  }

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={save}>
          <Form.Item name="RefCode" style={{ display: 'none' }}></Form.Item>
          <Spin spinning={isValidating || tagValidating}>
            {/* 基本資料 */}
            <Row align="middle">
              <Col span={24}>
                <Row align="middle" justify="end" gutter={[12, 12]}>
                  <Col><Button onClick={() => navigate('/agent')}>{i18n.t('cancel')}</Button></Col>
                  {
                    $p('40203') &&
                    <Col><Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button></Col>
                  }
                </Row>
              </Col>
              <Col span={24}>
                <Descriptions size="small" layout="horizontal" title={i18n.t('edit')} column={{ xs: 8 }}
                  contentStyle={{ maxWidth: 500, width: 500 }}
                  labelStyle={{ fontSize: 12, fontWeight: 500, maxWidth: 200, width: 200 }}
                  bordered>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('account')}</>}>
                    <Form.Item name="Account">
                      <Input disabled />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<>{isEnterPassword && <span className="require">*</span>}{i18n.t('password')}</>} >
                    <Form.Item name="password" required={isEnterPassword} rules={[
                      { min: 4, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                      { max: 20, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                    ]}>
                      <Input.Password disabled={confirm || !$p('40203')} placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`}
                        onChange={e => {
                          setDisConfirmPassword(e.target.value === '');
                          setIsEnterPassword(e.target.value !== '');
                          e.target.value === '' && handlePassword()
                        }} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<>{isEnterPassword && <span className="require">*</span>}{i18n.t('confirmNewPassword')}</>}>
                    <Form.Item name="confirmNewPassword" required={isEnterPassword} rules={[
                      { min: 4, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                      { max: 20, message: `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}` },
                    ]}>
                      <Input.Password disabled={isDisConfirmPassword ? isDisConfirmPassword : confirm}
                        placeholder={`4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('status')}</>}>
                    <Form.Item name="Status">
                      <Select
                        className="w-full"
                        disabled={agent?.Status === 0 || !$p('40203')}
                        options={enumToOptions(AGENT_STATE)}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 手動驗證 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('verificationDate')}</>}>
                    {agent?.Data?.Status === 0
                      ? <Button disabled={
                        ( $p('40203') && !$p('40206')) ||
                        (!$p('40203') && !$p('40206'))
                      } type="primary" onClick={handleClickManualVerification}>{i18n.t('manualVerification')}</Button>
                      : <Input value={timeS2L(agent?.Data?.VerifiedDate)} disabled />
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('agentTag')}>
                    <Form.Item name="TagsId">
                      <Select
                        mode="tags"
                        disabled={confirm || !$p('40203')}
                        className="w-full"
                        placeholder={i18n.t('pleaseSelect')}
                        options={
                          tagList?.Data
                            .filter((item: any)=>item.Status === ENABLE.enabled)
                            .map((option: any ) => ({
                            value: option.Id,
                            label:
                              <Tag key={option.Id} style={{ color: option.TextColor, background: option.Color }}>
                                {option.Name}
                              </ Tag>
                          }))
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 優惠占成 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('promotionRevenue')}%</>}>
                    <Form.Item name="BonusCostRate" rules={[
                      ...verify({ max: parentRebate.BonusCostRate || 100 }),
                    ]}>
                      <InputNumber className="w-full" disabled={confirm || !$p('40203')}
                        placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  {/* 金流費率 */}
                  <Descriptions.Item label={<><span className="require">*</span>{i18n.t('paymentFeeRate')}</>}>
                    <Form.Item name="CashFlowFeeRate" rules={[
                      ...verify({ max: parentRebate.CashFlowFeeRate || 100 }),
                    ]}>
                      <InputNumber className="w-full" disabled={confirm || !$p('40203')}
                        placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('remark')}>
                    <Form.Item name="Remark">
                      <Input disabled={confirm || !$p('40203')} placeholder={`${i18n.t('inputData')}`} maxLength={100} showCount/>
                    </Form.Item>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>

            {/* 後台權限 */}
            <Row align="middle" className="mt-1">
              <Col span={24}>
                <Col className="size-16 font-w-md">{i18n.t('backOfficePermissions')}</Col>
                <Col span={24} className="mt-1">
                  <SelectAgentPermission agent={agent?.Data} permissions={permissions}
                    setPermissions={setPermissions} confirm={confirm} disabled={!$p('40203')} />
                </Col>
              </Col>
            </Row>
          </Spin>
          {/* 週期設定 */}
          <Row align="middle" className="mt-1" gutter={[12, 12]}>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('rebateSettlementPeriod')}</>}>
                  <Form.Item name="CommissionSettleCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions size="small" layout="vertical" title={i18n.t('cashbackCommissionSetting')} bordered
                labelStyle={{ fontSize: 12, fontWeight: 500 }}>
                <Descriptions.Item style={{ width: '50%' }} label={<><span className="require">*</span>{i18n.t('revenueSettlementPeriod')}</>}>
                  <Form.Item name="RevenueShareSettleCycle">
                    <Select
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      options={enumToOptions(SETTLE_CYCLE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          {/* 返水占成 */}
          <Row className="mt-1 mb-3">
            <Col span={24} className="mt-1">
              <Spin spinning={rebateValidating}>
                <Row align="middle" gutter={[12, 12]}>
                  <Col span={12}>
                    <Row justify="end">
                      <Col>
                        <Popover placement="bottomLeft" trigger="click" open={isOpenRebatePopover}
                          content={
                            <Row gutter={[12, 12]}>
                              <Col span={24} className="w-6">
                                <Form.Item name="batchSetting01">
                                  <Input placeholder={`${i18n.t('inputData')}`}
                                    onChange={e => handleSetAllRebate(e.target.value)} />
                                </Form.Item>
                              </Col>
                              <Col span={24} className="center">
                                <Button size="small" onClick={() => setIsOpenRebatePopover(false)}>
                                  {i18n.t('close')}
                                </Button>
                              </Col>
                            </Row>
                          }>
                          <Button type="primary" disabled={isAllDisabled || !$p('40203')}
                            onClick={() => setIsOpenRebatePopover(!isOpenRebatePopover)}>
                            {i18n.t('batchSetting')}
                          </Button>
                        </Popover>
                      </Col>
                    </Row>
                    <Table
                      bordered
                      className="mt-1"
                      size="small"
                      columns={[
                        {
                          title: i18n.t('gameCategory'),
                          dataIndex: 'game',
                          className: 'color-03',
                          width: '25%',
                          render: (_, { game, name }) => `${game} % (max：${$f(parentRebate[name], 2)})`
                        },
                        {
                          title:
                            <>
                              <span className="require">*</span>{i18n.t('rebateRate')} %
                            </>,
                          width: '25%',
                          render: (_, { name }) => (
                            <Form.Item name={name} rules={verify({ max: $f(parentRebate[name], 2) })}>
                              <Input className="size-12" disabled={isAllDisabled || !$p('40203')} />
                            </Form.Item>
                          )
                        },
                      ]}
                      dataSource={[
                        {
                          game: i18n.t('EGAMES'),
                          name: 'EGamesCommissionRate',
                        },
                        {
                          game: i18n.t('LIVE'),
                          name: 'LiveCommissionRate',
                        },
                        {
                          game: i18n.t('SPORTS'),
                          name: 'SportsCommissionRate',
                        },
                        {
                          game: i18n.t('ESPORTS'),
                          name: 'ESportsCommissionRate',
                        },
                        {
                          game: i18n.t('POKER'),
                          name: 'PokerCommissionRate',
                        },
                        {
                          game: i18n.t('FISHING'),
                          name: 'FishingCommissionRate',
                        },
                        {
                          game: i18n.t('COCKFIGHT'),
                          name: 'CockfightCommissionRate',
                        },
                        {
                          game: i18n.t('LOTTERY'),
                          name: 'LotteryCommissionRate',
                        },
                        {
                          game: i18n.t('SLOT'),
                          name: 'SlotCommissionRate',
                        }
                      ].map(item => ({ key: item.name , ...item }))}
                      pagination={false}
                    />
                  </Col>
                  <Col span={12}>
                    <Row justify="end">
                      <Col>
                        <Popover placement="bottomLeft" trigger="click" open={isOpenShareRatePopover} content={
                          <Row gutter={[12, 12]}>
                            <Col span={24} className="w-6">
                              <Form.Item name="batchSetting02">
                                <Input placeholder={`${i18n.t('inputData')}`}
                                  onChange={e => handleSetAllShareRate(e.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col span={24} className="center">
                              <Button size="small" onClick={() => setIsOpenShareRatePopover(false)}>
                                {i18n.t('close')}
                              </Button>
                            </Col>
                          </Row>
                        }>
                          <Button type="primary" disabled={isAllDisabled || !$p('40203')}
                            onClick={() => setIsOpenShareRatePopover(!isOpenShareRatePopover)}>
                            {i18n.t('batchSetting')}
                          </Button>
                        </Popover>
                      </Col>
                    </Row>
                    <Table
                      className="mt-1"
                      bordered
                      size="small"
                      columns={[
                        {
                          title: i18n.t('gameCategory'),
                          dataIndex: 'game',
                          className: 'color-03',
                          width: '25%',
                          render: (_, { game, name }) => `${game} % (max：${$f(parentRebate[name], 2)})`
                        },
                        {
                          title:
                            <><span className="require">*</span>{i18n.t('revenueRate')} %</>,
                          width: '25%',
                          render: (_, { name }) => (
                            <Form.Item name={name} rules={verify({ max: $f(parentRebate[name], 2) })}>
                              <Input className="size-12" disabled={isAllDisabled || !$p('40203')} />
                            </Form.Item>
                          )
                        },
                      ]}
                      dataSource={[
                        {
                          game: i18n.t('EGAMES'),
                          name: 'EGamesRevenueShareRate',
                        },
                        {
                          game: i18n.t('LIVE'),
                          name: 'LiveRevenueShareRate',
                        },
                        {
                          game: i18n.t('SPORTS'),
                          name: 'SportsRevenueShareRate',
                        },
                        {
                          game: i18n.t('ESPORTS'),
                          name: 'ESportsRevenueShareRate',
                        },
                        {
                          game: i18n.t('POKER'),
                          name: 'PokerRevenueShareRate',
                        },
                        {
                          game: i18n.t('FISHING'),
                          name: 'FishingRevenueShareRate',
                        },
                        {
                          game: i18n.t('COCKFIGHT'),
                          name: 'CockfightRevenueShareRate',
                        },
                        {
                          game: i18n.t('LOTTERY'),
                          name: 'LotteryRevenueShareRate',
                        },
                        {
                          game: i18n.t('SLOT'),
                          name: 'SlotRevenueShareRate',
                        }
                      ].map(item => ({ key: item.name , ...item }))}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;