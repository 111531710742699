import { Button, Col, Form, Input, message, Row, Select, Space, Spin } from "antd";
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import {
  PopupDeductExpectedValidBet,
  ViewRolloverList,
  ViewRolloverOverview,
} from "components/rollover.component";
import { DatePickerCol, InquiryWindow, LayoutNav, LayoutPagination } from "components/layout.component";
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from "react";
import { $get, $post } from "services";
import { tzShift, timeL2S } from "utils/common";
import mockContentsRolloverService from "services/contents/rollover.service";
import { ROLLOVER_TYPE } from "../../../constants/rollover";
import { RESPONSE_CODE_ROLLOVER_REVOKE } from "../../../constants/response";
import * as common from "../../../utils/common";

// 會員流水查詢

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState<boolean>(false);
  const [form] = useForm();
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [rolloverListParams, setRolloverListParams] = useState<any>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  });

  const [isModalOpenOneClickRevoke, setIsModalOpenOneClickRevoke] = useState(false);
  const [isModalOpenDeductExpectedValidBet, setIsModalOpenDeductExpectedValidBet] = useState(false);

  const { data: rolloverList, mutate, isValidating } = $get({
    url: 'api/turnoverticket/list',
    params: {
      ...rolloverListParams,
      PageIndex: page[0],
      PageSize: page[1],
    },
    allow: !!rolloverListParams?.MemberAccount,
    mock: mockContentsRolloverService
  })

  const [rolloverListData, setRolloverListData] = useState<any>([]);
  const [notPassListData, setNotPassListData] = useState<any>([]);
  const [notPassListDataIds, setNotPassListDataIds] = useState<any>([]);

  // 以下用於一鍵解除彈窗內的資訊
  // 應達有效投注
  const [expectedValidBet, setExpectedValidBet] = useState<any>(0);
  // 尚需有效投注
  const [validBetsStillRequired, setValidBetsStillRequired] = useState<any>(0);
  // 遊戲商/大類應達有效投注總計
  const [totalGameProviderOriginalValidBetAmount, setTotalGameProviderOriginalValidBetAmount] = useState<any>(0);
  // 遊戲商/大類尚需有效投注
  const [gameProviderValidBetsStillRequired, setGameProviderValidBetsStillRequired] = useState<any>(0);

  useEffect(() => {
    if (rolloverList) {
      setRolloverListData(rolloverList?.Data);

      // 未通過的流水數組資料
      const notPassData = rolloverList?.Data?.filter((item: any) => item?.Status === 0);
      setNotPassListData(notPassData);

      // 未通過的流水數組Id
      const notPassIds = notPassData?.map((item: any) => item?.Id);
      setNotPassListDataIds(notPassIds)


      // 1.未通過且2.GameProviders經過JSON.parse後為空的數組資料
      const notPassEmptyGameProviders = notPassData?.filter((item: any) => {
        try {
          const gameProviders = JSON?.parse(item?.GameProviders);
          return Array.isArray(gameProviders) && gameProviders?.length === 0;
        } catch (e) {
          return false;
        }
      });

      // 1.未通過且2.GameProviders經過JSON.parse後為空的數組資料 應達有效投注總和
      const emptyGameProvidersTotalOriginalValidBetAmount = notPassEmptyGameProviders?.reduce((sum: number, item: any) => {
        return sum + item?.OriginalValidBetAmount;
      }, 0);
      setExpectedValidBet(common.toFormatNumber(emptyGameProvidersTotalOriginalValidBetAmount))

      // 1.未通過且2.GameProviders經過JSON.parse後為空的數組資料 目前有效投注總和
      const emptyGameProvidersTotalAccumulatedValidBetAmount = notPassEmptyGameProviders?.reduce((sum: number, item: any) => {
        return sum + item?.AccumulatedValidBetAmount;
      }, 0);
       setValidBetsStillRequired(common.toFormatNumber(emptyGameProvidersTotalOriginalValidBetAmount- emptyGameProvidersTotalAccumulatedValidBetAmount))


      // 1.未通過且2.GameProviders經過JSON.parse後不為空的數組資料
      const notPassFillGameProviders = notPassData?.filter((item: any) => {
        try {
          const gameProviders = JSON.parse(item?.GameProviders);
          return Array.isArray(gameProviders) && gameProviders?.length !== 0;
        } catch (e) {
          return false;
        }
      });

      // 1.未通過且2.GameProviders經過JSON.parse後不為空的數組資料 應達有效投注總和
      const fillGameProvidersTotalOriginalValidBetAmount = notPassFillGameProviders?.reduce((sum: number, item: any) => {
        return sum + item?.OriginalValidBetAmount;
      }, 0);
      setTotalGameProviderOriginalValidBetAmount(common.toFormatNumber(fillGameProvidersTotalOriginalValidBetAmount))

      // 1.未通過且2.GameProviders經過JSON.parse後不為空的數組資料 目前有效投注總和
      const fillGameProvidersTotalAccumulatedValidBetAmount = notPassFillGameProviders?.reduce((sum: number, item: any) => {
        return sum + item?.AccumulatedValidBetAmount;
      }, 0);
      setGameProviderValidBetsStillRequired(common.toFormatNumber(fillGameProvidersTotalOriginalValidBetAmount-fillGameProvidersTotalAccumulatedValidBetAmount))
    }
  }, [rolloverList]);

  // 搜尋
  const search = (formData: any) => {
    setPage([1, page[1]]);
    setRolloverListParams({
      MemberAccount: formData?.Account,
      Category: formData?.Category,
      StartDate: timeL2S(date[0]as string).toString(),
      EndDate: timeL2S(date[1]as string).toString(),
    })
    mutate();
  }

  // 清除
  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
  }

  // 一鍵解除
  const submitOneClickRevoke = () => {
    $post({
      url: 'api/turnoverticket/unlock/update',
      send: {
        Ids: notPassListDataIds
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_ROLLOVER_REVOKE
    })
  }

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search}>
          {/* 搜尋 */}
          <Row align="middle" gutter={[12, 12]}>
            <Col className="w-12">
              <Form.Item name="Account" rules={[{ required: true, message: `${i18n.t('inputData')}${i18n.t('memberAccount')}` }]}>
                <Input placeholder={`${i18n.t('memberAccount')}`} />
              </Form.Item>
            </Col>
            <Col className='w-12'>
              <Form.Item name="Category">
                <Select
                  placeholder={i18n.t("category")}
                  options={Object.keys(ROLLOVER_TYPE)?.map((key) => ({
                    value: key,
                    label: i18n.t(ROLLOVER_TYPE[key as keyof typeof ROLLOVER_TYPE])
                  }))}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit" loading={isValidating}>{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="mt-1">
            <Col span={24}>
              <Spin spinning={isValidating}>
                <ViewRolloverOverview data={rolloverList} />
              </Spin>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="mt-1" >
            <Col>
              <Space>
                {
                  $p('21602') &&
                  <Button
                    type="primary"
                    onClick={() => { setIsModalOpenOneClickRevoke(true)
                    }}
                    disabled={notPassListData?.length === 0}
                  >{i18n.t('oneClickrevoke')}</Button>
                }
              </Space>
            </Col>
            <Col>
              <Space>
                {
                  $p('21603') &&
                  <Button type="primary" onClick={() => setIsModalOpenDeductExpectedValidBet(true)} disabled={notPassListData?.length === 0}>{i18n.t('deductExpectedValidBet')}</Button>
                }
              </Space>
            </Col>
          </Row>
        </Form>
        <Spin spinning={isValidating}>
          <ViewRolloverList data={rolloverList} mutate={mutate} />
          <LayoutPagination total={rolloverList && rolloverList?.TotalRecord} setPage={setPage} page={page} />
        </Spin>
        {/*一鍵解除彈窗*/}
        <InquiryWindow isOpen={isModalOpenOneClickRevoke} close={() => setIsModalOpenOneClickRevoke(false)}
                       customerMsg={
                         <>
                           <div>{`${i18n.t("expectedValidBet")} : ${expectedValidBet}。`} </div>
                           <div>{`${i18n.t("totalGameProviderOriginalValidBetAmount")} : ${totalGameProviderOriginalValidBetAmount}。`} </div>
                           <div>{`${i18n.t("validBetsStillRequired")} : ${validBetsStillRequired}。`} </div>
                           <div>{`${i18n.t("gameProviderValidBetsStillRequired")} : ${gameProviderValidBetsStillRequired}。`} </div>
                           <div>{`${i18n.t("onceCancelCannotBeRestored")}`}</div>
                         </>
                       } action={submitOneClickRevoke} />
        {/*扣除應達有效投注彈窗*/}
        <PopupDeductExpectedValidBet isOpen={isModalOpenDeductExpectedValidBet} close={() => setIsModalOpenDeductExpectedValidBet(false)} mutate={mutate} notPassListData={notPassListData}/>
      </Content>
    </div>
  );
};

export default PageMain;