import { Button, Col, Form, message, Row, Select, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InquiryWindow, LayoutNav, LayoutPagination, LayoutTabGateway } from 'components/layout.component';
import { PopupMerchantEdit } from 'components/verification.component';
import { GATEWAY, GATEWAY_CODE_DEPOSIT, GATEWAY_CODE_WITHDRAW, WALLET_TRANSLATE } from 'constants/gateway';
import { RESPONSE_CODE_MERCHANT } from 'constants/response';
import { ENABLE, IS_OFFLINE, TRANSFER_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, toFormatNumber } from 'utils/common';

// 出入款渠道設定

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();
  const [formGroup] = Form.useForm();
  const [form] = Form.useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [showTable, setShowTable] = useState<TRANSFER_TYPE>(TRANSFER_TYPE.withdrawal);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [currentItem, setCurrentItem] = useState<Merchant>({});
  const [isOffline, setIsOffline] = useState<IS_OFFLINE | null>();
  const [gateway, setGateway] = useState<GATEWAY | null>();
  const [isOpenInquiry, setIsOpenInquiry] = useState<boolean>(false);
  const [inquiryMsg, setInquiryMsg] = useState<string>('');

  const { id: specifyId } = useParams();

  // 群組列表
  const { data: groupList } = $get({
    url: 'api/membergroup/all/list'
  })
  useEffect(() => {
   if (groupList) {
    // 接收外部id
    const id = !!specifyId || Number(specifyId) === 0 ? Number(specifyId) : groupList?.Data[0].Id;
    setParams((item: any) => ({ ...item, MemberGroupId: id }));
    formGroup.setFieldValue('MemberGroupId', id);
   }
  }, [groupList]);

  const [params, setParams] = useState<any>({
  })

  // 起始頁面
  useEffect(() => {
    if (init) {
      setShowTable(!$p('21401') && $p('21501') ? TRANSFER_TYPE.deposit : TRANSFER_TYPE.withdrawal);
    }
   }, [init]);

  //  切換列表
  useEffect(() => {
    if (showTable) {
      setParams((item: any) => ({ MemberGroupId: item.MemberGroupId, Type: showTable }));
      onClear();
    }
  }, [showTable]);

  const { data, isValidating, mutate } = $get({
    url: 'api/membergroupmerchant/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: (!!params.MemberGroupId || params.MemberGroupId === 0) && !!params.Type
  })

  const search = (formData: any) => {
    let GatewayCode = '';

    if (formData.GatewayCode) {
      const isWithdrawal = showTable === TRANSFER_TYPE.withdrawal;
      const isBank = formData.GatewayCode === GATEWAY.WITHDRAW_GATE_BANK;

      if (isWithdrawal) GatewayCode = isBank ? GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK_OFFLINE : GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO_OFFLINE
      else GatewayCode = isBank ? GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK_OFFLINE : GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_CRYPTO_OFFLINE
    }
 
    const [_gatewayCode, Name] = (formData[gateway === GATEWAY.WITHDRAW_GATE_CRYPTO ? 'GatewayCodeNameC' : 'GatewayCodeNameB'] || '$$').split('$$')

    setPage([1, page[1]]);
    setParams((val: any) => ({
      ...val,
      IsOffline: formData.IsOffline,
      GatewayCode,
      Name
    }))
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setGateway(null);
    setIsOffline(null);
  }

  // 渠道名稱
  const { data: bankList } = $get({
    url: 'api/membergroupmerchant/name/list',
    params: {
      Type: params.Type,
      IsOffline: isOffline,
      GatewayCode: params.Type === 1 ? 'DEPOSIT_GATE_BANK_OFFLINE' : 'WITHDRAW_GATE_BANK_OFFLINE'
    },
    allow: (!!isOffline || isOffline === 0) && !!params.Type
  })

  const { data: cryptoList } = $get({
    url: 'api/membergroupmerchant/name/list',
    params: {
      Type: params.Type,
      IsOffline: isOffline,
      GatewayCode: params.Type === 1 ? 'DEPOSIT_GATE_CRYPTO_OFFLINE' : 'WITHDRAW_GATE_CRYPTO_OFFLINE'
    },
    allow: gateway === GATEWAY.WITHDRAW_GATE_CRYPTO
  })

  const handleEditState = () => {
    $post({
      url: 'api/membergroupmerchant/status/update',
      send: {
        Type: params.Type,
        MemberGroupId: params.MemberGroupId,
        MerchantId: currentItem.MerchantId,
        Status: currentItem.Status === ENABLE.enabled ? ENABLE.disabled : ENABLE.enabled,
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MERCHANT
    })
  }

  const displayName: any = (record: any) => {
    return record?.GatewayCode.includes('OFFLINE')
      ? `${i18n.t(record?.BankName || record?.Crypto)} - ${record?.CustomName}`
      : `${i18n.t(record?.Name)} - ${record?.CustomName}`
  }

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={formGroup} layout="vertical" className='pb-1'>
          {/* 群組名稱 */}
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="MemberGroupId" className="w-12" label={i18n.t('groupName')}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <Select 
                  placeholder={i18n.t('pleaseSelect')}
                  options={groupList?.Data.map((item: any) => ({
                    value: item.Id, label: item.Name
                  }))} 
                  onChange={val => {
                    setParams((p: any) => ({ ...p, MemberGroupId: val }))
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <LayoutTabGateway showTable={showTable} setShowTable={setShowTable} />

        <Form form={form} onFinish={search}>
          <Row gutter={[12, 12]}>
            {/* 類型 */}
            <Col className="w-12">
              <Form.Item name="IsOffline">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('type')}`}
                  options={enumToOptions(IS_OFFLINE)}
                  onChange={v => {
                    setIsOffline(v);
                    form.setFieldValue('GatewayCode', null);
                    form.setFieldValue('GatewayCodeNameC', null);
                    form.setFieldValue('GatewayCodeNameB', null);
                  }}
                />
              </Form.Item>
            </Col>
            {/* 出款方式 */}
            {isOffline === IS_OFFLINE.transfer &&
            <Col className="w-12">
              <Form.Item name="GatewayCode">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t(showTable === TRANSFER_TYPE.withdrawal ? 'withdrawalType' : 'depositMethod')}`}
                  options={enumToOptions(GATEWAY).map(({ value, label }) => ({
                    value, label: WALLET_TRANSLATE[label]
                  }))}
                  onChange={v => {
                    setGateway(v);
                    form.setFieldValue('GatewayCodeNameC', null);
                    form.setFieldValue('GatewayCodeNameB', null);
                  }}
                />
              </Form.Item>
            </Col>}
            {gateway === GATEWAY.WITHDRAW_GATE_CRYPTO
            // 幣種
            ? <Col className="w-12">
              <Form.Item name="GatewayCodeNameC">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('currency')}`}
                  options={cryptoList?.Data.map((item: any) => ({
                    value: `${item.GatewayCode}$$${item.Name}`, label: item.Name
                  }))}
                  disabled={!(isOffline || isOffline === IS_OFFLINE.thirdParty)}
                />
              </Form.Item>
            </Col>
            // 渠道名稱
            : <Col className="w-12">
              <Form.Item name="GatewayCodeNameB">
                <Select
                  style={{ width: '100%' }}
                  placeholder={`${i18n.t('channelName')}`}
                  options={bankList?.Data.map((item: any) => ({
                    value: `${item.GatewayCode}$$${item.Name}`, label: item.Name
                  }))}
                  disabled={
                    !(isOffline || isOffline === IS_OFFLINE.thirdParty) || 
                    (isOffline === IS_OFFLINE.transfer && !gateway)
                  }
                />
              </Form.Item>
            </Col>}

            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
        </Form>

        {/* 出款 */}
        {showTable === TRANSFER_TYPE.withdrawal &&
        <Table
          loading={isValidating}
          size="middle"
          className="mt-1"
          dataSource={data?.Data.map((item: Merchant) => ({ key: `${TRANSFER_TYPE.withdrawal}${item.MerchantId}`, ...item }))}
          columns={[
            {
              dataIndex: 'IsOffline',
              title: i18n.t('type'),
              width: 120,
              render: (val) => i18n.t(IS_OFFLINE[val]),
            },
            {
              dataIndex: 'Name',
              title: `${i18n.t('channelName')} - ${i18n.t('displayName')}`,
              width: 200,
              render: (val, record: any) => displayName(record)
            },
            {
              dataIndex: 'OperateTimesPerDay',
              title: i18n.t('MaximumWithdrawalsPerDay'),
              align: 'right',
              width: 200,
              render: (val) => (val || val === 0) ? toFormatNumber(val, 0) : '-'
            },
            {
              dataIndex: 'MaxAmountPerTrans',
              title: i18n.t('MaximumWithdrawalSingle'),
              align: 'right',
              width: 200,
              render: (val) => !!val ? toFormatNumber(val) : '-'
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              width: 80,
              render: (val, record) => (
                <Switch
                  checked={val === ENABLE.enabled}
                  onChange={val => {
                    setCurrentItem(record);
                    const name = displayName(record);
                    setInquiryMsg(`${i18n.t(val ? 'confirmActivation' :'confirmDeactivation' )} ${name}`);
                    setIsOpenInquiry(true);
                  }}
                  disabled={!$p('21403')}
                />
              )
            },
            {
              title: i18n.t('operation'),
              width: 120,
              render: (_, record) => (
                <>
                  <Button className="size-12" type="link" disabled={!$p('21402')} onClick={() => {
                    setCurrentItem(record);
                    setIsOpenEdit(true);
                  }}>{i18n.t('edit')}</Button>
                </>
              ),
            },
          ]}
          pagination={false}
        />}

        {/* 入款 */}
        {showTable === TRANSFER_TYPE.deposit && 
        <Table
          loading={isValidating}
          size="middle"
          className="mt-1"
          dataSource={data?.Data.map((item: Merchant) => ({ key: `${TRANSFER_TYPE.deposit}${item.MerchantId}`, ...item }))}
          columns={[
            {
              dataIndex: 'IsOffline',
              title: i18n.t('type'),
              width: 120,
              render: (val) => i18n.t(IS_OFFLINE[val]),
            },
            {
              dataIndex: 'Name',
              title: `${i18n.t('channelName')} - ${i18n.t('displayName')}`,
              width: 200,
              render: (val, record: any) => displayName(record)
            },
            {
              dataIndex: 'OperateTimesPerDay',
              title: i18n.t('MaximumDepositPerDay'),
              align: 'right',
              width: 100,
              render: (val) => (val || val === 0) ? toFormatNumber(val, 0) : '-'
            },
            {
              dataIndex: 'MinAmountPerTrans',
              title: i18n.t('MinimumDepositSingle'),
              align: 'right',
              width: 150,
              render: (val) => toFormatNumber(val)
            },
            {
              dataIndex: 'MaxAmountPerTrans',
              title: i18n.t('MaximumDepositSingle'),
              align: 'right',
              width: 150,
              render: (val) => !!val ? toFormatNumber(val) : '-'
            },
            {
              dataIndex: 'Status',
              title: i18n.t('status'),
              width: 80,
              render: (val, record) => (
                <Switch
                  checked={val === ENABLE.enabled}
                  onChange={val => {
                    setCurrentItem(record);
                    const name = displayName(record);
                    setInquiryMsg(`${i18n.t(val ? 'confirmActivation' : 'confirmDeactivation')} ${name}`);
                    setIsOpenInquiry(true);
                  }}
                  disabled={!$p('21503')}
                />
              )
            },
            {
              title: i18n.t('operation'),
              width: 120,
              render: (_, record) => (
                <>
                  <Button className="size-12" type="link" disabled={!$p('21502')} onClick={() => {
                    setCurrentItem(record);
                    setIsOpenEdit(true);
                  }}>{i18n.t('edit')}</Button>
                </>
              ),
            },
          ]}
          pagination={false}
        />}
        {/* 頁碼共用 */}
        <LayoutPagination total={data?.TotalRecord} setPage={setPage} page={page} />

        {/* 編輯彈窗 */}
        <PopupMerchantEdit isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} currentItem={currentItem} mutate={mutate} displayName={displayName} />
        {/* 詢問視窗 */}
        <InquiryWindow isOpen={isOpenInquiry} close={() => setIsOpenInquiry(false)} msg={inquiryMsg} action={handleEditState} />
      </Content >
    </div >
  );
};

export default PageMain;