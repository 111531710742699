import { Button, Col, Form, Input, Row, Select, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, Export, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import { MEMBER_FREEZE_REPORT, MEMBER_STATE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get } from 'services';
import * as common from 'utils/common';
import { timeL2S } from "utils/common";

// 會員排名

interface SearchParams {
  RankingType?: number;
  StartDate: string | DATE_TYPE;
  EndDate: string | DATE_TYPE;
  MemberAccount?: string;
  UpMemberAccount?: string;
  AGAccount?: string;
  SAAccount?: string;
  MemberStatus?: number;
  IsFreeze?: number;
}

enum REPORT_MEMBER_RANK {
  deposit = 1,
  withdrawing = 2,
  betAmountValid = 3,
  memberWin = 4,
  memberLoss = 5
}

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [reset, setReset] = useState(false);
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('month'), 'YYYY-MM-DD HH:mm:ss'),
    DATE_TYPE.thisMonth
  ]);
  const [params, setParams] = useState<SearchParams>({
    RankingType: REPORT_MEMBER_RANK.memberWin,
    StartDate: timeL2S(date[0] as string),
    EndDate: timeL2S(date[1] as string),
  });
  const { data: RankList, isValidating, mutate } = $get({
    url: 'api/report/ranking/member/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        ...params,
        MemberAccount: formData.memberAccount,
        UpMemberAccount: formData.recommendedMembers,
        AGAccount: formData.agentAccount,
        SAAccount: formData.masterAgent,
        MemberStatus: formData.MemberStatus,
        IsFreeze: formData.Status,
        RankingType: formData.type,
        StartDate: timeL2S(date[0] as string),
        EndDate: timeL2S(date[1] as string)
      })
    }
    mutate();
  }

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    form.resetFields();
  }

  return (
    <div id="container">
      <Content>
        <Form form={form} onFinish={onFinish} initialValues={{
          type: REPORT_MEMBER_RANK.memberWin
        }}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="memberAccount">
                    <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="recommendedMembers">
                    <Input className="w-12" placeholder={`${i18n.t('recommendedMembers')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="agentAccount">
                    <Input className="w-12" placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="masterAgent">
                    <Input className="w-12" placeholder={`${i18n.t('masterAgent')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="Status" className="w-12">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('status')}`}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...common.enumToOptions(MEMBER_FREEZE_REPORT)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="MemberStatus" className="w-12">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('auditStatus')}`}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...common.enumToOptions(MEMBER_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="type" className="w-12">
                    <Select
                      className="w-full"
                      options={common.enumToOptions(REPORT_MEMBER_RANK)}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={191} date={date} setDate={setDate} initDate={reset} defaultDateType={DATE_TYPE.thisMonth} />
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  {$p('60702') && <Export url={'/report/ranking/member/list/export'} param={{
                    ...params, PageSize: RankList && RankList.TotalRecord, PageIndex: 1,
                  }} sheetName={i18n.t('memberRanking')} columns={ExcelColumns.ReportMemberRank} />}
                </Col>
                <Col className="color-03">
                  {i18n.t('lastUpdateTime')}：{dayjs(RankList?.UpdateDateTime).format('YYYY-MM-DD HH:mm:ss')}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  scroll={{ x: 1500 }}
                  dataSource={
                    RankList?.Data?.map((item: ReportMemberRank, index: number) => ({ ...item, key: index }))
                  }
                  columns={[
                    {
                      dataIndex: 'ladder',
                      title: i18n.t('ranking'),
                      align: 'center',
                      fixed: 'left',
                      width: 80,
                      render: (val, rocord, i) => ((page[0] - 1) * page[1]) + (i + 1)
                    },
                    {
                      dataIndex: 'MemberAccount',
                      title: i18n.t('memberAccount'),
                      fixed: 'left',
                      width: 200,
                    },
                    {
                      dataIndex: 'UpMemberAccount',
                      title: i18n.t(`${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`),
                      width: 300,
                      render: (val, { AGAccount, SAAccount }: any) => (
                        <>
                          <span>{val || '-'}／</span>
                          <span>{AGAccount || '-'}／</span>
                          <span>{SAAccount || '-'}</span>
                        </>
                      )
                    },
                    {
                      dataIndex: 'MemberName',
                      title: i18n.t('name'),
                      width: 150,
                    },
                    {
                      dataIndex: 'DepositAmount',
                      title: i18n.t('deposit'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'WithdrawAmount',
                      title: i18n.t('withdrawal'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'ValidBetAmount',
                      title: i18n.t('betAmountValid'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div>{common.toFormatNumber(val)}</div>
                    },
                    {
                      dataIndex: 'WinLossAmount',
                      title: i18n.t('memberWinLoss'),
                      align: 'right',
                      width: 200,
                      render: (val) => <div className={common.numColor03(val)}>{common.toFormatNumber(val)}</div>
                    },
                    {
                      title: i18n.t('operation'),
                      width: 200,
                      fixed: 'right',
                      render: (_, { MemberId, MemberAccount, AGId }: any) => (
                        <>
                          <Button className="size-12" type="link"
                            onClick={() => navigate(`/member/info/${MemberId}/${MemberAccount}/${AGId}`)}>{i18n.t('detail')}</Button>
                          <Button className="size-12" type="link"
                            onClick={() => navigate(`/member/account/${MemberAccount}`)}>{i18n.t('accountRecords')}</Button>
                        </>
                      )
                    }
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={RankList ? RankList.TotalRecord : 0} setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;
