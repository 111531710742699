import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import { UploadFile } from "antd/lib";
import { Content } from "antd/lib/layout/layout";
import { FormInputPosition } from "components/form.component";
import { LayoutNav, UploadImage } from "components/layout.component";
import { CKEditorComp } from "components/promotion.component";
import { RESPONSE_CODE_BONUS } from "constants/response";
import { AMOUNT_MAX } from "constants/system";
import dayjs from "dayjs";
import { WHETHER } from "enum/state";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { $get, $post } from "services";
import { enumToOptions, timeL2S, verify } from "utils/common";

// 優惠兌換碼: 編輯

const PageMain: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const [isPopUp, setIsPopUp] = useState();
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const [editorContent, setEditorContent] = useState<string>();

  const { data: couponInfo } = $get({
    url: 'api/bonus/info',
    params: { Id: id },
    allow: !!id
  });

  useEffect(() => {
    if (couponInfo) {
      const { Name, IsPopUp, IsRedirect, IsFnVisible, BonusRuleSetting, ValidBetTimes,
        Status, Position, StartDate, EndDate, Photo, Content } = couponInfo.Data;
      const { RewardAmount, RewardQuantity } = JSON.parse(BonusRuleSetting);
      form.setFieldsValue({
        Name,
        IsPopUp,
        IsRedirect,
        IsFnVisible,
        RewardQuantity,
        RewardAmount,
        ValidBetTimes,
        Status,
        Position,
        StartDate: dayjs(StartDate),
        Content
      })
      if (EndDate === '0000-00-00 00:00:00') {
        form.setFieldValue('EndDate', null);
        setEndDate(null);
      } else {
        form.setFieldValue('EndDate', dayjs(EndDate));
        setEndDate(dayjs(EndDate));
      }
      setIsPopUp(IsPopUp);
      setStartDate(dayjs(StartDate));
      setImageData(Photo ? 
        [{
          uid: Photo,
          name: Photo,
          url: Photo,
        }] : []
      );
      setEditorContent(Content);
    };
  }, [couponInfo, form]);

  const onFinish = ({ Name, IsPopUp, IsRedirect, IsFnVisible, Status, Position }: {
    Name: string;
    IsPopUp: number;
    IsRedirect: number;
    IsFnVisible:number;
    Status: number;
    Position: number;
  }) => {
    $post({
      url: 'api/bonus/redemption/update',
      send: {
        Id: id,
        Name,
        Status,
        IsPopUp,
        IsRedirect,
        IsFnVisible,
        Content: editorContent,
        Position,
        Photo: imageData[0] ? [imageData[0].url] : [],
        StartDate: timeL2S(dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')),
        EndDate: endDate ? timeL2S(dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')) : null,
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        navigate('/promotion/coupon');
      },
      resCode: RESPONSE_CODE_BONUS
    })
  };

  // 禁用今天之前的所有日期
  const disabledStartDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };

  // 禁用开始时间之前的日期
  const disabledEndDate = (current: any) => {
    return current && startDate && current < dayjs(startDate).endOf('day');
  };

  // 禁用开始时间之前的时间
  const disabledStartTime = () => {
    const now = dayjs();
    return {
      disabledHours: () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
          if (i < now.hour()) {
            hours.push(i);
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour: any) => {
        const minutes = [];
        if (selectedHour === now.hour()) {
          for (let i = 0; i < 60; i++) {
            if (i < now.minute()) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      },
      disabledSeconds: (selectedHour: any, selectedMinute: any) => {
        const seconds = [];
        if (selectedHour === now.hour() && selectedMinute === now.minute()) {
          for (let i = 0; i < 60; i++) {
            if (i < now.second()) {
              seconds.push(i);
            }
          }
        }
        return seconds;
      },
    };
  };

  // 禁用开始时间之前的结束时间
  const disabledEndTime = (endDate: any) => {
    const start = dayjs(startDate);
    return {
      disabledHours: () => {
        const hours = [];
        if (startDate && dayjs(endDate).isSame(start, 'day')) {
          for (let i = 0; i < 24; i++) {
            if (i < start.hour()) {
              hours.push(i);
            }
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour: any) => {
        const minutes = [];
        if (
          startDate &&
          dayjs(endDate).isSame(start, 'day') &&
          selectedHour === start.hour()
        ) {
          for (let i = 0; i < 60; i++) {
            if (i < start.minute()) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      },
      disabledSeconds: (selectedHour: any, selectedMinute: any) => {
        const seconds = [];
        if (
          startDate &&
          dayjs(endDate).isSame(start, 'day') &&
          selectedHour === start.hour() &&
          selectedMinute === start.minute()
        ) {
          for (let i = 0; i < 60; i++) {
            if (i < start.second()) {
              seconds.push(i);
            }
          }
        }
        return seconds;
      },
    };
  };

  return (
    <div id="container">
      <Content>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item style={{ width: 395 }} name="Name" label={
                    <Row gutter={6} align="middle">
                      <Col>{i18n.t('promotionName')}</Col>
                      <Col>
                        <Tooltip placement='top'
                          title={i18n.t('systemWillAutomaticallyGenerateRedemptionCodes')}>
                          <InfoCircleFilled />
                        </Tooltip>
                      </Col>
                    </Row>
                  } rules={[
                    { required: true, message: `${i18n.t('required')}` },
                    { type: 'string', max: 100, message: `${i18n.t('exceedsCharacterLimit', { length: 100 })}` }
                  ]}>
                    <Input placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col><Button onClick={() => navigate(-1)}>{i18n.t('cancel')}</Button></Col>
                    <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="IsPopUp" label={i18n.t('popupDisplay')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      onChange={e => {
                        setIsPopUp(e);
                        form.setFieldValue('IsRedirect', WHETHER.none);
                      }}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="IsRedirect" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: isPopUp, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      disabled={!isPopUp}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="IsFnVisible" label={i18n.t('frontDeskDisplay')}
                             rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="RewardQuantity"
                    label={i18n.t('numberOfRedemptionCodeSets')} required>
                    <Input placeholder={`${i18n.t('inputData')}`} disabled />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="RewardAmount"
                    label={`${i18n.t('redEnvelopeAmount')}／${i18n.t('sets')}`} 
                    rules={[...verify({ point: 4, min: 1, max: AMOUNT_MAX, isShowCompareNumber: true })]}>
                    <Input placeholder={`${i18n.t('inputData')}`} disabled />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="ValidBetTimes" label={i18n.t('rolloverRate')} required>
                    <Input placeholder={`${i18n.t('inputData')}`} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="Status" label={i18n.t('status')}
                    rules={[{ required: true, message: `${i18n.t('required')}` },]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 1, label: i18n.t('release') },
                        { value: 0, label: i18n.t('disable') }
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <FormInputPosition className="w-12" />
                </Col>
                <Col>
                  <Form.Item name="StartDate" label={i18n.t('promotionStartTime')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <DatePicker
                      showTime
                      disabledDate={disabledStartDate}
                      disabledTime={disabledStartTime}
                      onChange={(value) => setStartDate(value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="EndDate"
                    label={
                      <Row gutter={6} align="middle">
                        <Col>{i18n.t('promotionEndTime')}</Col>
                        <Col>
                          <Tooltip placement='top'
                            title={i18n.t('ifNotSetEndTimeItWillBeConsideredAsPermanentRedemptionCode')}>
                            <InfoCircleFilled />
                          </Tooltip>
                        </Col>
                      </Row>
                    }>
                    <DatePicker
                      showTime
                      allowClear
                      disabled={!startDate}
                      disabledDate={disabledEndDate}
                      disabledTime={disabledEndTime}
                      placeholder={`${!endDate && i18n.t('permanent')}`}
                      onChange={(value) => setEndDate(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="Photo" label={i18n.t('image')}
                rules={[{ required: !imageData[0], message: `${i18n.t('required')}` }]} required>
                <UploadImage
                  name="Photo"
                  url={'/upload/images/public/bonus'}
                  preUrl={couponInfo?.Data?.Photo || ''}
                  imageData={imageData}
                  setImageData={setImageData}
                  form={form}
                  remove
                  w={1920}
                  h={700}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="Content" label={i18n.t('internalContent')}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'Content'} image />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  )
}

export default PageMain