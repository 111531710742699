export const ROLLOVER_TYPE = {
  DEPOSIT_TICKET: "depositing",
  DEPOSIT_BONUS: "depositPromotions",
  RECOMMEND_BONUS: "referralReward",
  DAILY_CHECK_IN_BONUS: "dailyCheckPromotionAmount",
  CONTINUOUS_CHECK_IN_BONUS: "dailyCheckAccumulatedAmount",
  LUCKY_MONEY_BONUS: "redEnvelopePromotions",
  TRIAL_BONUS: "signupBonus",
  ADJUST_TICKET: "transferAdjust",
  REDEMPTION_BONUS: "couponOffer",
  PEOPLE_AGENT_PERSONAL_COMMISSION: "allAgentPersonalRebate",
  PEOPLE_AGENT_PERSONAL_COMMISSION_RECALCULATE: "allAgentPersonalRebateRebateRecalculation",
  PEOPLE_AGENT_DIRECT_MEMBER_COMMISSION: "allAgentRebateForDirectDownlineValidMembers",
  PEOPLE_AGENT_DIRECT_MEMBER_COMMISSION_RECALCULATE: "allAgentRebateForDirectDownlineValidMembersRebateRecalculation",
  MEMBER_LEVEL_UPGRADE_REWARD: "memberLevelPromotionUpgradeBonus",
  MEMBER_LEVEL_WEEKLY_REWARD: "memberLevelPromotionWeeklyBonus",
  LEVEL_MONTHLY_REWARD: "memberLevelPromotionAmountMonthly",
  OTHER_BONUS: "otherPromotions",
  COMMISSION: "rebate",
  DECREASE_VALID_BET: "deductExpectedValidBet",
}