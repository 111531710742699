const main = function() {

  const Data = [
    {
      "Id": 348,
      "Position": 1,
      "Title": "mock測試測試測試測試測試測試測試測試測試測試測試",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/y2zcB4GHHN.png?1729837117593",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 349,
      "Position": 2,
      "Title": "馬耳他牌照(MGA)認證",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/a2tjEj1dnU.png?1729837302957",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 353,
      "Position": 2,
      "Title": "牌照三",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/uSTzX11m3T.png?1730960477152",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 354,
      "Position": 2,
      "Title": "牌照四",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/mfKcXUTgdh.png?1730960493583",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 356,
      "Position": 2,
      "Title": "牌照五",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/94cdSBKESC.png?1730960526784",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 357,
      "Position": 2,
      "Title": "牌照六",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/heaaBzG657.png?1730960536951",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 358,
      "Position": 2,
      "Title": "牌照七",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/N4HxskA5ar.png?1730960552861",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 355,
      "Position": 3,
      "Title": "牌照八",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/SeJKe64n35.png?1730960512636",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    },
    {
      "Id": 360,
      "Position": 7,
      "Title": "cddd",
      "photo_1": "https://ants3.s3.ap-northeast-1.amazonaws.com/contents/eZzsYn8AT1.png?1732601870009",
      "LinkURL": "",
      "Status": 1,
      "Lang": "zh-TW"
    }
  ];
  
  return {
    State: "Success",
    TotalRecord: 22,
    Data
  }
}

export default main;
