import { useState, useEffect } from 'react';
import { $get } from 'services';
import useSite from 'hooks/site.hook';


export const usePermission = () => {
  const { data: $s,isCashVersion: $sc } = useSite();
  // 信用版忽略頁面
  const creditDisabled = [
    'ManagementId-18', // 會員銀行卡列表

    'ManagementId-21', // 實名驗證
    'ManagementId-22', // 銀行卡驗證

    'ManagementId-27', // 提款查詢
    'ManagementId-28', // 入款查詢
    'ManagementId-30', // 轉帳出入款管理
    'ManagementId-32', // 轉帳出入款管理
    'ManagementId-33', // 轉帳出入款管理
    'ManagementId-35', // 轉帳出入款管理
    'ManagementId-36', // 三方出入款管理
    'ManagementId-37', // 三方出入款管理
    'ManagementId-38', // 三方出入款管理
    'ManagementId-39', // 三方出入款管理

    'ManagementId-49', // 代理提款查詢
    'ManagementId-50', // 代理入款查詢
    'ManagementId-52', // 代理銀行卡查詢
  ]
  const [allList, setAllList] = useState<any>([]);
  const { data: operationList } = $get({ url: 'api/permission/operation/list' });


  useEffect(() => {
    if (operationList) {
      const data: any = [];

      operationList.Data.forEach((item: any) => {

        const management = data.find((x: any) => x.key === item.ManagementId);
        // 頁面已經推進
        if (management) {
          management.child.push({ key: item.OperationId, name: item.OperationName })

          // 建立頁面
        } else {
          // 信用版忽略頁面
          if ($sc || !creditDisabled.includes(`ManagementId-${item.ManagementId}`)) {
            data.push({
              MenuId: item.MenuId, key: item.ManagementId, name: item.ManagementName, rowSpan: 0, child: [
                { key: item.OperationId, name: item.OperationName }
              ]
            });
            const menu = data.find((x: any) => x.MenuId === item.MenuId);
            if (menu) menu.rowSpan++
          }
        }

      });

      setAllList(data);
    }
  }, [operationList]);

  return { allList };
};