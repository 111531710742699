import { Button, Col, Form, Image, Input, Row, Spin, Table, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination, LayoutTabChatLog } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'enum/date';
import { ROLE } from 'enum/socket';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { timeL2S, timeS2LUnix, tzShift } from "utils/common";

// 聊天室紀錄: 私訊

interface SearchParams {
  MemberAccount?: string;
  AgentAccount?: string;
  NoCode?: string;
  StartDate?: string;
  EndDate?: string;
}

const PageMain: React.FC = () => {
  const [form] = useForm();
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<SearchParams>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1])
  });
  const { data: PrivateList, isValidating, mutate } = $get({
    url: 'api/chathistory/private/session/message/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const onFinish = (value: {
    memberAccount: string;
    SAaccount: string;
    id: string;
  }) => {
    setPage([1, page[1]]);
    if (date) {
      setParams({
        MemberAccount: value.memberAccount,
        AgentAccount: value.SAaccount,
        NoCode: value.id,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
    }
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    setPage([1, 10]);
    mutate();
  };

  return (
    <div id="container">
      <Content>
        <LayoutTabChatLog activeKey='2' />
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="memberAccount">
                    <Input className="w-12" placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="SAaccount">
                    <Input className="w-12" placeholder={`${i18n.t('masterAgentAndAgentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="id">
                    <Input className="w-12" placeholder={`${i18n.t('identifier')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <DatePickerCol width={190} date={date} setDate={setDate} initDate={reset}
              ignoreDateType={[DATE_TYPE.thisMonth, DATE_TYPE.lastMonth]} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  scroll={{ x: 1500 }}
                  dataSource={PrivateList?.Data?.map((item: ChatPrivateHistory) => ({ key: item.Id, ...item })) || []}
                  columns={[
                    {
                      title: i18n.t('sendMessageRole'),
                      dataIndex: 'SenderRole',
                      fixed: 'left',
                      width: 150,
                      render: (_, { SenderRole }: any) => i18n.t(ROLE[SenderRole])
                    },
                    {
                      title: i18n.t('account'),
                      dataIndex: 'SenderAccount',
                      fixed: 'left',
                      width: 200,
                    },
                    {
                      title: <>{i18n.t('identifier')}</>,
                      width: 200,
                      render: (_, { SendNoCode }) => <>{SendNoCode || '-'}</>
                    },
                    {
                      title: i18n.t('receiveMessageRole'),
                      dataIndex: 'ReceiverRole',
                      width: 150,
                      render: (_, { ReceiverRole }) => i18n.t(ROLE[ReceiverRole])
                    },
                    {
                      title: i18n.t('account'),
                      dataIndex: 'ReceiverAccount',
                      width: 200,
                    },
                    {
                      title: <>{i18n.t('identifier')}</>,
                      width: 200,
                      render: (_, { ReceiveNoCode }) => <>{ReceiveNoCode || '-'}</>
                    },
                    {
                      title: i18n.t('content'),
                      width: 300,
                      render: (_, { Type, Content, }) => (
                        Type === 0
                          ? <Tooltip title={Content}>
                            <div style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden', textOverflow: 'ellipsis',
                            }}>
                              {Content}
                            </div>
                          </Tooltip>
                          : <Image src={Content} height={80} />
                      )
                    },
                    {
                      title: i18n.t('sendTime'),
                      dataIndex: 'SendTime',
                      width: 150,
                      render: (val) => timeS2LUnix(val, 'YYYY-MM-DD HH:mm:ss')
                    },
                    {
                      title: 'IP',
                      dataIndex: 'IP',
                      width: 150,
                    },
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={PrivateList ? PrivateList.TotalRecord : 0}
                  setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;