
export const UPGRADE_RESTRICTIONS: any = {
  Daily: 'upgradeOneLevelPerDay',
  Unlimit: 'continuouslyUpgradeable',
}

export const BONUS_CONDITIONS: any = {
  OR: 'totalDepositOrValidBetting',
  AND: 'totalDepositAndValidBetting',
}
