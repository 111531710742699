import { Button, Col, Form, message, Row, Select, Spin, Table } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupAddFront, PopupEditFront } from 'components/front.component';
import { InquiryWindow, LayoutNav, LayoutPagination, LayoutTabFront } from "components/layout.component";
import { FRONT_COPY_TYPE } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from "services";
import { enumToOptions, timeS2L } from 'utils/common';
import { RESPONSE_CODE_FRONT } from "../../../constants/response";
import useAccount from "hooks/account.hook";

// 前台文案

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [key, setKey] = useState<number | null>(null);
  const [activeData, setActiveData] = useState();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [params, setParams] = useState<any>({
    Type: null,
  });
  const { data: FrontList, isValidating, mutate } = $get({
    url: 'api/contents/frontpage/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    },
    showMessage: false,
    allow: !!params.Type
  })

  const onEdit = (record: any) => {
    setActiveData(record);
    setIsOpenEditModal(true);
  }

  const onDelete = (key: number) => {
    setKey(key);
    setIsOpenDeleteModal(true);
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      Type: formData.type,
    })
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    setParams({ ...params, Type: FRONT_COPY_TYPE.qa });
  };

  const handleDelete = () => {
    $post({
      url: 'api/contents/frontpage/delete',
      send: { Id: key },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_FRONT
    })
  }

  return (
    <div id="container">
      <Content>
        <LayoutTabFront activeKey="1" />
        <Form form={form} onFinish={onFinish} initialValues={{
          type: null,
        }}>
          <Row gutter={[12, 12]}>
            {/* 搜尋欄 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="type">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('selectCategory')}`}
                      options={enumToOptions(FRONT_COPY_TYPE)}
                    />
                  </Form.Item>
                </Col>
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
            <Col span={24}>
              {$p('50102') && <Button type="primary" onClick={() => setIsOpenAddModal(true)}>{i18n.t('add')}</Button>}
            </Col>
            {/* 數據 */}
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  dataSource={
                    FrontList?.Data.map((item: FrontList) => (
                      {
                        key: item.Id,
                        Type: item.Type,
                        sort: item.Position,
                        title: item.Title,
                        updater: item.OperatorAccount,
                        updateDate: item.UpdateDate,
                        created: item.CreateDate,
                      }
                    )) || []
                  }
                  columns={[
                    {
                      title: i18n.t('sort'),
                      dataIndex: 'sort',
                      align: 'center',
                      width: 80,
                    },
                    {
                      title: i18n.t('mainTitle'),
                      dataIndex: 'title',
                      width: 800
                    },
                    {
                      title: i18n.t('updater'),
                      dataIndex: 'updater',
                      width: 120,
                      render: (val) => val || 'System'
                    },
                    {
                      title: i18n.t('updateTime'),
                      dataIndex: 'updateDate',
                      width: 120,
                      render: (val: string) => timeS2L(val)
                    },
                    {
                      title: i18n.t('createTime'),
                      dataIndex: 'created',
                      width: 120,
                      render: (val: string) => timeS2L(val)
                    },
                    {
                      title: i18n.t('operation'),
                      width: 100,
                      render: (_, record: any) => (
                        <>
                          {$p('50103') && <Button type="link" onClick={() => onEdit(record)}>{i18n.t('edit')}</Button>}
                          {$p('50104') && <Button type="link" onClick={() => onDelete(record.key)}>{i18n.t('delete')}</Button>}
                        </>
                      )
                    }
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={FrontList ? FrontList.TotalRecord : 0} setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
      <PopupAddFront isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} mutate={mutate} />
      <PopupEditFront isOpen={isOpenEditModal} close={() => setIsOpenEditModal(false)} data={activeData} mutate={mutate} />
      <InquiryWindow isOpen={isOpenDeleteModal} close={() => setIsOpenDeleteModal(false)} 
        msg={i18n.t('confirmDeleteData')} action={handleDelete} />
    </div>
  );
};

export default PageMain;