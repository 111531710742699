import PageChatLog from "pages/admin/chat";
import PageChatAccount from "pages/admin/chat/account";
import PageChatOnlineService from 'pages/admin/chat/online-service';
import PageChatPrivate from "pages/admin/chat/private";
import PageChatReply from "pages/admin/chat/reply";
import PageChatSetting from "pages/admin/chat/setting";

export const chatModule = [
  { path: '/chat', element: <PageChatLog/> },
  { path: '/chat/private', element: <PageChatPrivate/> },
  { path: '/chat/setting', element: <PageChatSetting/> },
  { path: '/chat/account', element: <PageChatAccount/> },
  { path: '/chat/reply', element: <PageChatReply/> },
  { path: '/chat/online-service', element: <PageChatOnlineService/> },
]