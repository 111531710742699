import { Col, Row, Spin, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutTabPlatform } from 'components/layout.component';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React from 'react';
import { $get, $post } from 'services';
import { specialProviderName, timeS2L } from "utils/common";

// 站台設定 遊戲商返水設定

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const { permissions: $p } = useAccount();
  const { data: ProviderList, isValidating, mutate } = $get({ url: 'api/providercommission/list' });

  const handleProviderRebateStatus = (Code: string, status: boolean) => {
    $post({
      url: 'api/providercommission/update',
      send: {
        Code,
        Commission_Status: status ? 1 : 0
      },
      success: () => {
        mutate();
        message.success(i18n.t('updateSuccess'));
      },
    })
  }

  return (
    <div id="container">
      <Content>
        <LayoutTabPlatform activeKey="6" />
        <Row align="middle" gutter={[10, 16]}>
          <Col span={24}>
            <Spin spinning={isValidating}>
              <Table
                size="middle"
                dataSource={ProviderList?.Data.map((item: ProviderRebateList) => ({ key: item.Name, ...item }))}
                columns={[
                  {
                    title: i18n.t('gameProvider'),
                    dataIndex: 'Name',
                    width: '25%',
                    render: (val, { Code }: any) => specialProviderName(val, site.SiteName, Code)
                  },
                  {
                    title: i18n.t('cashbackState'),
                    dataIndex: 'Commission_Status',
                    width: '25%',
                    render: (_, { Code, Commission_Status }) =>
                      <Switch checked={Commission_Status}
                        onChange={status => handleProviderRebateStatus(Code, status)}
                        disabled={!$p('00503')} />
                  },
                  {
                    title: i18n.t('updater'),
                    dataIndex: 'Update_Account',
                    width: '25%'
                  },
                  {
                    title: i18n.t('updateTime'),
                    dataIndex: 'UpdateDate',
                    width: '25%',
                    render: (val: string) => timeS2L(val)
                  },
                ]}
                pagination={false}
              />
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;