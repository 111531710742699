import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { SelectCycleDate } from 'components/agent.component';
import { LayoutNav } from 'components/layout.component';
import { DailyList } from 'components/report.component';
import { SETTLEMENT } from 'enum/state';
import i18n from 'i18n';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 代理 > 結算報表

const PageMain: React.FC = () => {
  const { id = '' } = useParams();
  const { data: agent } = $get({
    url: 'api/agent/one',
    params: { Id: id },
    allow: !!id
  })
  const [periodCommission, setPeriodCommission] = useState<string>("");
  const [periodRevenueshare, setPeriodRevenueshare] = useState<string>("");
  const { data: agentCommissionReport, isValidating: commissionValidating } = $get({
    url: 'api/agent/report/commission/settlement/list',
    params: {
      Period: periodCommission,
      AgentId: id
    },
    allow: !!periodCommission
  })
  const { data: agentRevenueshareReport, isValidating: revenueshareValidating } = $get({
    url: 'api/agent/report/revenueshare/settlement/list',
    params: {
      Period: periodRevenueshare,
      AgentId: id
    },
    allow: !!periodRevenueshare
  })

  return (
    <div id="container">
      <Content>

        <Row gutter={[12, 12]}>
          <Col span={24} className="size-14 font-w-md mt-1">
            {i18n.t('rebateReport')}
          </Col>
          <Col span={4}>
            <SelectCycleDate setPeriod={setPeriodCommission} id={id} mode={SETTLEMENT.revenue} />
          </Col>
          <Col>
            <DailyList loading={commissionValidating} data={agentCommissionReport?.Data} reset={periodCommission} columns={[
              {
                dataIndex: 'AgentAccount',
                title: i18n.t('account'),
                width: 200,
                render: (val: any, { AgentLevel, CategoryName }: any) => {
                  const word = common.agentLevelWord(AgentLevel);
                  return CategoryName === 'ALL'
                    ?
                    <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                      {word}／{val}
                    </span>
                    : ''
                }
              },
              {
                dataIndex: 'CategoryName',
                title: i18n.t('gameCategory'),
                width: 150,
                render: (val: any) => i18n.t(`${val}`)
              },
              {
                dataIndex: 'ValidBetAmount',
                title: i18n.t('accumulationEffectiveBets'),
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DirectMemberValidBetAmount',
                title: i18n.t('directMemberEffectiveBets'),
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'WinLoseAmount',
                title: i18n.t('totalMemberWinLoss'),
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              // 總返水
              {
                dataIndex: 'TotalCommission',
                title: i18n.t('totalRebate'),
                align: 'right',
                width: 300,
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DownlineAgentCommission',
                title: i18n.t('downlineAgentRebate'),
                width: 300,
                align: 'right',
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DownlineMemberCommission',
                title: i18n.t('downlineMemberRebate'),
                width: 300,
                align: 'right',
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'DirectMemberCommission',
                title: i18n.t('directMemberCashback'),
                width: 300,
                align: 'right',
                render: (val: any) => $f(val)
              },
              {
                dataIndex: 'Commission',
                title: i18n.t('myRebate'),
                width: 150,
                align: 'right',
                render: (val: any) => <div className={common.numColor05(val)}>{$f(val)}</div>
              },
            ]} />
          </Col>

          <Col span={24} className="size-14 font-w-md mt-1">
            {i18n.t('revenueReport')}
          </Col>
          <Col span={4}>
            <SelectCycleDate setPeriod={setPeriodRevenueshare} id={id} mode={SETTLEMENT.revenueshare} />
          </Col>

          <Col>
            <DailyList loading={revenueshareValidating} data={agentRevenueshareReport?.Data} reset={periodRevenueshare} columns={[
              {
                key: 1,
                dataIndex: 'AgentAccount',
                title: i18n.t('account'),
                className: 'size-12',
                width: 200,
                render: (val: any, { AgentLevel, CategoryName }: any) => {
                  const word = common.agentLevelWord(AgentLevel);
                  return CategoryName === 'ALL'
                    ?
                    <span style={{ margin: '2px 8px', display: 'inline-block' }}>
                      {word}／{val}
                    </span>
                    : ''
                }
              },
              {
                key: 2,
                dataIndex: 'CategoryName',
                title: i18n.t('gameCategory'),
                className: 'size-12',
                width: 150,
                render: (val: any) => i18n.t(`${val}`)
              },
              {
                key: 3,
                dataIndex: 'ValidBetAmount',
                title: i18n.t('accumulationEffectiveBets'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any) => $f(val)
              },
              {
                key: 4,
                dataIndex: 'WinLoseAmount',
                title: i18n.t('totalMemberWinLoss'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                key: 41,
                dataIndex: 'DirectMemberWinLoseAmount',
                title: i18n.t('directMemberWinLoss'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                key: 5,
                dataIndex: 'TotalCost',
                title: i18n.t('totalCost'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
              },
              {
                key: 6,
                dataIndex: 'DownlineRevenueShare',
                title: i18n.t('subordinateCommissionRate'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                key: 7,
                dataIndex: 'DownlineAgentCost',
                title: i18n.t('subordinateCost'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
              },
              {
                key: 8,
                dataIndex: 'DownlineAgentProfit',
                title: i18n.t('subordinateProfit'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
              },
              {
                key: 9,
                dataIndex: 'RevenueShare',
                title: i18n.t('myCommissionRate'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any) => <div className={common.numColor01(val)}>{$f(val)}</div>
              },
              {
                key: 10,
                dataIndex: 'AgentCost',
                title: i18n.t('myCost'),
                className: 'size-12',
                align: 'right',
                width: 150,
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ? $f(val) : '-'
              },
              {
                key: 11,
                dataIndex: 'AgentProfit',
                width: 150,
                title:
                  <Row align="middle" justify="end" gutter={[5, 0]}>
                    <Col>
                      <Tooltip placement="top" title={
                        i18n.t('sitemasterMyProfitExplains')
                      }>
                        <InfoCircleFilled />
                      </Tooltip>
                    </Col>
                    <Col className="size-12">{i18n.t('myProfit')}</Col>
                  </Row>,
                className: 'size-12',
                align: 'right',
                render: (val: any, { CategoryName }: any) => CategoryName === 'ALL' ?
                  <div className={common.numColor05(val)}>{$f(val)}</div> : '-'
              },
            ]} />
          </Col>
        </Row>

      </Content>
    </div>
  );
};

export default PageMain;