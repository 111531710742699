import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { ViewTransferBank, ViewTransferCrypto } from 'components/finance.component';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import { GATEWAY, GATEWAY_CODE_DEPOSIT, GATEWAY_CODE_WITHDRAW } from 'constants/gateway';
import { TRANSFER_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get } from 'services';

// 轉帳出入款管理

interface Search {
  GatewayCode?: string;
  CustomName?: string;
  MemberGroupId?: number;
}

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();
  const [form] = useForm();
  const [page, setPage] = useState<number[]>([1, 10]);
  const [type, setType] = useState(TRANSFER_TYPE.deposit);
  const [gateway, setGateway] = useState(GATEWAY.WITHDRAW_GATE_BANK);
  const [params, setParams] = useState<Search>({
    GatewayCode: GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK_OFFLINE
  })
  useEffect(() => {
    if ($p('20701')) {
      setType(TRANSFER_TYPE.deposit);
      setParams({
        GatewayCode: GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK_OFFLINE
      })
    } else if ($p('20401')) {
      setType(TRANSFER_TYPE.withdrawal);
      setParams({
        GatewayCode: GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK_OFFLINE
      })
    }
  }, [init]);
  const { data: DepositList, isValidating: depositLoading, mutate: refreshDeposit } = $get({
    url: 'api/depositmerchant/offline/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1],
    },
    allow: !!$p('20701') && type === TRANSFER_TYPE.deposit,
    showMessage: false
  })
  const { data: withdrawal, isValidating: withdrawalLoading, mutate: refreshWithdrawal } = $get({
    url: 'api/withdrawmerchant/offline/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1],
    },
    allow: !!$p('20401') && type === TRANSFER_TYPE.withdrawal,
    showMessage: false
  })
  const [dataList, setDataList] = useState<any>([])
  useEffect(() => {
    if (DepositList?.Data) setDataList(DepositList.Data)
    else if (withdrawal?.Data) setDataList(withdrawal.Data)
  }, [DepositList, withdrawal]);

  useEffect(() => {
    onClear();
    switch (type) {
      case TRANSFER_TYPE.deposit:
        setParams({
          GatewayCode: gateway === GATEWAY.WITHDRAW_GATE_BANK ? GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_BANK_OFFLINE : GATEWAY_CODE_DEPOSIT.DEPOSIT_GATE_CRYPTO_OFFLINE
        })
        break;
      case TRANSFER_TYPE.withdrawal:
        setParams({
          GatewayCode: gateway === GATEWAY.WITHDRAW_GATE_BANK ? GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_BANK_OFFLINE : GATEWAY_CODE_WITHDRAW.WITHDRAW_GATE_CRYPTO_OFFLINE
        })
        break;
    }
  }, [type, gateway]);

  const search = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      CustomName: formData.CustomName,
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const mutate = () => {
    refreshDeposit();
    refreshWithdrawal();
  }

  const modeList: any[] = [
    $p('20401') && { value: TRANSFER_TYPE.deposit, label: i18n.t('deposit') },
    $p('20701') && { value: TRANSFER_TYPE.withdrawal, label: i18n.t('withdraw') }
  ].filter(item => item)
  
  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={search}>
          <Row gutter={[12, 12]}>
            {/* 搜尋欄位 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  {/* 切換出入款 */}
                  {modeList.length > 0 &&
                  <Select
                    className="w-12"
                    defaultValue={modeList[0].value}
                    options={modeList}
                    onChange={setType}
                  />}
                </Col>
                <Col>
                  <Select
                    className="w-12"
                    defaultValue={GATEWAY.WITHDRAW_GATE_BANK}
                    options={[
                      { value: GATEWAY.WITHDRAW_GATE_BANK, label: i18n.t('bankCard') },
                      { value: GATEWAY.WITHDRAW_GATE_CRYPTO, label: i18n.t('virtualCurrency') },
                    ]}
                    onChange={setGateway}
                  />
                </Col>
                <Col>
                  <Form.Item name="CustomName">
                    <Input placeholder={`${i18n.t('displayName')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            {/* 列表 */}
            <Col span={24}>
              {
                gateway === GATEWAY.WITHDRAW_GATE_BANK &&
                <Spin spinning={depositLoading || withdrawalLoading}>
                  <ViewTransferBank data={dataList} mutate={mutate} type={type} />
                  <LayoutPagination total={withdrawal?.TotalRecord || DepositList?.TotalRecord } setPage={setPage} page={page} />
                </Spin>
              }
              {
                gateway === GATEWAY.WITHDRAW_GATE_CRYPTO &&
                <Spin spinning={depositLoading || withdrawalLoading}>
                  <ViewTransferCrypto data={dataList} mutate={mutate} type={type} />
                  <LayoutPagination total={withdrawal?.TotalRecord || DepositList?.TotalRecord } setPage={setPage} page={page} />
                </Spin>
              }
            </Col>
          </Row>
        </Form>
      </Content >

    </div >
  );
};

export default PageMain;