const main = function() {

  return {
    State: "Fail",
    Message: "WALLET_BALANCE_MAXIMUM_LIMIT"
  }

}

export default main;
