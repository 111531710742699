import { Button, Col, Form, Input, Modal, Row, Select, Spin, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { RESPONSE_CODE_ROLLOVER_REVOKE, } from "constants/response";
import { ROLLOVER_STATE } from "enum/state";
import i18n from "i18n";
import React, { useEffect, useState } from "react";
import { $post } from "services";
import * as common from "utils/common";
import { timeS2L, verify } from "utils/common";
import { InquiryWindow, ProviderTableDisplay } from "./layout.component";
import useAccount from "hooks/account.hook";
import { ROLLOVER_TYPE } from "../constants/rollover";


// 流水查詢總計列表
export const ViewRolloverOverview = ({ data }: {
  data: {
    TotalOriginalValidBetAmount: number;
    TotalAccumulatedValidBetAmount: number;
    TotalGameProviderOriginalValidBetAmount: number;
    TotalGameProviderAccumulatedValidBetAmount: number;
  };
}) => {
  return (
    <Table
      className="w-full"
      size="middle"
      dataSource={
        data &&
        [{
          key: 1,
          totalOriginalValidBetAmount: data.TotalOriginalValidBetAmount,
          totalAccumulatedValidBetAmount: data.TotalAccumulatedValidBetAmount,
          totalGameProviderOriginalValidBetAmount: data.TotalGameProviderOriginalValidBetAmount,
          totalGameProviderAccumulatedValidBetAmount: data.TotalGameProviderAccumulatedValidBetAmount,
        }]
      }
      columns={[
        {
          title: i18n.t('totalOriginalValidBetAmount'),
          align: 'right',
          dataIndex: 'totalOriginalValidBetAmount',
          render: (_, { totalOriginalValidBetAmount }) => common.toFormatNumber(totalOriginalValidBetAmount)
        },
        {
          title: i18n.t('totalAccumulatedValidBetAmount'),
          align: 'right',
          dataIndex: 'totalAccumulatedValidBetAmount',
          render: (_, { totalAccumulatedValidBetAmount }) => common.toFormatNumber(totalAccumulatedValidBetAmount)
        },
        {
          title: i18n.t('totalGameProviderOriginalValidBetAmount'),
          align: 'right',
          dataIndex: 'totalGameProviderOriginalValidBetAmount',
          render: (_, { totalGameProviderOriginalValidBetAmount }) => common.toFormatNumber(totalGameProviderOriginalValidBetAmount)
        },
        {
          title: i18n.t('totalGameProviderAccumulatedValidBetAmount'),
          align: 'right',
          dataIndex: 'addBalanceTotal',
          render: (_, { totalGameProviderAccumulatedValidBetAmount }) => common.toFormatNumber(totalGameProviderAccumulatedValidBetAmount)
        },
      ]}
      pagination={false}
    />
  )
}

// 流水查詢列表
export const ViewRolloverList = ({ data, mutate }: {
  data: any
  mutate: () => void;
}) => {
  const { permissions: $p } = useAccount();
  const [isModalOpenRevoke, setIsModalOpenRevoke] = useState(false);
  const [rolloverOneInfo, setRolloverOneInfo] = useState<any>({});
  const [isOpenDetail, setOpenDetail] = useState(false);


  // 解除
  const submitRevoke = () => {
    $post({
      url: 'api/turnoverticket/unlock/update',
      send: { Ids: [rolloverOneInfo?.Id] },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_ROLLOVER_REVOKE
    })
  }

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case ROLLOVER_STATE.pass:
      case ROLLOVER_STATE.passManualRelease:
        return 'color-pass'
      case ROLLOVER_STATE.notPass:
        return 'color-reject'
      default: return ''
    };
  };

  return (
    <>
      <Table
        className="mt-1"
        size="small"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'Category',
            title: i18n.t('category'),
            width: 120,
            render: (val: keyof typeof ROLLOVER_TYPE) => {
              return i18n.t(ROLLOVER_TYPE[val])}
          },
          {
            dataIndex: 'Name',
            title: i18n.t('nameTitle'),
            fixed: 'left',
            width: 300,
            render: (val: any, record: any) => {
              switch (record.Category) {
                case 'DEPOSIT_TICKET':
                case 'COMMISSION':
                  return '-';
                case 'ADJUST_TICKET':
                  return record.Amount < 0 ? `${i18n.t('deductBalance')}` : `${i18n.t('addBalance')}`;
                case 'DECREASE_VALID_BET':
                  const detail = JSON.parse(record.Detail || '{}');
                  if ((detail.Category === 'DEPOSIT_TICKET' || detail.Category === 'COMMISSION' || detail.Category === 'ADJUST_TICKET') && record.Amount > 0) {
                    return '-';
                  } else {
                    return `${i18n.t('deductPromotion')} - ${detail.Name}`;
                  }
                default:
                  return record.Amount < 0 ? `${i18n.t('deductPromotion')} - ${val}` : val;
              }
            }
          },
          {
            dataIndex: 'Providers',
            title: `${i18n.t('applicableGameProvider')}/${i18n.t('mainCategory')}`,
            width: 260,
            render: (val, { CategoryCodes }: any) => <ProviderTableDisplay Providers={[]} gameCategorys={CategoryCodes}  />
          },
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left'
          },
          {
            dataIndex: 'Amount',
            title: i18n.t('amount'),
            align: 'right',
            width: 200,
            render: (val) => val === null ? '-' : common.toFormatNumber(val)
          },
          {
            dataIndex: 'OriginalValidBetAmount',
            title: i18n.t('expectedValidBet'),
            align: 'right',
            width: 200,
            render: (val) => val === null ? '-' : common.toFormatNumber(val)
          },
          {
            dataIndex: 'AccumulatedValidBetAmount',
            title: i18n.t('accumulatedValidBetAmount'),
            align: 'right',
            width: 200,
            render: (val, {Amount}) => val === null || Amount < 0 ? '-' : common.toFormatNumber(val)
          },
          {
            dataIndex: 'Status',
            width: 150,
            title: i18n.t('status'),
            fixed: 'right',
            render: (val, {Amount}) => (
              <>
                <div className={stateColor(val)}>
                  {Amount < 0 ? '-' : i18n.t(ROLLOVER_STATE[val])}
                </div>
              </>
            )
          },

          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record) => (
              <>
                {
                  ($p('21602') && record.Status === ROLLOVER_STATE.notPass) &&
                  <Button className="size-12" type="link"
                          onClick={() => {
                            setRolloverOneInfo(record);
                    setIsModalOpenRevoke(true)
                  }}>{i18n.t('revoke')}</Button>
                }
                {
                  ( record.Status === ROLLOVER_STATE.passManualRelease || record.Category === 'DECREASE_VALID_BET') &&
                  <Button className="size-12" type="link"
                          onClick={() => {
                            setRolloverOneInfo(record);
                            setOpenDetail(true);
                          }}>{i18n.t('detail')}</Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />
      {/*流水查詢詳情解除彈窗*/}
      <InquiryWindow isOpen={isModalOpenRevoke} close={() => setIsModalOpenRevoke(false)}
                     customerMsg={
                       <>
                         <div>{`${i18n.t("expectedValidBet")} : ${rolloverOneInfo.OriginalValidBetAmount}。`} </div>
                         <div>{`${i18n.t("validBetsStillRequired")} : ${rolloverOneInfo.OriginalValidBetAmount - rolloverOneInfo.AccumulatedValidBetAmount}。`} </div>
                         <div>{`${i18n.t("onceCancelThisDataCannotBeRestored")}`}</div>
                       </>
                     } action={submitRevoke} />
      {/*流水查詢詳情詳情彈窗*/}
      <PopupDetail isOpen={isOpenDetail} close={() => setOpenDetail(false)} rolloverOneInfo={rolloverOneInfo} />
    </>
  )
}

// 流水查詢詳情彈窗
export const PopupDetail = ({ isOpen, close, rolloverOneInfo }: any) => {
  const Detail: { Category: keyof typeof ROLLOVER_TYPE } = JSON.parse(rolloverOneInfo?.Detail || '{}');
  return (
    <Modal
      title={`${i18n.t('detail')}`}
      open={isOpen}
      centered
      onCancel={close}
      footer={
        <Row justify="center">
          <Button key="cancel" type="primary" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
      width={1000}
    >
      <Table
        size="small"
        dataSource={[rolloverOneInfo]}
        columns={[
          {
            dataIndex: 'Category',
            title: i18n.t('category'),
            width: 150,
            render: (val: keyof typeof ROLLOVER_TYPE, record) => {
              if (record.Status === ROLLOVER_STATE.passManualRelease) {
                return i18n.t(ROLLOVER_TYPE[val]);
              } else if (val === 'DECREASE_VALID_BET') {
                return i18n.t(ROLLOVER_TYPE[Detail.Category])
              } else {
                return '-';
              }
            }
          },
          {
            dataIndex: '',
            title: i18n.t('operation'),
            width: 150,
            render: (val, record) => {
              if (record.Status === ROLLOVER_STATE.passManualRelease) {
                return i18n.t('manualRelease');
              } else if (record.Category === 'DECREASE_VALID_BET') {
                return i18n.t('deductExpectedValidBet')
              } else {
                return '-';
              }
            }
          },
          {
            dataIndex: 'Detail',
            title: i18n.t('operator'),
            width: 150,
            render: (val) => (JSON.parse(val || '{}')).Operator
          },
          {
            dataIndex: 'CompleteTime',
            title: i18n.t('revokeTime'),
            width: 200,
            render: (val) => common.timeS2L(val)
          }
        ]}
        pagination={false}
      />
    </Modal>
  )
}

// 扣除應達有效投注彈窗
export const PopupDeductExpectedValidBet = ({ isOpen, close, mutate, notPassListData }: any) => {
  const [form] = useForm();
  // 扣除應達有效投注
  const onFinish = (formData: any) => {
    $post({
      url: 'api/turnoverticket/decrease/create',
      send: {
        Id: notPassSelectedData.Id,
        ValidBetAmount: formData.DeductExpectedValidBet,
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_ROLLOVER_REVOKE
    })
  };

  const onClose = () => {
    close();
    setNotPassSelectedData({})
    setTimeout(() => {
      form.resetFields();
    }, 300);
  }

  const [notPassSelectedData, setNotPassSelectedData] = useState<any>({});

  const handleChange = (value: number) => {
    const selectedItem = notPassListData.find((item: any) => item.Id === value);
    setNotPassSelectedData(selectedItem);
  };

  const [isInitialized, setIsInitialized] =useState(false)

  useEffect(() => {
    if(!isInitialized){
      setIsInitialized(true)
      return
    }
    form.validateFields(['DeductExpectedValidBet']);
  }, [notPassSelectedData, form]);

  return (
    <Modal 
      title={i18n.t('deductExpectedValidBet')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={1000}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]} className="pb-1">
          <Col span={24}>
            <Form.Item name="MemberGroupId" className="w-12" label={i18n.t('order')}
                       rules={[{ required: true, message: `${i18n.t('required')}` }]}>
              <Select
                placeholder={i18n.t('pleaseSelect')}
                options={notPassListData?.map((item: any) => ({
                  value: item.Id, label: item.Code
                }))}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="w-full">
          <Table
            size="small"
            dataSource={[notPassSelectedData && notPassSelectedData]}
            columns={[
              {
                dataIndex: 'Category',
                title: i18n.t('category'),
                width: 120,
                render: (val: keyof typeof ROLLOVER_TYPE) => {
                  return i18n.t(ROLLOVER_TYPE[val])}
              },
              {
                dataIndex: 'Name',
                title: i18n.t('nameTitle'),
                fixed: 'left',
                width: 300,
                render: (val: any, record: any) => {
                  switch (record.Category) {
                    case 'DEPOSIT_TICKET':
                    case 'COMMISSION':
                      return '-';
                    case 'ADJUST_TICKET':
                      return record.Amount < 0 ? `${i18n.t('deductBalance')}` : `${i18n.t('addBalance')}`;
                    case 'DECREASE_VALID_BET':
                      const detail = JSON.parse(record.Detail || '{}');
                      if ((detail.Category === 'DEPOSIT_TICKET' || detail.Category === 'ADJUST_TICKET') && record.Amount > 0) {
                        return '-';
                      } else {
                        return `${i18n.t('deductPromotion')} - ${detail.Name}`;
                      }
                    default:
                      return record.Amount < 0 ? `${i18n.t('deductPromotion')} - ${val}` : val;
                  }
                }
              },
              {
                dataIndex: 'Providers',
                title: `${i18n.t('applicableGameProvider')}/${i18n.t('mainCategory')}`,
                width: 260,
                render: (val, { CategoryCodes }: any) => <ProviderTableDisplay Providers={[]} gameCategorys={CategoryCodes}  />
              },
              {
                dataIndex: 'Code',
                title: i18n.t('order'),
                width: 250,
                fixed: 'left'
              },
              {
                dataIndex: 'Amount',
                title: i18n.t('amount'),
                align: 'right',
                width: 200,
                render: (val) => val === null ? '-' : common.toFormatNumber(val)
              },
              {
                dataIndex: 'ActualValidBetAmount',
                title: i18n.t('expectedValidBet'),
                align: 'right',
                width: 200,
                render: (val) => val === null ? '-' : common.toFormatNumber(val)
              },
            ]}
            pagination={false}
          />
          </Col>
          <Col span={12}>
          <Form.Item name="DeductExpectedValidBet" label={i18n.t('deductExpectedValidBet')} className="w-12" rules={[
            ...verify({ zero: false, point: 4}),
            {
              validator: (_, value) => {
                if (value && notPassSelectedData.ActualValidBetAmount && parseFloat(value) > notPassSelectedData.ActualValidBetAmount) {
                  return Promise.reject(new Error(`${i18n.t("mustBeLessThanOrEqualTo")} ${notPassSelectedData.ActualValidBetAmount}`));
                }
                return Promise.resolve();
              },
            }
          ]}>
            <Input type="number" placeholder={`${i18n.t('inputData')}`} />
          </Form.Item>
        </Col>
        </Row>
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button onClick={onClose}>{i18n.t('cancel')}</Button></Col>
          <Col><Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button></Col>
        </Row>
      </Form>
    </Modal>
  )
}

