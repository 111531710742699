import PageFront from "pages/admin/front";
import PageFrontCarousel from "pages/admin/front/carousel";
import PageFrontPopup from "pages/admin/front/popup";
import PageFrontSub from "pages/admin/front/sub";
import PageFrontLayoutSetting from "pages/admin/front/layout-setting"

export const frontModule = [
  { path: '/front', element: <PageFront/> },
  { path: '/front/sub', element: <PageFrontSub/> },
  { path: '/front/carousel', element: <PageFrontCarousel/> },
  { path: '/front/popup', element: <PageFrontPopup/> },
  { path: '/front/layout-setting', element: <PageFrontLayoutSetting/> },
]