import { Col, Row, Select, Spin, Switch, message, Menu } from "antd";
import { Content } from 'antd/es/layout/layout';
import { CopyButton, LayoutNav, LayoutTabMember } from "components/layout.component";
import { PopupStatusModal } from 'components/wallet.component';
import { RESPONSE_CODE_MEMBER_PROVIDER_STATUS } from 'constants/response';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { specialProviderName } from 'utils/common';

// 會員編輯 > 遊戲商資料

interface ProviderInfo {
  Id: number;
  MemberId: number;
  ProviderId: number;
  ProviderName: string;
  CategoryCode: string;
  Status: number;
}

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const { permissions: $p } = useAccount();
  const { id, account, agId } = useParams();
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [modalType, setModalType] = useState<number | null>();
  const [gameMenuItems, setGameMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { data: MemberProviderList, mutate, isValidating } = $get({
    url: 'api/memberprovidersetting/list',
    params: { MemberId: id }
  });

  const handleStatus = (checked: boolean, item: any) => {
    $post({
      url: 'api/memberprovidersetting/update',
      send: {
        MemberId: id,
        Id: item.Id || null,
        ProviderId: item.ProviderId,
        Status: checked ? 1 : 0
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
      },
      resCode: RESPONSE_CODE_MEMBER_PROVIDER_STATUS
    }, setIsLoading)
  };

  const handleAllStatus = (option: number) => {
    setModalType(option);
    setIsOpenStatusModal(true);
  }

  useEffect(() => {
    if (site && MemberProviderList?.Data) {
      const MenuItemObject: any = {};

      MemberProviderList.Data.forEach((item: any) => {
        const label = specialProviderName(item.ProviderName, site.SiteName, item.ProviderCode);
        const content = (
          <Row align="middle" gutter={[12, 12]} className="w-full" wrap={false} style={{ overflow: 'auto' }} >
            <Col span={4}>
              <span className="font-w-md">{i18n.t(item?.CategoryCode)}</span>
            </Col>
            <Col span={20} style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
              <div className="color-07">{`${i18n.t("game")}${i18n.t("account")}`} :</div>
              <div className="font-w-md">&nbsp;{i18n.t(item?.GameAccount)}</div>
              <div style={{ minWidth:'30px' }}><CopyButton text={i18n.t(item?.GameAccount)} /></div>
              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'flex-end' }}> 
                <Switch onChange={c => handleStatus(c, item)} checked={item.Status} loading={isLoading} disabled={!$p('10302')} />
              </div>
            </Col>
          </Row>
        )
        if (!MenuItemObject[label]) {
          MenuItemObject[label] = {
            label: <span className="font-w-md">{label}</span>,
            children: [
              {
                key: `${item.ProviderCode}$$${item.CategoryCode}`,
                label: content
              }
            ]
          }
        } else {
          MenuItemObject[label].children.push({
            key: `${item.ProviderCode}$$${item.CategoryCode}`,
            label: content
          })
        }
      })
      setGameMenuItems(Object.values(MenuItemObject));
    }
  }, [MemberProviderList, site])

  return (
    <div id="gameProviderData">
      <Content className="pr-2 pl-2 pb-2">
        <LayoutTabMember activeKey="2" id={id || 0} account={account} agId={agId} />
        <Row>
          <Row gutter={[12, 12]}>
            {
              ($p('10303') && $p('10302')) &&
              <Col>
                <Select
                  className="w-12"
                  placeholder={i18n.t('gameStatus')}
                  onSelect={handleAllStatus}
                  options={[
                    { value: 1, label: i18n.t('enableAllGames') },
                    { value: 0, label: i18n.t('disableAllGames') }
                  ]}
                />
              </Col>
            }
          </Row>
          {/* 遊戲錢包資料 */}
          <Col span={24} className="mt-1" style={{ overflowX: 'auto' }}>
            <div style={{ width: 'calc(100% - 7px)', minWidth: 1600 }}>
            <Spin spinning={isValidating}>
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <Menu
                    mode="inline"
                    style={{ border: 0 }}
                    items={gameMenuItems?.filter((_, i) => i % 3 === 0)}
                  />
                </Col>
                <Col span={8}>
                  <Menu
                    mode="inline"
                    style={{ border: 0 }}
                    items={gameMenuItems?.filter((_, i) => i % 3 === 1)}
                  />
                </Col>
                <Col span={8}>
                  <Menu
                    mode="inline"
                    style={{ border: 0 }}
                    items={gameMenuItems?.filter((_, i) => i % 3 === 2)}
                  />
                </Col>
              </Row>
            </Spin>
            </div>
          </Col>
        </Row>
      </Content>
      <PopupStatusModal isOpen={isOpenStatusModal} close={() => setIsOpenStatusModal(false)}
        id={id || 0} type={modalType} mutate={mutate}
      />
    </div >
  );
};

export default PageMain;