import { DatabaseFilled, InfoCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Descriptions, Form, Input, Modal, Popover, Row, Spin, Table, Tooltip, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ExcelColumns } from 'constants/excel';
import {
  ALB,
  ALB_BetType,
  ALB_gameResult,
  ALB_gameResult2,
  OG,
  OG_Bet_Place,
  SA,
  SA_BetType,
  WG
} from "constants/report-info";
import dayjs from 'dayjs';
import { MODE, REPORT_STATE, SMS_STATE } from "enum/state";
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import * as common from 'utils/common';
import { toFormatNumber as $f, numColor01, timeS2L } from "utils/common";
import { CopyButton, Export, LayoutPagination } from './layout.component';

export const PopupBetInfo = ({ isOpen, close, betId }: any) => {
  const { data: site } = useSite();
  const { data: bet, mutate } = $get({
    url: 'api/betticket/info',
    params: { BetId: betId },
    allow: !!betId
  });
  const { data: betLink, isValidating, mutate: refreshLink } = $get({
    url: 'api/betticket/info/link',
    params: { BetId: betId },
    allow: !!betId,
    showMessage: false
  });

  // 遊戲名稱
  const gameTypeName = (): any => {
    // case 根據不同遊戲商添加
    switch (bet?.Data?.ProviderCode) {
      case 'ALB':
        return ALB.GameType[JSON.parse(bet?.Data?.Detail)?.gameType]
      case 'SA':
        return SA.GameType[JSON.parse(bet?.Data?.Detail)?.gametype]
      case 'OG':
        return OG.Game_Code[JSON.parse(bet?.Data?.Detail)?.game_code]
      default:
        return common.gameNameTransfer(bet?.Data?.LangName, bet?.Data?.GameName);
    }
  };

  // 細節
  const filterEntries = (entries: any) => {
    // case 根據不同遊戲商添加
    switch (bet?.Data?.ProviderCode) {
      case 'ALB':
        const albExcludeKey = new Set(['appType', 'betTime', 'betMethod', 'gameRoundEndTime', 'gameRoundStartTime']);
        return entries
          .filter(([key]: [string, any]) => !albExcludeKey.has(key))
          .map(([key, value]: [string, any]) => {
            // case根據不同key項目作轉換優化
            switch (key) {
              case 'status':
                return ALB.Status[value] !== undefined ? [key, ALB.Status[value]] : [key, value];
              case 'gameType':
                return ALB.GameType[value] !== undefined ? [key, ALB.GameType[value]] : [key, value];
              case 'commission':
                return ALB.Commission[value] !== undefined ? [key, ALB.Commission[value]] : [key, value];
              case 'betType':
                return (ALB_BetType(gameTypeName())) !== undefined ? [key, (ALB_BetType(gameTypeName()))[value]] : [key, value];
              case 'gameResult':
                return ALB_gameResult(gameTypeName(), value) !== undefined ? [key, ALB_gameResult(gameTypeName(), value)] : [key, value];
              case 'gameResult2':
                return ALB_gameResult2(gameTypeName(), value) !== undefined ? [key, ALB_gameResult2(gameTypeName(), value)] : [key, value];
              default:
                return [key, value];
            }
          });
      case 'SA':
        const saExcludeKey = new Set(['retry', 'txnid', 'amount', 'EndTime', 'bettype', 'currency', 'betsource', 'timestamp', 'IPLocation', 'Payouttime', 'TransactionID', 'WinLossAmount', 'payoutdetails']);
        return entries
          .filter(([key]: [string, any]) => !saExcludeKey.has(key))
          .map(([key, value]: [string, any]) => {
            // case根據不同key項目作轉換優化
            switch (key) {
              case 'hostid':
                return SA.HostId[value] !== undefined ? [key, SA.HostId[value]] : [key, value];
              case 'gametype':
                return SA.GameType[value] !== undefined ? [key, SA.GameType[value]] : [key, value];
              case 'BetType':
                return (SA_BetType(gameTypeName())) !== undefined ? [key, (SA_BetType(gameTypeName()))[value]] : [key, value];
              default:
                return [key, value];
            }
          });
      case 'OG':
        const ogExcludeKey = new Set(['amount', 'remark', 'game_id', 'currency', 'called_at', 'signature', 'timestamp', 'IPLocation', 'other_info', 'secondary_info', 'transaction_id', 'winlose_amount', 'effective_amount', 'transaction_type']);
        return entries
          .filter(([key]: [string, any]) => !ogExcludeKey.has(key))
          .map(([key, value]: [string, any]) => {
            // case根據不同key項目作轉換優化
            switch (key) {
              case 'game_code':
                return OG.Game_Code[value] !== undefined ? [key, OG.Game_Code[value]] : [key, value];
              case 'bet_place':
                return (OG_Bet_Place(gameTypeName())) !== undefined ? [key, (OG_Bet_Place(gameTypeName()))[value]] : [key, value];
              default:
                return [key, value];
            }
          });
      case 'WG':
        const wgExcludeKey = new Set(['created_at', 'prize_date', 'prize_time']);
        return entries
          .filter(([key]: [string, any]) => !wgExcludeKey.has(key))
          .map(([key, value]: [string, any]) => {
            // case根據不同key項目作轉換優化
            switch (key) {
              case 'status':
                return WG.Status[value] !== undefined ? [key, WG.Status[value]] : [key, value];
              case 'playkey':
                return WG.PlayKey[value] !== undefined ? [key, WG.PlayKey[value]] : [key, value];
              default:
                return [key, value];
            }
          });
      default:
        return entries;
    }
  };

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered
      width={850}
      footer={
        <Row justify="center">
          <Button key="cancel" type="primary" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Descriptions size="small" column={2} bordered
        labelStyle={{ width: 160, fontSize: 12, fontWeight: 500 }} contentStyle={{ width: 200, fontSize: 12 }}>
        <Descriptions.Item label={i18n.t('roundNumber')}>
          {bet?.Data?.RoundId}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('betAmount')}>
          {common.toFormatNumber(bet?.Data?.BetAmount)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('memberAccount')}>
          {bet?.Data?.MemberAccount}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('betAmountValid')}>
          {common.toFormatNumber(bet?.Data?.ValidBetAmount)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('agent')}>
          {bet?.Data?.AGAccount}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('payout')}>
          {common.toFormatNumber(bet?.Data?.SettleAmount) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('gameProvider')}>
          {common.specialProviderName(bet?.Data?.ProviderName, site?.SiteName, bet?.Data?.ProviderCode)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('memberWinLoss')}>
          <div className={common.numColor01(bet?.Data?.WinLossAmount)}>
            {common.toFormatNumber(bet?.Data?.WinLossAmount) || '-'}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('gameCategory')}>
          {i18n.t(bet?.Data?.CategoryCode)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('status')}>
          {i18n.t(REPORT_STATE[bet?.Data?.Status])}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('gameName')}>
          {gameTypeName() || common.gameNameTransfer(bet?.Data?.LangName, bet?.Data?.GameName)}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('settlementTime')}>
          {timeS2L(bet?.Data?.EndTime) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('betTime')}>
          {timeS2L(bet?.Data?.BetTime)}
        </Descriptions.Item>
        <Descriptions.Item label={''}>

        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('details')} style={{ maxWidth: 150 }} span={2}>
          {
            bet?.Data?.Detail &&
            filterEntries(Object.entries(JSON.parse(bet?.Data?.Detail)))
              .map((key: any, i: any) => {
                return (
                  <div key={i}>{`${key.toString().replace(',', '：')}`}</div>
                )
              })
          }
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t('link')} span={2}>
          <Spin spinning={isValidating}>
            {
              betLink?.Data?.Url
                ? <Link to={betLink?.Data?.Url} onClick={refreshLink}
                  target="_blank">{betLink?.Data?.Url}</Link>
                : '-'
            }
          </Spin>
        </Descriptions.Item>
      </Descriptions>
    </Modal >
  )
}

// 代理統計分析 代理展開
export const ReportAgentList = ({ agentSaData, columns, params, setParams, isValidating, reset }: any) => {

  useEffect(() => {
    if (agentSaData && agentSaData.Data) {
      setTimeout(init);
    }
  }, [agentSaData, reset]);

  const init = () => {
    setThreeAgent(agentSaData.Data)
    setThreeAgentKey([])
    setExpandAccount('')
  }

  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => !params.AgentAccount && (record.up_count > record.bet_count),
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setExpandAccount(record.Account);
    setThreeAgentKey([record.id]);
    setRefresh(!refresh);
    mutate();
  }

  // 收合
  const backto = (record: any) => {
    if (record.Level === 0) {
      init()
    } else {
      setParentId(record.AGId)
    }
  }
  const [parentId, setParentId] = useState<any>(0);
  const { data: parent } = $get({
    url: 'api/agent/one',
    params: { Id: parentId },
    allow: !!parentId
  })
  useEffect(() => {
    if (parent) {
      setExpandAccount(parent.Data.Account);
      setThreeAgentKey([parent.Data.Id])

      setParentId(0)
    }
  }, [parent]);

  const [threeAgentKey, setThreeAgentKey] = useState<any>([]);
  const [threeAgent, setThreeAgent] = useState<any>([]);

  const [refresh, setRefresh] = useState(false);
  const [expandAccount, setExpandAccount] = useState('');
  const { data: threeList, mutate, isValidating: threeValidating } = $get({
    url: 'api/report/bet/ag/list',
    params: {
      ...params,
      AgentAccount: expandAccount
    },
    allow: !!expandAccount
  });
  useEffect(() => {
    if (threeList && threeList.Data && expandAccount) {
      setThreeAgent(threeList.Data);
    }
  }, [threeList, refresh]);

  return (
    <Table
      size='middle'
      loading={threeValidating || isValidating}
      columns={columns}
      dataSource={threeAgent?.map((item: any) => ({ ...item, key: item.id }))}
      expandable={{ ...expandableSa, expandedRowKeys: threeAgentKey }}
      pagination={{
        showSizeChanger: true,
        showTotal: (total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`,
        total: agentSaData?.TotalRecord,
        current: params.PageIndex,
      }}
      onChange={p => setParams({ ...params, PageIndex: p.current, PageSize: p.pageSize })}
    />
  )
}

// 代理優惠統計 代理展開
export const ReportPromotionList = ({ agentSaData, columns, params, page, setPage, setParams, isValidating, reset }: any) => {

  useEffect(() => {
    if (agentSaData && agentSaData.Data) {
      setTimeout(init);
    }
  }, [agentSaData]);

  const init = () => {
    setThreeAgent(agentSaData.Data)
    setThreeAgentKey([])
    setExpandAccount('')
  }

  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => !params.AgentAccount && (record.up_count > record.bonus_count),
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setExpandAccount(record.Account);
    setThreeAgentKey([record.id]);
    setRefresh(!refresh);
    mutate();
  }

  // 收合
  const backto = (record: any) => {
    if (record.Level === 0) {
      init();
    } else {
      setParentId(record.AGId)
    }
  }
  const [parentId, setParentId] = useState<any>(0);
  const { data: parent } = $get({
    url: 'api/agent/one',
    params: { Id: parentId },
    allow: !!parentId
  })
  useEffect(() => {
    if (parent) {
      setExpandAccount(parent.Data.Account);
      setThreeAgentKey([parent.Data.Id])

      setParentId(0)
    }
  }, [parent]);

  const [threeAgentKey, setThreeAgentKey] = useState<any>([]);
  const [threeAgent, setThreeAgent] = useState<any>([]);

  const [refresh, setRefresh] = useState(false);
  const [expandAccount, setExpandAccount] = useState('');
  const { data: threeList, mutate, isValidating: threeValidating } = $get({
    url: 'api/report/bonusstat/ag/list',
    params: {
      ...params,
      AgentAccount: expandAccount
    },
    allow: !!expandAccount
  });
  useEffect(() => {
    if (threeList && threeList.Data && expandAccount) {
      setThreeAgent(threeList.Data);
    }
  }, [threeList, refresh]);

  return (
    <>
      <Table
        size='middle'
        loading={threeValidating || isValidating}
        columns={columns}
        dataSource={threeAgent?.map((item: any) => ({ ...item, key: item.id }))}
        expandable={{ ...expandableSa, expandedRowKeys: threeAgentKey }}
        pagination={false}
      />
      <LayoutPagination total={agentSaData?.TotalRecord} page={page} setPage={setPage} />
    </>
  )
}

// 輸贏報表 月展開
export const ViewReportWinLose = ({ data, date }: any) => {
  const navigate = useNavigate();

  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => record.rowExpandable,
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setReportDate('');
    setTimeout(() => {
      setReportDate(record.ReportDate);
    });
  }

  // 收合
  const backto = (record: any) => {
    setReportDate('');
  }

  const [list, setList] = useState<any>([]);
  const [reportDate, setReportDate] = useState<any>('');
  useEffect(() => {
    if (data && data.Data) {
      setReportDate('')

      const obj: any = {}
      data.Data.forEach((item: any) => {
        const d = item.ReportDate.split('-');
        const name = `${d[0]}-${d[1]}`
        if (obj[name]) {
          obj[name].TotalBetAmount += item.TotalBetAmount;
          obj[name].TotalBetCount += item.TotalBetCount;
          obj[name].TotalBetMemberCount += item.TotalBetMemberCount;
          obj[name].TotalSettleAmount += item.TotalSettleAmount;
          obj[name].TotalValidBetAmount += item.TotalValidBetAmount;
          obj[name].TotalWinLossAmount += item.TotalWinLossAmount;
        } else {
          obj[name] = JSON.parse(JSON.stringify(item))
          obj[name].ReportDate = name
          obj[name].rowExpandable = true
        }
      });
      setList(Object.values(obj))
    }
  }, [data]);

  const columns: any = [
    {
      dataIndex: 'ReportDate',
      title: i18n.t('date'),
      width: 200,
    },
    {
      dataIndex: 'TotalBetCount',
      title: i18n.t('betCounts'),
      width: 200,
      align: 'right'
    },
    {
      dataIndex: 'TotalBetAmount',
      title: i18n.t('betAmount'),
      width: 200,
      align: 'right',
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'TotalSettleAmount',
      title: i18n.t('payout'),
      width: 200,
      align: 'right',
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'TotalWinLossAmount',
      title: i18n.t('totalWinLossAmounts'),
      width: 200,
      align: 'right',
      render: (val: any) => <div className={common.numColor01(val)}>{common.toFormatNumber(val)}</div>
    },
  ]

  return (
    <>
      <Table
        size="middle"
        expandable={{ ...expandableSa, expandedRowKeys: [reportDate] }}
        dataSource={
          list?.reverse().map((item: ReportWinLoseType, i: number) => ({ key: item.ReportDate, ...item }))
            .filter((item: ReportWinLoseType) => item.ReportDate.includes(reportDate) || !reportDate)
        }
        columns={columns}
        pagination={false}
      />
      {!!reportDate &&
        <Table
          size="middle"
          expandable={{ ...expandableSa, expandedRowKeys: [reportDate] }}
          dataSource={
            data?.Data?.reverse().map((item: ReportWinLoseType, i: number) => ({ key: item.ReportDate, ...item }))
              .filter((item: ReportWinLoseType) => item.ReportDate.includes(reportDate))
          }
          columns={columns}
          pagination={false}
        />}
    </>
  )
}

// 報表 遊戲類型展開
export const DailyList = ({ loading, data, columns, reset }: any) => {

  useEffect(() => {
    if (data) {
      setTimeout(init);
    }
  }, [data, reset]);

  const init = () => {
    setAgentId(0);
    setList([]);
  }

  // 展開按鈕設定
  const expandableSa = {
    rowExpandable: (record: any) => record.rowExpandable && record.Detail.length > 0,
    expandedRowRender: () => <></>,
    // 展開子代
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setAgentId(record.AgentId);
    setList(record.Detail)

  }

  // 收合
  const backto = (record: any) => {
    init();
  }

  const [list, setList] = useState<any>([]);
  const [agentId, setAgentId] = useState<any>(0);

  return (
    <>
      <Table
        loading={loading}
        size="middle"
        expandable={{ ...expandableSa, expandedRowKeys: [agentId] }}
        dataSource={
          data?.map((item: any) => ({ key: item.AgentId, rowExpandable: true, ...item }))
            .filter((item: any) => item.key === agentId || !agentId)
        }
        columns={columns}
        pagination={false}
      />
      {!!agentId &&
        <Table
          size="middle"
          expandable={{ ...expandableSa, expandedRowKeys: [agentId] }}
          dataSource={
            list?.map((item: any) => ({ key: item.AgentId, ...item }))
          }
          columns={columns}
          pagination={false}
        />}
    </>
  )
}

// 報表 交收報表
export const ViewSettlementList = ({ loading, data, columns, reset }: any) => {
  const [list, setList] = useState<any>([]);
  const [activeKey, setActiveKey] = useState<any>('');

  useEffect(() => {
    if (data) {
      setTimeout(init);
    }
  }, [data, reset]);

  const init = () => {
    setActiveKey(0);
    setList([]);
  }

  // 展開按鈕設定
  const expandable = {
    rowExpandable: (record: any) => record.rowExpandable && record.Detail.length > 0,
    expandedRowRender: () => <></>,
    // 展開遊戲大類
    onExpand: (open: boolean, record: any) => {
      if (open) expand(record)
      else backto(record)
    },
  };

  // 點開
  const expand = (record: any) => {
    setActiveKey(record.ProviderCode);
    setList(record.Detail)
  }

  // 收合
  const backto = (record: any) => {
    init();
  }

  return (
    <>
      <Table
        loading={loading}
        size="middle"
        expandable={{ ...expandable, expandedRowKeys: [activeKey] }}
        dataSource={
          data?.Data?.map((item: any) => ({ key: item.ProviderCode, rowExpandable: true, ...item }))
            .filter((item: any) => item.key === activeKey || !activeKey)
        }
        columns={columns}
        pagination={false}
      />
      {!!activeKey &&
        <Table
          size="middle"
          expandable={{ ...expandable, expandedRowKeys: [activeKey] }}
          dataSource={
            list?.map((item: any) => ({ key: item.ProviderId, ...item }))
          }
          columns={[
            {
              dataIndex: 'CategoryCode',
              title: i18n.t('gameCategory'),
              width: 150,
              render: (val) => i18n.t(val)
            },
            {
              dataIndex: 'Rate',
              title: `${i18n.t('rate')}%`,
              align: 'right',
              width: 150,
              render: (val) => common.convertedToPercentage01(val)
            },
            {
              dataIndex: 'BetAmount',
              title: i18n.t('totalBetAmount'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
            {
              dataIndex: 'ValidBetAmount',
              title: i18n.t('totalValidBets'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
            {
              dataIndex: 'WinLossAmount',
              title: i18n.t('totalGamePnL'),
              align: 'right',
              width: 150,
              render: (val) => <div className={common.numColor03(val)}>{common.toFormatNumber(val)}</div>
            },
            {
              dataIndex: 'ReceivableAmount',
              title: i18n.t('totalSettlementAmount'),
              align: 'right',
              width: 150,
              render: (val) => <div className={common.numColor03(val)}>{common.toFormatNumber(val)}</div>
            },
            // 對齊用
            {
              dataIndex: '',
              title: '',
              width: 150,
              render: (val: any) => ''
            },
            {
              dataIndex: 'DonateAmount',
              title: i18n.t('totalDonateAmount'),
              align: 'right',
              width: 150,
              render: (val) => common.toFormatNumber(val)
            },
          ]}
          pagination={false}
        />}
    </>
  )
}

// 短信記錄列表
export const ViewSMSList = ({ data }: any) => {
  const { permissions: $p } = useAccount();
  const [mode, setMode] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case SMS_STATE.success:
        return 'color-pass'
      case SMS_STATE.fail:
        return 'color-reject'
      default: return 'color-padding'
    }
  }

  const handleSetting = () => {
    setIsOpenModal(true);
    setMode(MODE.edit);
  };

  const handleDetail = () => {
    setIsOpenModal(true);
    setMode(MODE.detail);
  };

  return (
    <>
      <Row className="mb-1" gutter={[12, 12]}>
        {
          ($p('61001') && $p('61002')) &&
          <Col >
            <Button type="primary" onClick={() => handleSetting()}>
              {i18n.t('setting')}
            </Button>
          </Col>
        }
        {
          ($p('61001') && !$p('61002')) &&
          <Col >
            <Button type="primary" onClick={() => handleDetail()}>
              {i18n.t('detail')}
            </Button>
          </Col>
        }
      </Row>
      <Table
        size="middle"
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Merchant',
            width: 150,
            title: i18n.t('SmsMerchantCode'),
          },
          {
            dataIndex: 'SendTime',
            width: 180,
            title: i18n.t('sendTime'),
          },
          {
            dataIndex: 'Role',
            width: 150,
            title: `${i18n.t('member')}/${i18n.t('agent')}`,
            render: (val) => val ? i18n.t('agent') : i18n.t('member')
          },
          {
            dataIndex: 'Account',
            width: 150,
            title: i18n.t('account'),
          },
          {
            dataIndex: 'PhoneNumber',
            width: 150,
            title: i18n.t('phoneNumber'),
            render: (val, { PhonePrefix }: any) => `${PhonePrefix}-${val}`
          },
          {
            dataIndex: 'Status',
            width: 150,
            title: i18n.t('status'),
            render: (val) => <div className={stateColor(val)}>{i18n.t(SMS_STATE[val])}</div>
          },
          {
            dataIndex: 'Content',
            title: i18n.t('content'),
            render: (val) => val || '-'
          },
        ]}
        pagination={false}
      />
      <PopupSmsModal isOpen={isOpenModal} close={() => setIsOpenModal(false)} mode={mode} />
    </>
  )
}

// 短信設定／詳情彈窗
export const PopupSmsModal = ({ isOpen, close, mode }: any) => {
  const disabled = mode === MODE.detail
  const [form] = useForm();
  const { data: smsTemplateInfo, isValidating, mutate } = $get({
    url: 'api/sms/template/info'
  })

  useEffect(() => {
    if (smsTemplateInfo && isOpen) {
      form.setFieldsValue({
        Verification: smsTemplateInfo?.Data[0]?.Code,
        Forget: smsTemplateInfo?.Data[1]?.Code,
      })
    }
  }, [smsTemplateInfo, isOpen]);

  const onFinish = (formData: any) => {
    if (mode === MODE.edit) {
      $post({
        url: 'api/sms/template/update',
        send: {
          Verification: formData.Verification,
          Forget: formData.Forget
        },
        success: () => {
          message.success(i18n.t('operationSuccess'));
          onClose();
          mutate();
        },
      });
    } else {
      onClose();
    }
  }

  const onClose = () => {
    close();
  }

  return (
    <Modal
      title={mode === MODE.edit ? i18n.t('setting') : i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Spin spinning={isValidating}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                name="Verification"
                label={
                  <>
                    {i18n.t('vertificationCodeContent')}
                    <Tooltip placement="top" className="size-12" title={i18n.t('verificationCodeRandomlyGeneratedBySystem')}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </>
                }
                rules={[{ required: true, type: 'string', max: 200 }]}>
                <Input.TextArea disabled={disabled} autoSize={{ minRows: 3, maxRows: 5 }} maxLength={200} showCount />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Forget"
                label={
                  <>
                    {i18n.t('forgetPasswordContent')}
                    <Tooltip placement="top" className="size-12" title={i18n.t('newPasswordBeDisplayed')}>
                      <InfoCircleFilled />
                    </Tooltip>
                  </>
                }
                rules={[{ required: true, type: 'string', max: 200 }]}>
                <Input.TextArea disabled={disabled} autoSize={{ minRows: 3, maxRows: 5 }} maxLength={200} showCount />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row justify="center" className="mt-1" gutter={[12, 12]}>
                <Col>
                  <Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                <Col>
                  <Button key="confirm" htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}

// 投注報表 會員頁籤列表
export const BettingReportMemberTable = ({ i, memberParams, activeKey, account, handleBetInfo, refs }: any) => {

  useImperativeHandle(refs, () => ({
    mutate: mutate,
  }));

  const exist = (account && account !== '') || i === 99;

  const [param, setParam] = useState();
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data, mutate, isValidating } = $get({
    url: 'api/betticket/list',
    params: {
      ...(param || memberParams),
      PageSize: page[1],
      PageIndex: page[0],
    },
    allow: exist
  });

  useEffect(() => {
    setPage([1, page[1]]);
    setParam({
      ...memberParams,
      MemberAccount: account
    })
  }, [memberParams]);

  return (
    <div style={{ display: exist && activeKey === account ? 'block' : 'none' }}>
      <Spin spinning={isValidating}>
        {/* 總覽 */}
        <Row className="mt-1 mb-1" gutter={[10, 10]}>
          <Col span={24}>
            <Table
              loading={isValidating}
              size="middle"
              dataSource={
                data ? [{
                  key: 1,
                  TotalRecord: data?.TotalRecord,
                  TotalBetAmount: data?.TotalBetAmount,
                  TotalValidBetAmount: data?.TotalValidBetAmount,
                  TotalWinLossAmount: data?.TotalWinLossAmount,
                }] : []
              }
              columns={[
                {
                  dataIndex: 'TotalRecord',
                  title: i18n.t('totalBets'),
                  align: 'right',
                  width: '25%',
                  render: (val) => $f(val, 0)
                },
                {
                  dataIndex: 'TotalBetAmount',
                  title: i18n.t('totalBet'),
                  align: 'right',
                  width: '25%',
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'TotalValidBetAmount',
                  title: i18n.t('betAmountValid'),
                  align: 'right',
                  width: '25%',
                  render: (val) => $f(val)
                },
                {
                  dataIndex: 'TotalWinLossAmount',
                  title: i18n.t('memberWinLoss'),
                  align: 'right',
                  width: '25%',
                  render: (val) => <span className={numColor01(val)}>{$f(val)}</span>
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        {/* 列表 */}
        <ViewBetList data={data} handleBetInfo={handleBetInfo} account={account} param={param} memberParams={memberParams} />
        <LayoutPagination total={data?.TotalRecord} setPage={setPage} page={page} />
      </Spin>
    </div>
  )
}

export const ViewBetList = ({ data, handleBetInfo, account, param, memberParams }: {
  data: {
    State: string;
    Data: Bet[];
    TotalRecord: number;
  }
  handleBetInfo: (betId: number) => void;
  account: string;
  param: any;
  memberParams: any;
}) => {
  const site = useSite();
  const { init, permissions: $p } = useAccount();
  const [columns, setColumns] = useState<any>([]);
  const updateColumnShow = (key: string, checked: boolean) => {
    columns.find((item: any) => {
      if (item.dataIndex === key) {
        item.show = checked;
        return true
      }
    })
    setColumns([...columns]);
  };
  useEffect(() => {
    if (init) {
      setColumns(
        [
          {
            dataIndex: 'RoundId',
            title: i18n.t('roundNumber'),
            fixed: 'left',
            width: 280,
          },
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            width: 120,
          },
          {
            dataIndex: 'AGAccount',
            title: i18n.t('agent'),
            width: 120,
          },
          {
            dataIndex: 'ProviderName',
            title: i18n.t('gameProviderCategory'),
            width: 150,
            render: (val: any, { CategoryCode, ProviderCode }: any) => (
              <span>
                {common.specialProviderName(val, site.data.SiteName, ProviderCode)}／{CategoryCode ? i18n.t(CategoryCode) : '-'}
              </span>
            )
          },
          {
            dataIndex: 'LangName',
            title: i18n.t('gameName'),
            width: 150,
            render: (val: any, { GameName }: any) => common.gameNameTransfer(val, GameName) || '-'
          },
          {
            dataIndex: 'BetAmount',
            title: i18n.t('betAmount'),
            align: 'right',
            width: 120,
            render: (val: any) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'ValidBetAmount',
            title: i18n.t('betAmountValid'),
            align: 'right',
            width: 120,
            render: (val: any) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'SettleAmount',
            title: () => (
              <>
                <Tooltip placement="top" className="size-12" title={i18n.t('betAmountAndMemberWinLoss')}>
                  <InfoCircleFilled />
                </Tooltip>
                <span style={{ marginLeft: 5 }}>{i18n.t('payout')}</span>
              </>
            ),
            align: 'right',
            width: 120,
            render: (val: any) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'WinLossAmount',
            title: i18n.t('memberWinLoss'),
            align: 'right',
            width: 120,
            render: (val: any) => <span className={common.numColor01(val)}>{common.toFormatNumber(val)}</span>
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 100,
            render: (val: any) => i18n.t(REPORT_STATE[val])
          },
          {
            dataIndex: 'BetTime',
            title: i18n.t('betTime'),
            width: 200,
            render: (val: any) => timeS2L(val)
          },
          {
            dataIndex: 'EndTime',
            title: i18n.t('settlementTime'),
            width: 200,
            render: (val: any) => val && val !== '0000-00-00 00:00:00'
              ?
              <Row justify="start" align="middle" className={'size-12'}>
                <span>{timeS2L(val)}</span>
                <CopyButton text={timeS2L(val)} />
              </Row>
              : '-'
          },
          {
            dataIndex: 'CancelTime',
            title: i18n.t('cancellationTime'),
            width: 200,
            render: (val: any, { Status, EndTime }: any) => (Status === REPORT_STATE.unsettledCancellation || Status === REPORT_STATE.settledCancellation) ? timeS2L(EndTime) : '-'
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 80,
            render: (_: any, record: any) => (
              <Button size="small" type="link" onClick={() => handleBetInfo(record.key)}>{i18n.t('detail')}</Button>
            )
          },
        ].map(item => ({ ...item, show: true }))
      )
    }
  }, [init]);

  return (
    <>
      <Row justify="space-between">
        <Col>
          {/* 匯出 */}
          {$p('60102') && 
          <Export
            fileName={account ? `${dayjs().format('YYYYMMDDHHmm')}_${account}.xlsx` : null}
            sheetName={account || i18n.t('bettingReports')} url={'/betticket/export'}
            columns={ExcelColumns.ReportBetting} param={{
              ...(param || memberParams),
              PageSize: 9999999,
              PageIndex: 1,
            }} 
          />}
        </Col>
        <Col>
          <Popover
            placement="bottomRight"
            trigger="click"
            content={
              <div>
                {columns
                  .filter((item: any) => !item.isOpenMore)
                  .map((item: any, i: number) =>
                    <div key={i}>
                      <Checkbox checked={item.show} onChange={e => updateColumnShow(item.dataIndex, e.target.checked)}>
                        {typeof item.title === 'function'
                          ? React.Children.map(item.title().props.children[1],
                            (child) => typeof child === 'object' && child.type === 'span'
                              ? child.props.children
                              : child
                          )
                          : item.title
                        }
                      </Checkbox>
                    </div>
                  )}
              </div>
            }
          >
            <Button><DatabaseFilled /></Button>
          </Popover>
        </Col>
      </Row>
      <Table
        className={'mt-1'}
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map((item: Bet) => ({ key: item.Id, ...item }))}
        columns={columns.filter((item: any) => item.show) as any}
        pagination={false}
      />
    </>
  )
}
