import { combineReducers, configureStore } from "@reduxjs/toolkit"

import searchSlice from "slice/search.slice"

const rootReducers = combineReducers({
  Search: searchSlice,
})

const store = configureStore({
  reducer: rootReducers
})

export type RootType = ReturnType<typeof rootReducers>
export default store