import { Button, Col, Form, Input, Row, Select, Space, Table } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, Export, LayoutNav, LayoutPagination } from 'components/layout.component';
import { ExcelColumns } from 'constants/excel';
import dayjs from 'dayjs';
import { AGENT_LOG } from 'enum/cashlog';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { SEARCH_DATE_TYPE } from 'enum/state';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import * as common from 'utils/common';
import { enumToOptions, timeL2S, timeS2L } from "utils/common";
import { OPERATION_ACCOUNT_ROLE } from "../../../enum/role";
import useAccount from "hooks/account.hook";

// 代理錢包紀錄

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const { isCashVersion: $sc } = useSite();

  const defaultDate = SEARCH_DATE_TYPE.createTime;

  const [form] = useForm();
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    common.tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    common.tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [params, setParams] = useState<any>({
    StartDate: timeL2S(date[0]),
    EndDate: timeL2S(date[1]),
  });

  const { data: recordList, isValidating, mutate } = $get({
    url: $sc ? 'api/agent/cashlog/list' : 'api/agent/cashlog/credit/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (date) {
      if ($sc) {
      setParams({
        Code: formData.Code,
        Account: formData.Account,
        KeyId: formData.KeyId,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1])
      })
      }
      else {
        setParams({
          OperationAccount: formData.OperationAccount,
          Role: formData.Role,
          Account: formData.Account,
          KeyId: formData.KeyId,
          StartDate: timeL2S(date[0]),
          EndDate: timeL2S(date[1])
        })
      }
    }
    if (recordList && recordList.State === 'Success') {
      mutate();
    }
  }

  const onClear = () => {
    setReset(!reset);
    setPage([1, 10]);
    form.resetFields();
  }

  const columns: any = [
    {
      dataIndex: 'Code',
      title: i18n.t('order'),
      fixed: 'left',
      width: 290,
    },
    // 現金版為代理帳號, 信用版為操作帳號
    {
      dataIndex: $sc ? 'AgentAccount' : 'OperatorAccount',
      title: $sc ? i18n.t('agentAccount') : i18n.t('operationAccount'),
      fixed: 'left',
      width: 150,
      render: (val: any, record: any) => {
        if (val) {
          // 0: 會員, 1: 代理
          if (!$sc && record.OperatorRole === 0) {
            return `${i18n.t('member')}/${val}`;

          } else if ($sc || record.OperatorRole === 1) {
            const word = common.agentLevelWord($sc ? record.Level : record.OperatorLevel);
            return `${word}／${val}`;

          } else {
            return val;
          }
        } else return '-';
      }
    },
    {
      dataIndex: 'KeyId',
      title: i18n.t('transactionType'),
      fixed: 'left',
      width: 150,
      render: (val: any, { Amount }: any) => (
        val === String(AGENT_LOG.agentTransfer) && Amount < 0
          ? i18n.t('agentTransferOut')
          : (
            val === String(AGENT_LOG.agentTransfer) && Amount > 0
              ? i18n.t('agentTransferIng')
              : i18n.t(AGENT_LOG[val])
          )
      )
    },
    // 信用版才有異動帳號
    {
      dataIndex: 'TargetAccount',
      title: i18n.t('changedAccount'),
      fixed: 'left',
      width: 150,
      render: (val: any, record: any) => {
        if (val) {
          // 0: 會員, 1: 代理
          if (record.TargetRole === 0) {
            return `${i18n.t('member')}/${val}`;

          } else if (record.TargetRole === 1) {
            const word = common.agentLevelWord(record.TargetLevel);
            return `${word}／${val}`;

          } else {
            return val;
          }
        } else return '-';
      }
    },
    {
      dataIndex: 'BeforeAmount',
      title: i18n.t('amountBeforeTransaction'),
      align: 'right',
      width: 210,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'Amount',
      title: i18n.t('transactionAmount'),
      align: 'right',
      width: 210,
      render: (val: any) => <div className={common.numColor03(val)}>{common.toFormatNumber(val)}</div>
    },
    {
      dataIndex: 'AfterAmount',
      title: i18n.t('amountAfterTransaction'),
      align: 'right',
      width: 210,
      render: (val: any) => common.toFormatNumber(val)
    },
    {
      dataIndex: 'CreateDate',
      title: i18n.t('createTime'),
      width: 180,
      render: (val: string) => timeS2L(val)
    },
    {
      dataIndex: 'UpdateDate',
      title: i18n.t('updateTime'),
      width: 180,
      render: (val: string) => timeS2L(val)
    },
    {
      dataIndex: 'Remark',
      title: i18n.t('remark'),
      render: (val: any) => val === "null" || val === "" ? '-' : val,
      width: 210
    },
  ].filter(item => !($sc && item.dataIndex === 'TargetAccount'))

  return (
    <div id="container">
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish} initialValues={{
          TimeMode: defaultDate, 
          Role: 1
        }}>
          <Row align="middle" gutter={[12, 12]}>
            {$sc &&
              <Col className="w-12">
                <Form.Item name="Code">
                  <Input placeholder={`${i18n.t('order')}`} />
                </Form.Item>
              </Col>
            }
            {$sc &&
              <Col className="w-12">
                <Form.Item name="Account">
                  <Input placeholder={`${i18n.t('agentAccount')}`} />
                </Form.Item>
              </Col>
            }
            {!$sc &&
              <Col className="w-12">
                <Form.Item name="OperationAccount">
                  <Input placeholder={`${i18n.t('operationAccount')}`} />
                </Form.Item>
              </Col>
            }
            {!$sc && <Col>
            <Space.Compact>
              <Form.Item className="w-6" name="Role" >
                <Select
                  options={enumToOptions(OPERATION_ACCOUNT_ROLE)}
                />
              </Form.Item>
              <Form.Item name="Account">
                <Input className="w-6" placeholder={`${i18n.t('changedAccount')}`} />
              </Form.Item>
            </Space.Compact>
            </Col>
            }
            <Col className="w-12">
              <Form.Item name="KeyId">
                <Select
                  className="w-full"
                  placeholder={i18n.t('allTransactionTypes')}
                  options={[
                    { value: '', label: i18n.t('allTransactionTypes') },
                    ...common.enumToOptions(AGENT_LOG),
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} className="mt-1">
            <Col className="w-12">
              {/* 還是鎖的 */}
              <Form.Item name="TimeMode">
                <Select
                  className="w-full"
                  disabled
                  options={common.enumToOptions(SEARCH_DATE_TYPE)}
                />
              </Form.Item>
            </Col>
            <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset} rangeLimit={DATE_RANGE_LIMIT.past31Days} />
            <Col>
              <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
            </Col>
            <Col>
              <Button onClick={onClear}>{i18n.t('clear')}</Button>
            </Col>
          </Row>
          <Row className="mt-1">
            {
              $p('41002') &&
              <Col>
                <Export url={$sc ? '/agent/cashlog/export': '/agent/cashlog/credit/export' } param={{
                  ...params, PageSize: recordList && recordList.TotalRecord, PageIndex: 1
                }} sheetName={i18n.t('agentWalletRecord')} columns={ExcelColumns.AgentWalletRecord.filter(item => {
                  if ($sc) {
                    return item.dataIndex !== 'OperatorAccount' && item.dataIndex !== 'TargetAccount';
                  } else {
                    return item.dataIndex !== 'AgentAccount';
                  }
                })
                } />
              </Col>
            }
          </Row>
        </Form>
        <Table
          loading={isValidating}
          size="middle"
          className="mt-1"
          scroll={{ x: 1500 }}
          dataSource={recordList?.Data.map((item: any) => ({ key: item.Id, ...item }))}
          columns={columns}
          pagination={false}
        />
        <LayoutPagination total={recordList ? recordList.TotalRecord : 0} setPage={setPage} page={page} />
      </Content>
    </div>
  );
};

export default PageMain;