import { Button, Col, Form, Input, message, Row, Select, Spin, Table, Tooltip } from "antd";
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupAddChatReply, PopupAddChatReplyContent, PopupEditChatReply } from 'components/chat.component';
import { InquiryWindow, LayoutNav, LayoutPagination, LayoutTabChatSetting } from "components/layout.component";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get, $post } from "services";
import { timeS2L } from "utils/common";
import { RESPONSE_CODE_CHAT } from "../../../constants/response";

interface SearchParams {
  CategoryId?: number;
  Content?: string;
}

// 聊天室設置 快捷回覆

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [id, setId] = useState<number | null>(null);
  const [page, setPage] = useState<number[]>([1, 10]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenAddContent, setIsOpenAddContent] = useState(false);
  const [editData, setEditData] = useState();
  const [params, setParams] = useState<SearchParams>({});
  const {
    data: QuickReplyCategory,
    isValidating: CategoryLoading,
    mutate: refreshCategory } = $get({ url: 'api/chatsetting/cannedresponse/category/list' });
  const { data: QuickReplyList, isValidating, mutate: refreshList } = $get({
    url: 'api/chatsetting/cannedresponse/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  })
  const onFinish = (value: {
    category: number;
    content: string;
  }) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      CategoryId: value.category,
      Content: value.content
    })
    refresh();
  }

  const onEdit = (record: any) => {
    setIsOpenEdit(true);
    setEditData(record);
  }

  const onDelete = (id: number) => {
    setIsOpenDelete(true);
    setId(id);
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
    refresh();
  }

  const refresh = () => {
    refreshList();
    refreshCategory();
  }

  const handleSubmit = () => {
    $post({
      url: 'api/chatsetting/cannedresponse/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        refresh();
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }

  return (
    <div id="container">
      <Content>
        <Form form={form} onFinish={onFinish}>
          <LayoutTabChatSetting activeKey='2' />
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item name="category" className="w-12">
                    <Select
                      loading={CategoryLoading}
                      className="w-full"
                      placeholder={i18n.t('selectCategory')}
                      options={
                        QuickReplyCategory ?
                          [{ value: '', label: i18n.t('ALL') },
                          ...QuickReplyCategory.Data.map((item: ChatQuickReplyCategory) => (
                            { value: item.Id, label: item.Name }
                          ))] : []
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="content"
                    rules={[{ type: 'string', min: 4, message: `${i18n.t('minimum4CharactersRequired')}` }]}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}${i18n.t('content')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Row gutter={[12, 12]}>
                    <Col><Button type="primary" htmlType="submit"
                      loading={isValidating}>{i18n.t('search')}</Button></Col>
                    <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  {$p('70302') && <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('addType')}</Button>}
                </Col>
                <Col>
                  {$p('70303') && <Button type="primary" onClick={() => setIsOpenAddContent(true)}>{i18n.t('addContent')}</Button>}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Spin spinning={isValidating}>
                <Table
                  size="middle"
                  scroll={{ x: 1500 }}
                  dataSource={
                    QuickReplyList?.Data.map((item: QuickReplyList, i: number) => ({ key: item.Id, sort: i + 1, ...item }))
                    || []
                  }
                  columns={[
                    {
                      title: i18n.t('#'),
                      dataIndex: 'sort',
                      width: 100
                    },
                    {
                      title: i18n.t('type'),
                      dataIndex: 'CategoryName',
                      width: 200
                    },
                    {
                      title: i18n.t('content'),
                      dataIndex: 'Content',
                      width: 400,
                      render: (val) => (
                        <Tooltip title={val}>
                          <div style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden', textOverflow: 'ellipsis',
                          }}>
                            {val}
                          </div>
                        </Tooltip>
                      )
                    },
                    {
                      title: i18n.t('createTime'),
                      dataIndex: 'CreateDate',
                      width: 150,
                      render: (val: string) => timeS2L(val)
                    },
                    {
                      title: i18n.t('updater'),
                      dataIndex: 'LastModifyAccount',
                      width: 150,
                      render: (val) => val || 'System'
                    },
                    {
                      title: i18n.t('updateTime'),
                      dataIndex: 'UpdateDate',
                      width: 150,
                      render: (val: string) => timeS2L(val)
                    },
                    {
                      title: i18n.t('operation'),
                      fixed: "right",
                      width: 100,
                      render: (_, record: any) => (
                        <>
                          {$p('70304') && <Button type="link" onClick={() => onEdit(record)}>{i18n.t('edit')}</Button>}
                          {$p('70305') && <Button type="link" onClick={() => onDelete(record.key)}>{i18n.t('delete')}</Button>}
                        </>
                      )
                    },
                  ]}
                  pagination={false}
                />
                <LayoutPagination total={QuickReplyList?.TotalRecord || 0}
                  setPage={setPage} page={page} />
              </Spin>
            </Col>
          </Row>
        </Form>
      </Content>
      {/* Modal */}
      <PopupAddChatReply isOpen={isOpenAdd} close={() => setIsOpenAdd(false)} refresh={refresh} />
      <PopupAddChatReplyContent isOpen={isOpenAddContent} close={() => setIsOpenAddContent(false)}
        Category={QuickReplyCategory?.Data} refresh={refresh} />
      <PopupEditChatReply isOpen={isOpenEdit} close={() => setIsOpenEdit(false)} Category={QuickReplyCategory?.Data}
        editData={editData} refresh={refresh} />
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} action={handleSubmit} />
    </div>
  );
};

export default PageMain;