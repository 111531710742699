import { Button, Col, Divider, Form, Input, Row, Select, Steps, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { InquiryWindow, LayoutNav, TooltipInfo } from 'components/layout.component';
import { RESPONSE_CODE_PROMOTION } from 'constants/response';
import { AMOUNT_MAX } from 'constants/system';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { toFormatNumber as $f, enumToOptions, ObjectToOptions, verify } from 'utils/common';
import { Descriptions } from 'antd';
import { BONUS_CONDITIONS, UPGRADE_RESTRICTIONS } from 'constants/level';

// 會員等級設定

const PageMain: React.FC = () => {
  const { permissions: $p } = useAccount();
  const { isCashVersion: $sc } = useSite();
  // 編輯跟刪除會不同item
  const [deleteRecord, setDeleteRecord] = useState<any>({});
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [list, setList] = useState<MemberlevelSetting[]>([]);
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [stepsCurrent, setStepsCurrent] = useState<number>(0);

  // 最多幾筆不能按新增

  const { data: memberlevelList, mutate, isValidating } = $get({ url: 'api/memberlevel/setting/list' });
  const maxLevelLength = 16;

  useEffect(() => {
    init();
  }, [memberlevelList]);

  const init = () => {
    if (memberlevelList) {
      form.setFieldsValue({
        UpgradeLimit: memberlevelList.Data?.UpgradeLimit,
        UpgradeRewardCondition: memberlevelList.Data?.UpgradeRewardCondition,
        WeeklyRewardCondition: memberlevelList.Data?.WeeklyRewardCondition,
        MonthlyRewardCondition: memberlevelList.Data?.MonthlyRewardCondition,
      })

      setList(memberlevelList.Data?.settings?.map((item: MemberlevelSetting, i: number) => {
        const WeeklyReward = item.WeeklyRewardSetting;
        const MonthlyReward = item.MonthlyRewardSetting;

        form.setFieldsValue({
          [`Name-${i}`]: item.Name,
          [`UPCDepositAmount-${i}`]: item.UPCDepositAmount,
          [`UPCValidBetAmount-${i}`]: item.UPCValidBetAmount,
          [`LUPROneceReward-${i}`]: item.LUPROneceReward,
          [`LUPRRewardValidBetTimes-${i}`]: item.LUPRRewardValidBetTimes,

          [`Weekly-DepositAmount-${i}`]: WeeklyReward.DepositAmount,
          [`Weekly-ValidBetAmount-${i}`]: WeeklyReward.ValidBetAmount,
          [`Weekly-RewardAmount-${i}`]: WeeklyReward.RewardAmount,
          [`Weekly-RewardValidBetTimes-${i}`]: WeeklyReward.RewardValidBetTimes,

          [`Monthly-DepositAmount-${i}`]: MonthlyReward.DepositAmount,
          [`Monthly-ValidBetAmount-${i}`]: MonthlyReward.ValidBetAmount,
          [`Monthly-RewardAmount-${i}`]: MonthlyReward.RewardAmount,
          [`Monthly-RewardValidBetTimes-${i}`]: MonthlyReward.RewardValidBetTimes,
        })

        return {
          ...item, 
          Position: i,
        };
      }));

      form.validateFields();
    }
  };

  const save = () => {
    const formData = form.getFieldsValue();
    const settings = list.map((item: any, i: number) => {
      return {
        Position: i,
        Name: formData[`Name-${i}`],
        UPCDepositAmount: Number(formData[`UPCDepositAmount-${i}`]),
        UPCValidBetAmount: Number(formData[`UPCValidBetAmount-${i}`]),
        LUPROneceReward: Number(formData[`LUPROneceReward-${i}`]),
        LUPRRewardValidBetTimes: Number(formData[`LUPRRewardValidBetTimes-${i}`]),

        WeeklyRewardSetting: {
          DepositAmount: Number(formData[`Weekly-DepositAmount-${i}`]),
          ValidBetAmount: Number(formData[`Weekly-ValidBetAmount-${i}`]),
          RewardAmount: Number(formData[`Weekly-RewardAmount-${i}`]),
          RewardValidBetTimes: Number(formData[`Weekly-RewardValidBetTimes-${i}`])
        },
        MonthlyRewardSetting: {
          DepositAmount: Number(formData[`Monthly-DepositAmount-${i}`]),
          ValidBetAmount: Number(formData[`Monthly-ValidBetAmount-${i}`]),
          RewardAmount: Number(formData[`Monthly-RewardAmount-${i}`]),
          RewardValidBetTimes: Number(formData[`Monthly-RewardValidBetTimes-${i}`])
        }
      };
    })

    const send: any = {
      UpgradeLimit: formData.UpgradeLimit,
      UpgradeRewardCondition: formData.UpgradeRewardCondition,
      WeeklyRewardCondition: formData.WeeklyRewardCondition,
      MonthlyRewardCondition: formData.MonthlyRewardCondition,
      settings
    };

    $post({
      url: 'api/memberlevel/upgrade',
      send,
      success: () => {
        message.success(i18n.t('updateSuccess'));
        mutate();
        setIsEdit(false);
        setStepsCurrent(0);
      },
      resCode: RESPONSE_CODE_PROMOTION
    })
  };

  const onAddLevelItem = () => {
    const newIndex = list.length;
    const newItem: any = {
      Position: newIndex
    }
    setList([
      ...list,
      newItem
    ])
    form.setFieldsValue({
      [`Name-${newIndex}`]: '',
      [`UPCDepositAmount-${newIndex}`]: '',
      [`UPCValidBetAmount-${newIndex}`]: '',
      [`LUPROneceReward-${newIndex}`]: '',
      [`LUPRRewardValidBetTimes-${newIndex}`]: '',

      [`Weekly-DepositAmount-${newIndex}`]: '',
      [`Weekly-ValidBetAmount-${newIndex}`]: '',
      [`Weekly-RewardAmount-${newIndex}`]: '',
      [`Weekly-RewardValidBetTimes-${newIndex}`]: '',

      [`Monthly-DepositAmount-${newIndex}`]: '',
      [`Monthly-ValidBetAmount-${newIndex}`]: '',
      [`Monthly-RewardAmount-${newIndex}`]: '',
      [`Monthly-RewardValidBetTimes-${newIndex}`]: '',
    })
  }
  
  const onDeleteLevelItem = () => {
    setList((list: any[]) => list.filter((item: any, i: number) => i < list.length - 1));
  };

  const cancel = () => {
    setIsEdit(false);
    init();
  };

  // 不得小於上層
  const validator = (r: any, value: any, name: string, position: number, i?: number) => {
    const preValue = form.getFieldValue(`${name}-${position - 1}`);
    const preError = form.getFieldError(`${name}-${position - 1}`);
    const precondition = $sc && position !== 0;
     // 規格: vip1以後 存款總額至少大於1
     if (!!i && value < 1 && Number(preValue) < 1)  {
      return Promise.reject(() => 
        <p style={{ margin: 0 }}>
          {preError.length === 0 ? `${i18n.t('mustBeGreaterThanOrEqualTo')}${1}` : i18n.t('error')}
        </p>
      );
    } 
    else if (precondition && Number(value) <= Number(preValue))  {
      return Promise.reject(() => 
        <p style={{ margin: 0 }}>
          {preError.length === 0 ? `${i18n.t('mustBeGreaterThan')}${Number(preValue)}` : i18n.t('error')}
        </p>
      );
    }  
    else {
      form.validateFields([`${name}-${position + 1}`]);
      return Promise.resolve();
    }
  }
  
  const nextStep = () => {
    const validateFields: any = [];
    (new Array(list.length).fill(null)).forEach((_:any, i: number) => {
      switch (stepsCurrent) {
        case 0:
          validateFields.push(
            `Name-${i}`, 
            `UPCDepositAmount-${i}`,
            `UPCValidBetAmount-${i}`,
            `LUPROneceReward-${i}`,
            `LUPRRewardValidBetTimes-${i}`,
          )
          break;
        case 1:
          validateFields.push(
            `Weekly-DepositAmount-${i}`,
            `Weekly-ValidBetAmount-${i}`,
            `Weekly-RewardAmount-${i}`,
            `Weekly-RewardValidBetTimes-${i}`,
          )
          break;
        case 2:
          validateFields.push(
            `Monthly-DepositAmount-${i}`,
            `Monthly-ValidBetAmount-${i}`,
            `Monthly-RewardAmount-${i}`,
            `Monthly-RewardValidBetTimes-${i}`,
          )
          break;
      }
    })
    form.validateFields(validateFields)
      .then((e) => {
        if (stepsCurrent === 2) save();
        else setStepsCurrent(val => val + 1);
      })
      .catch((e) => {
        if (e.errorFields.length === 0) {
          if (stepsCurrent === 2) save();
          else setStepsCurrent(val => val + 1);
        }
      });
  }

  return (
    <div id="container">
      <Content className="ph-2">
        {/* 進度條 */}
        <Row className="pb-1">
          <Steps
            size="small"
            current={stepsCurrent}
            items={[{ title: 'Step 1' }, { title: 'Step 2' }, { title: 'Done' }]}
          />
        </Row>

        <Form form={form} component={false} onFinish={save}>

  {/* 步驟一 */}
        <div style={{ display: stepsCurrent === 0 ? 'block' : 'none' }}>
        {/* 會員升級限制 / 編輯 / 取消 */}
        <Row className="pb-1" style={{ borderBottom: '1px solid #EEE' }}>
          <Col span={14}>
            <Descriptions 
              layout="vertical"
              labelStyle={{ fontSize: 12, fontWeight: 700 }}
              size="small"
              bordered
            >
              <Descriptions.Item style={{ width: '50%' }} 
                label={<><span className="require">*</span>{i18n.t('memberUpgradeRestrictions')}</>}>
                <Form.Item name="UpgradeLimit">
                <Select
                  style={{ width: "100%" }}
                  options={ObjectToOptions(UPGRADE_RESTRICTIONS)}
                  disabled={!isEdit}
                />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item style={{ width: '50%' }} 
                label={<><span className="require">*</span>{i18n.t('requirementsForUpgrade')}</>}>
                <Form.Item name="UpgradeRewardCondition">
                <Select
                  style={{ width: "100%" }}
                  options={ObjectToOptions(BONUS_CONDITIONS)}
                  disabled={!isEdit}
                />
                </Form.Item>
              </Descriptions.Item>
           </Descriptions>
          </Col>
          <Col span={10}>
            <Row gutter={[16, 16]} justify={'end'}>
              {!isEdit 
                ? <Col><Button type="primary" onClick={() => setIsEdit(true)}>{i18n.t('edit')}</Button></Col>
                : <>
                  <Col><Button onClick={cancel}>{i18n.t('cancel')}</Button></Col>
                  <Col><Button type="primary" onClick={nextStep}>{i18n.t('nextStep')}</Button></Col>
                </>}
            </Row>
          </Col>
        </Row>
        {/* 新增 */}
        <Row gutter={[16, 16]} className="pb-1 pt-1">
          {$p('30202') &&
            <Col>
              <Button type="primary" onClick={onAddLevelItem} disabled={!isEdit || list.length >= maxLevelLength}>{i18n.t('add')}</Button>
            </Col>}
        </Row>
        {/* 列表 */}
        <Row gutter={[16, 16]} className="pb-1">
          <Col className="w-full">
            <Form.Item name="Id" className="display-none"><Input /></Form.Item>
            <Table
              loading={isValidating}
              size="small"
              bordered
              dataSource={list.map((item: any) => ({ ...item, key: item.Position }))}
              columns={[
                {
                  dataIndex: 'Position',
                  width: 50,
                  align: 'center',
                  render: (val, { Position }) => (Position || 0) + 1
                },
                {
                  dataIndex: 'Name',
                  title: i18n.t('levelName'),
                  width: 150,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Name-${i}`} rules={[
                      { required: true, message: `${i18n.t('required')}` },
                      { max: 10, message: `${i18n.t('levelNameCannotExceed10Characters')}` }
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} disabled={!isEdit} />
                    </Form.Item>
                  </>
                },
                {
                  dataIndex: 'MemberCountAtLevel',
                  title: i18n.t('numberOfPeople'),
                  width: 150,
                  align: 'right',
                  render: (val) => $f(val, 0)
                },
                {
                  // 存款總額
                  dataIndex: 'UPCDepositAmount',
                  title: i18n.t('totalDeposit'),
                  width: 150,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`UPCDepositAmount-${i}`} rules={[
                      ...verify({ point: 4 }),
                      { validator: async (r, value) => validator(r, value, 'UPCDepositAmount', record.Position, i)}
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} disabled={!isEdit} />
                    </Form.Item>
                  </>
                },
                {
                  // 有效投注總額
                  dataIndex: 'UPCValidBetAmount',
                  title: i18n.t('totalValidBet'),
                  width: 150,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`UPCValidBetAmount-${i}`} rules={[
                      ...verify({ point: 4 }),
                      { validator: async (r, value) => validator(r, value, 'UPCValidBetAmount', record.Position)}
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} disabled={!isEdit} />
                    </Form.Item>
                  </>
                },
                {
                  // 晉級彩金
                  dataIndex: 'LUPROneceReward',
                  width: 150,
                  title: <TooltipInfo textKey='upgradeBonus' tipKey='automaticDistributionWhenConditionsMet' />,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`LUPROneceReward-${i}`} rules={verify({ point: 4, max: AMOUNT_MAX })}>
                      <Input placeholder={`${i18n.t('inputData')}`} disabled={!isEdit} />
                    </Form.Item>
                  </>
                },
                {
                  // 洗碼倍率
                  dataIndex: 'LUPRRewardValidBetTimes',
                  width: 150,
                  title: i18n.t('rolloverRate'),
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`LUPRRewardValidBetTimes-${i}`} rules={verify({ point: 0 })}>
                      <Input placeholder={`${i18n.t('inputData')}`} disabled={!isEdit} />
                    </Form.Item>
                  </>
                },
                {
                  dataIndex: 'operation',
                  width: 150,
                  title: <TooltipInfo textKey='operation' tipKey='deleteLevelNeedFromLast' />,
                  render: (_: any, record: any, i: number) => {
                    return (i === list.length - 1 && i !== 0) && <>
                      <Button type="link" disabled={!isEdit} onClick={() => {
                        setDeleteRecord(record);
                        setIsOpenDelete(true);
                      }}>{i18n.t('delete')}</Button>
                    </>
                  },
                },
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        </div>

  {/* 步驟二 */}
        <div style={{ display: stepsCurrent === 1 ? 'block' : 'none' }}>
        <Row className="pb-1" style={{ borderBottom: '1px solid #EEE' }}>
          <Col span={7}>
            <Descriptions 
              layout="vertical"
              labelStyle={{ fontSize: 12, fontWeight: 700 }}
              size="small"
              bordered
            >
              <Descriptions.Item style={{ width: '50%' }} 
                label={<><span className="require">*</span>{i18n.t('weeklyMoneyConditions')}</>}>
                <Form.Item name="WeeklyRewardCondition">
                <Select
                  style={{ width: "100%" }}
                  options={ObjectToOptions(BONUS_CONDITIONS)}
                />
                </Form.Item>
              </Descriptions.Item>
           </Descriptions>
          </Col>
          <Col span={17}>
            <Row gutter={[16, 16]} justify={'end'}>
              {!isEdit 
                ? <Col><Button type="primary" onClick={() => setIsEdit(true)}>{i18n.t('edit')}</Button></Col>
                : <>
                  <Col><Button onClick={() => setStepsCurrent(0)}>{i18n.t('prevStep')}</Button></Col>
                  <Col><Button type="primary" onClick={nextStep}>{i18n.t('nextStep')}</Button></Col>
                </>}
            </Row>
          </Col>
        </Row>
        {/* 列表 */}
        <Row gutter={[16, 16]} className="pb-1 pt-1">
          <Col className="w-full">
            <Form.Item name="Id" className="display-none"><Input /></Form.Item>
            <Table
              loading={isValidating}
              size="small"
              bordered
              dataSource={list.map((item: any) => ({ ...item, key: item.Position }))}
              columns={[
                {
                  dataIndex: 'Position',
                  width: 50,
                  align: 'center',
                  render: (val, { Position }) =>
                    // 墊高用
                    <div style={{ height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {(Position || 0) + 1}
                    </div>
                },
                {
                  dataIndex: 'Name',
                  title: i18n.t('levelName'),
                  width: 175,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Name-${i}`}>
                      <Input placeholder={`${i18n.t('inputData')}`} disabled/>
                    </Form.Item>
                  </>
                },
                {
                  dataIndex: 'MemberCountAtLevel',
                  title: i18n.t('numberOfPeople'),
                  width: 175,
                  align: 'right',
                  render: (val) => $f(val, 0)
                },
                {
                  // 存款總額
                  dataIndex: 'UPCDepositAmount',
                  title: `${i18n.t('totalDeposit')}(${i18n.t('lastWeek')})`,
                  width: 175,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Weekly-DepositAmount-${i}`} rules={[
                      ...verify({ point: 4 }),
                      { validator: async (r, value) => validator(r, value, 'Weekly-DepositAmount', record.Position, i)}
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                },
                {
                  // 有效投注總額
                  dataIndex: 'UPCValidBetAmount',
                  title: `${i18n.t('totalValidBet')}(${i18n.t('lastWeek')})`,
                  width: 175,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Weekly-ValidBetAmount-${i}`} rules={[
                      ...verify({ point: 4 }),
                      { validator: async (r, value) => validator(r, value, 'Weekly-ValidBetAmount', record.Position)}
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                },
                {
                  dataIndex: 'LUPRWeekReward',
                  width: 175,
                  title: <TooltipInfo textKey='weeklyBonus' tipKey='automaticDistributionWhenConditionsMet' />,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Weekly-RewardAmount-${i}`} rules={verify({ point: 4, max: AMOUNT_MAX })}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                },
                {
                  // 洗碼倍率
                  dataIndex: 'LUPRRewardValidBetTimes',
                  width: 175,
                  title: i18n.t('rolloverRate'),
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Weekly-RewardValidBetTimes-${i}`} rules={verify({ point: 0 })}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                }
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        </div>

  {/* 步驟三 */}
        <div style={{ display: stepsCurrent === 2 ? 'block' : 'none' }}>
        {/* 條件 / 確認 */}
        <Row className="pb-1" style={{ borderBottom: '1px solid #EEE' }}>
          <Col span={7}>
            <Descriptions 
              layout="vertical"
              labelStyle={{ fontSize: 12, fontWeight: 700 }}
              size="small"
              bordered
            >
              <Descriptions.Item style={{ width: '50%' }} 
                label={<><span className="require">*</span>{i18n.t('monthlyMoneyConditions')}</>}>
                <Form.Item name="MonthlyRewardCondition">
                <Select
                  style={{ width: "100%" }}
                  options={ObjectToOptions(BONUS_CONDITIONS)}
                />
                </Form.Item>
              </Descriptions.Item>
           </Descriptions>
          </Col>
          {/* 按鈕 */}
          <Col span={17}>
            <Row gutter={[16, 16]} justify={'end'}>
              {!isEdit 
                ? <Col><Button type="primary" onClick={() => setIsEdit(true)}>{i18n.t('edit')}</Button></Col>
                : <>
                  <Col><Button onClick={() => setStepsCurrent(1)}>{i18n.t('prevStep')}</Button></Col>
                  <Col><Button type="primary" onClick={nextStep}>{i18n.t('confirm')}</Button></Col>
                </>}
            </Row>
          </Col>
        </Row>
        {/* 列表 */}
        <Row gutter={[16, 16]} className="pb-1 pt-1">
          <Col className="w-full">
            <Form.Item name="Id" className="display-none"><Input /></Form.Item>
            <Table
              loading={isValidating}
              size="small"
              bordered
              dataSource={list.map((item: any) => ({ ...item, key: item.Position }))}
              columns={[
                {
                  dataIndex: 'Position',
                  width: 50,
                  align: 'center',
                  render: (val, { Position }) =>
                    // 墊高用
                    <div style={{ height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {(Position || 0) + 1}
                    </div>
                },
                {
                  dataIndex: 'Name',
                  title: i18n.t('levelName'),
                  width: 175,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Name-${i}`}>
                      <Input placeholder={`${i18n.t('inputData')}`} disabled/>
                    </Form.Item>
                  </>
                },
                {
                  dataIndex: 'MemberCountAtLevel',
                  title: i18n.t('numberOfPeople'),
                  width: 175,
                  align: 'right',
                  render: (val) => $f(val, 0)
                },
                {
                  // 存款總額
                  dataIndex: 'UPCDepositAmount',
                  title: `${i18n.t('totalDeposit')}(${i18n.t('lastMonth')})`,
                  width: 175,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Monthly-DepositAmount-${i}`} rules={[
                      ...verify({ point: 4 }),
                      { validator: async (r, value) => validator(r, value, 'Monthly-DepositAmount', record.Position, i)}
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                },
                {
                  // 有效投注總額
                  dataIndex: 'UPCValidBetAmount',
                  title: `${i18n.t('totalValidBet')}(${i18n.t('lastMonth')})`,
                  width: 175,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Monthly-ValidBetAmount-${i}`} rules={[
                      ...verify({ point: 4 }),
                      { validator: async (r, value) => validator(r, value, 'Monthly-ValidBetAmount', record.Position)}
                    ]}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                },
                {
                  dataIndex: 'LUPRMonthReward',
                  width: 175,
                  title: <TooltipInfo textKey='amountMonthly' tipKey='automaticDistributionWhenConditionsMet' />,
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Monthly-RewardAmount-${i}`} rules={verify({ point: 4, max: AMOUNT_MAX })}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                },
                {
                  // 洗碼倍率
                  dataIndex: 'LUPRRewardValidBetTimes',
                  width: 175,
                  title: i18n.t('rolloverRate'),
                  render: (_: any, record: any, i: number) => <>
                    <Form.Item name={`Monthly-RewardValidBetTimes-${i}`} rules={ verify({ point: 0 })}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </>
                }
              ]}
              pagination={false}
            />
          </Col>
        </Row>
        </div>

        </Form>

      </Content>
      <InquiryWindow isOpen={isOpenDelete} close={() => setIsOpenDelete(false)} 
        msg={`${i18n.t('confirmDeletion')} ${i18n.t('no')} ${deleteRecord.Position + 1} ${i18n.t('counts')} ${i18n.t('isData')}`} 
        action={onDeleteLevelItem} />
    </div >
  );
};

export default PageMain;