import i18n from "i18n"

// 權限列表 翻譯

// 權限管理: 大類
export const menui18n: any = {
  2: i18n.t('member'),
  3: i18n.t('financial'),
  4: i18n.t('discount'),
  5: i18n.t('agent'),
  6: i18n.t('frontendPage'),
  7: i18n.t('statisticalReports'),
  8: i18n.t('chatRoom'),
  1: i18n.t('advanced'),
  9: i18n.t('allAgent'),
}

// 權限管理: 頁面
export const managementi18n: any = {
  14: i18n.t('memberList'),
  15: `${i18n.t('memberList')}-${i18n.t('basicData')}`,
  16: `${i18n.t('memberList')}-${i18n.t('gameProviderData')}`,
  17: `${i18n.t('memberList')}-${i18n.t('contactInformation')}`,
  18: `${i18n.t('memberList')}-${i18n.t('bankCardList')}`,
  19: `${i18n.t('memberList')}-${i18n.t('rebateList')}`,
  20: i18n.t('accountRecords'),
  21: i18n.t('realNameVerification'),
  22: i18n.t('withdrawalInfoAudit'),
  23: i18n.t('instantMessages'),
  81: `${i18n.t('instantMessages')}-${i18n.t('messageTemplate')}`,
  24: i18n.t('memberIPCheck'),
  25: `${i18n.t('memberIPCheck')}-${i18n.t('IPBlockManagement')}`,
  26: i18n.t('gameIPCheck'),
  80: i18n.t('memberTag'),
  89: i18n.t('memberGroup'),
  90: `${i18n.t('memberGroup')}-${i18n.t('memberList')}`,

  94: i18n.t('memberRolloverQuery'),
  27: i18n.t('withdrawalQuery'),
  28: i18n.t('depositQuery'),
  29: i18n.t('adjustmentQuery'),
  30: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('withdrawing')}`,
  32: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('withdrawing')}-${i18n.t('total')}`,
  33: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('deposit')}`,
  35: `${i18n.t('offlineDepositWithdraw')}-${i18n.t('deposit')}-${i18n.t('total')}`,
  36: `${i18n.t('paymentManagement')}-${i18n.t('withdrawing')}`,
  37: `${i18n.t('paymentManagement')}-${i18n.t('withdrawing')}-${i18n.t('total')}`,
  38: `${i18n.t('paymentManagement')}-${i18n.t('deposit')}`,
  39: `${i18n.t('paymentManagement')}-${i18n.t('deposit')}-${i18n.t('total')}`,
  91: `${i18n.t('depositAndWithdrawalChannelSetting')}-${i18n.t('withdraw')}`,
  92: `${i18n.t('depositAndWithdrawalChannelSetting')}-${i18n.t('deposit')}`,

  // 優惠
  40: i18n.t('promotionManagement'),
  41: i18n.t('memberLevelSetting'),
  42: i18n.t('promotionQuery'),
  76: i18n.t('memberRebateSettings'),
  43: i18n.t('rebatePromotionQuery'),
  44: i18n.t('memberLevelPromotionQuery'),
  78: i18n.t('jackpotSettings'),
  79: i18n.t('jackpotSearch'),

  // 全民代理
  86: i18n.t('peopleAgentSetting'),
  87: i18n.t('peopleAgentSearch'),
  88: i18n.t('peopleAgentRebateSearch'),

  // 代理
  45: i18n.t('generalAgentList'),
  46: `${i18n.t('generalAgentList')}-${i18n.t('edit')}`,
  47: `${i18n.t('generalAgentList')}-${i18n.t('settlementReports')}`,
  48: `${i18n.t('generalAgentList')}-${i18n.t('dailyReport')}`,
  49: i18n.t('agentWithdrawalQuery'),
  50: i18n.t('agentDepositQuery'),
  51: i18n.t('agentAdjustmentQuery'),
  52: i18n.t('agentBankCardQuery'),
  53: i18n.t('agentReportAudit'),
  54: i18n.t('agentWalletRecord'),
  55: i18n.t('agentTag'),
  85: i18n.t('layoutSetting'),

  56: i18n.t('frontendText'),
  57: i18n.t('carouselImage'),
  58: `${i18n.t('frontDeskSettings')}-${i18n.t('popupSetting')}`,
  75: `${i18n.t('frontDeskSettings')}-${i18n.t('frontDeskSettings')}`,
  59: i18n.t('SEOSetting'),

  60: i18n.t('bettingReports'),
  61: i18n.t('dataOverview'),
  62: i18n.t('operationReports'),
  63: i18n.t('gameStatisticalAnalysis'),
  64: i18n.t('agentStatisticalAnalysis'),
  65: i18n.t('agentPromotionStatistical'),
  66: i18n.t('memberRanking'),
  67: i18n.t('memberWinlossReport'),
  72: i18n.t('settlementReport'),
  73: i18n.t('smsRecord'),
  82: i18n.t('couponManagement'),
  83: i18n.t('couponQuery'),
  84: i18n.t('gameManagement'),

  68: i18n.t('chatRoomLog'),
  69: `${i18n.t('chatRoomSettings')}-${i18n.t('accountLookup')}`,
  70: `${i18n.t('chatRoomSettings')}-${i18n.t('quickReply')}`,
  71: `${i18n.t('chatRoomSettings')}-${i18n.t('featureSettings')}`,

  1: i18n.t('platformSettings'),
  2: `${i18n.t('platformSettings')}-${i18n.t('gameData')}`,
  3: `${i18n.t('platformSettings')}-${i18n.t('gameProviderSorting')}`,
  4: `${i18n.t('platformSettings')}-${i18n.t('gameList')}`,
  5: `${i18n.t('platformSettings')}-${i18n.t('gameProviderCashbackSettings')}`,
  6: `${i18n.t('platformSettings')}-${i18n.t('uploadLicense')}`,
  93: `${i18n.t('platformSettings')}-${i18n.t('customerServiceSoftwareSetting')}`,

  7: `${i18n.t('permissionManagement')}-${i18n.t('permissionName')}`,
  8: `${i18n.t('permissionManagement')}-${i18n.t('backOfficeAccount')}`,
  9: i18n.t('announcementSettings'),
  // 10: `${i18n.t('announcementSettings')}-${i18n.t('systemAnnouncement')}`,
  11: i18n.t('maintenanceSettings'),
  // 12: i18n.t('gameManagement'),

  // 操作記錄(舊)
  13: i18n.t('operationRecords'),

  77: i18n.t('loginRecords'),

  // 操作記錄(新)
  74: i18n.t('operationRecords'),
}

// 權限管理: 操作
export const operationi18n: any = {
  // 會員
  10101: i18n.t('view'),
  10102: i18n.t('add'),
  // 10103: `${i18n.t('remark')}-${i18n.t('edit')}`,
  10104: i18n.t('export'),
  10105: i18n.t('batchSetting'),

  10201: i18n.t('view'),
  10202: i18n.t('edit'),
  10203: `${i18n.t('remark')}-${i18n.t('view')}`,
  10204: `${i18n.t('remark')}-${i18n.t('add')}`,
  10205: `${i18n.t('remark')}-${i18n.t('edit')}`,
  10206: `${i18n.t('remark')}-${i18n.t('delete')}`,

  10301: i18n.t('view'),
  10302: i18n.t('edit'),
  10303: i18n.t('batchSetting'),

  10401: i18n.t('view'),
  10402: i18n.t('add'),
  10403: i18n.t('edit'),
  10404: i18n.t('delete'),

  10501: i18n.t('view'),
  10503: i18n.t('add'),
  10504: i18n.t('delete'),
  10505: i18n.t('edit'),

  10601: i18n.t('view'),
  10602: i18n.t('edit'),

  10701: i18n.t('view'),

  10801: i18n.t('view'),
  10802: i18n.t('add'),
  10803: i18n.t('edit'),
  10804: i18n.t('audit'),

  10901: i18n.t('view'),
  10903: i18n.t('audit'),

  11001: i18n.t('view'),
  11002: i18n.t('add'),


  11501: i18n.t('view'),
  11502: i18n.t('setting'),
  11503: i18n.t('edit'),
  11504: i18n.t('delete'),

  11101: i18n.t('view'),
  11102: i18n.t('freezeMember'),
  11103: i18n.t('blockIP'),
  11104: i18n.t('unblockIP'),

  11201: i18n.t('view'),
  11202: i18n.t('add'),
  11203: i18n.t('edit'),
  11204: i18n.t('delete'),

  11301: i18n.t('view'),
  11302: i18n.t('add'),
  11303: `${i18n.t('remark')}-${i18n.t('edit')}`,

  11401: i18n.t('view'),
  11402: i18n.t('add'),
  11403: i18n.t('edit'),
  11404: `${i18n.t('memberAccount')}-${i18n.t('edit')}`,

  11601: i18n.t('view'),
  11602: i18n.t('add'),
  11603: i18n.t('edit'),
  11604: i18n.t('delete'),
  11605: i18n.t('modifyGroupsInBatches'),

  11701: i18n.t('view'),
  11702: i18n.t('batchSetting'),
  11703: i18n.t('lockMemberGroup'),

  // 財務
  21601: i18n.t('view'),
  21602: `${i18n.t('oneClickrevoke')}/${i18n.t('revoke')}`,
  21603: i18n.t('deductExpectedValidBet'),

  20101: i18n.t('view'),
  20110: i18n.t('export'),
  20104: `${i18n.t('audit')}-${i18n.t('customerServiceReview')}`,
  20106: `${i18n.t('audit')}-${i18n.t('customerServiceSupervisorReview')}`,
  20108: `${i18n.t('audit')}-${i18n.t('financialAudit')}`,

  20201: i18n.t('view'),
  20205: i18n.t('export'),
  20203: `${i18n.t('audit')}-${i18n.t('financialAudit')}`,

  20301: i18n.t('view'),
  20306: i18n.t('export'),
  20303: i18n.t('add'),
  20304: i18n.t('audit'),

  20401: i18n.t('view'),
  20402: i18n.t('add'),
  20403: i18n.t('edit'),
  20404: i18n.t('delete'),

  20601: i18n.t('view'),

  20701: i18n.t('view'),
  20702: i18n.t('add'),
  20703: i18n.t('edit'),
  20705: i18n.t('delete'),

  20901: i18n.t('view'),

  21001: i18n.t('view'),
  21003: i18n.t('edit'),

  21101: i18n.t('view'),

  21201: i18n.t('view'),
  21203: i18n.t('edit'),

  21301: i18n.t('view'),

  21401: i18n.t('view'),
  21402: i18n.t('edit'),
  21403: i18n.t('status'),

  21501: i18n.t('view'),
  21502: i18n.t('edit'),
  21503: i18n.t('status'),

  // 優惠 
  30101: i18n.t('view'),
  30102: i18n.t('add'),
  30103: i18n.t('edit'),
  30104: i18n.t('delete'),

  30201: i18n.t('view'),
  30202: i18n.t('add'),
  30203: i18n.t('edit'),
  30204: i18n.t('delete'),

  30301: i18n.t('view'),
  30302: i18n.t('add'),
  30303: i18n.t('audit'),

  30401: i18n.t('view'),
  30402: i18n.t('audit'),

  30501: i18n.t('view'),

  30601: i18n.t('view'),
  30602: i18n.t('edit'),

  30701: i18n.t('view'),
  30702: i18n.t('add'),
  30703: i18n.t('edit'),
  30704: i18n.t('delete'),

  30801: i18n.t('view'),

  30901: i18n.t('view'),
  30902: i18n.t('add'),
  30903: i18n.t('edit'),
  30904: i18n.t('delete'),
  30905: i18n.t('export'),

  31001: i18n.t('view'),
  31002: i18n.t('export'),

  // 全民代理
  80101: i18n.t('view'),
  80102: i18n.t('edit'),

  80201: i18n.t('view'),
  80202: i18n.t('export'),
  80203: i18n.t('currencyExchange'),

  80301: i18n.t('view'),
  80302: i18n.t('export'),
  80303: i18n.t('audit'),
  80304: i18n.t('rebateRecalculation'),

  // 代理
  40101: i18n.t('view'),
  40102: i18n.t('export'),
  40103: i18n.t('accounting'),

  40201: i18n.t('view'),
  40202: `${i18n.t('add')}-${i18n.t('generalAgent')}`,
  40203: i18n.t('edit'),
  40204: i18n.t('currencyExchange'),
  40205: `${i18n.t('add')}-${i18n.t('agent')}`,
  40206: i18n.t('manualVerification'),

  40301: i18n.t('view'),

  40401: i18n.t('view'),

  40501: i18n.t('view'),
  40502: i18n.t('export'),
  40504: `${i18n.t('audit')}-${i18n.t('customerServiceReview')}`,
  40506: `${i18n.t('audit')}-${i18n.t('customerServiceSupervisorReview')}`,
  40508: `${i18n.t('audit')}-${i18n.t('financialAudit')}`,

  40601: i18n.t('view'),
  40602: i18n.t('export'),
  40603: `${i18n.t('audit')}-${i18n.t('financialAudit')}`,

  40701: i18n.t('view'),
  40702: i18n.t('export'),
  40703: i18n.t('add'),
  40704: i18n.t('audit'),

  40801: i18n.t('view'),
  40803: i18n.t('delete'),

  40901: i18n.t('view'),
  40906: i18n.t('manualDispatch'),
  40908: i18n.t('batchDistribution'),

  41001: i18n.t('view'),
  41002: i18n.t('export'),

  41101: i18n.t('view'),
  41102: i18n.t('add'),
  41103: i18n.t('edit'),
  41105: `${i18n.t('agentAccount')}-${i18n.t('edit')}`,

  // 前台頁面
  50101: i18n.t('view'),
  50102: i18n.t('add'),
  50103: i18n.t('edit'),
  50104: i18n.t('delete'),

  50201: i18n.t('view'),
  50202: i18n.t('add'),
  50203: i18n.t('edit'),
  50204: i18n.t('delete'),

  50301: i18n.t('view'),
  50302: i18n.t('edit'),

  50501: i18n.t('view'),
  50502: i18n.t('edit'),

  50401: i18n.t('view'),
  50402: i18n.t('add'),
  50403: i18n.t('edit'),
  50405: i18n.t('delete'),

  50601: i18n.t('view'),
  50602: i18n.t('edit'),

  // 統計報表
  60101: i18n.t('view'),
  60102: i18n.t('export'),

  60301: i18n.t('view'),
  60302: i18n.t('export'),

  60401: i18n.t('view'),
  60402: i18n.t('export'),

  60501: i18n.t('view'),

  60601: i18n.t('view'),

  60701: i18n.t('view'),
  60702: i18n.t('export'),

  60801: i18n.t('view'),
  60802: i18n.t('export'),

  60901: i18n.t('view'),
  60902: i18n.t('export'),

  61001: i18n.t('view'),
  61002: i18n.t('setting'),


  // 聊天室
  70101: i18n.t('view'),
  70103: i18n.t('privateMessage'),

  70201: i18n.t('view'),
  70202: i18n.t('edit'),

  70301: i18n.t('view'),
  70302: `${i18n.t('add')}-${i18n.t('type')}`,
  70303: `${i18n.t('add')}-${i18n.t('content')}`,
  70304: i18n.t('edit'),
  70305: i18n.t('delete'),

  70401: i18n.t('view'),
  70402: i18n.t('edit'),

  // 進階
  '00101': i18n.t('view'),
  '00102': i18n.t('edit'),

  '00201': i18n.t('view'),
  '00202': i18n.t('edit'),

  '00301': i18n.t('view'),
  '00302': i18n.t('edit'),

  '00401': i18n.t('view'),
  '00402': i18n.t('edit'),

  '00501': i18n.t('view'),
  '00503': i18n.t('edit'),

  '00601': i18n.t('view'),
  '00602': i18n.t('add'),
  '00603': i18n.t('edit'),
  '00604': i18n.t('delete'),

  '01601': i18n.t('view'),
  '01602': i18n.t('edit'),

  '00701': i18n.t('view'),
  '00703': i18n.t('add'),
  '00704': i18n.t('edit'),

  '00801': i18n.t('view'),
  '00802': i18n.t('add'),
  '00803': i18n.t('edit'),
  '00804': i18n.t('modifyPassword'),

  '00901': i18n.t('view'),
  '00902': i18n.t('add'),
  '00903': i18n.t('edit'),
  '00904': i18n.t('delete'),

  // '01001': i18n.t('view'),
  // '01002': i18n.t('add'),
  // '01003': i18n.t('edit'),
  // '01004': i18n.t('delete'),

  '01101': i18n.t('view'),
  '01102': i18n.t('add'),
  '01103': i18n.t('edit'),
  '01104': i18n.t('delete'),

  '01201': i18n.t('view'),
  '01202': i18n.t('edit'),

  '01401': i18n.t('view'),
  '01501': i18n.t('view'),
}

// 代理權限
export const agenti18n = ($sc: any = true, $s: any = { CommissionModule: '1' }) => {
  return [
    {
      key: '1', MenuName: i18n.t('team'), name: i18n.t('member'), rowSpan: 2, child: [
        { key: '0101', name: i18n.t('view') },
        { key: '0102', name: `${i18n.t('add')}／${i18n.t('edit')}` },
        { key: '0103', name: i18n.t('remark') },
        { key: '0104', name: i18n.t('transferAdd') },
        ...(!$sc ? [
          { key: '0105', name: i18n.t('transferDeduction') },
        ] : []),
        { key: '0106', name: i18n.t('cashFlowRecord') },
      ]
    },
    {
      key: '2', MenuName: i18n.t('team'), name: i18n.t('agent'), child: [
        { key: '0201', name: i18n.t('view') },
        { key: '0202', name: `${i18n.t('add')}／${i18n.t('edit')}` },
        { key: '0203', name: i18n.t('export') },
        { key: '0204', name: i18n.t('remark') },
      ]
    },
    {
      key: '3', MenuName: i18n.t('myWallet'), name: i18n.t('myWallet'), rowSpan: 1, child: [
        { key: '0301', name: i18n.t('view') },
        ...($sc ? [
          { key: '0302', name: i18n.t('deposit') },
        ] : []),
        { key: '0303', name: i18n.t('transfer') },
        ...($sc ? [
          { key: '0304', name: i18n.t('withdrawal') },
        ] : []),
      ]
    },
    {
      key: '4', MenuName: i18n.t('advanced'), name: i18n.t('memberRebateSettings'), rowSpan: 1, child: [
        { key: '0401', name: i18n.t('view') },
        { key: '0402', name: i18n.t('edit') },
      ]
    },
  ].filter(item => !($s.CommissionModule === '2' && item.key === '4'))
}

// 操作記錄
// NOTE: 目前已改用api自動產生 此陣列沒用到
export const operations: any = [
  /* 進階 */
  // 站台設置
  {
    ManagementId: 1,
    options: [{ '00102': i18n.t('edit') }],
  },
  // 站台設置-遊戲資料
  {
    ManagementId: 2,
    options: [{ '00202': i18n.t('edit') }],
  },
  // 站台設置-遊戲商排序
  {
    ManagementId: 3,
    options: [{ '00302': i18n.t('edit') }],
  },
  // 站台設置-遊戲清單
  {
    ManagementId: 4,
    options: [{ '00402': i18n.t('edit') }],
  },
  // 站台設置-遊戲商反水設定
  {
    ManagementId: 5,
    options: [{ '00503': i18n.t('edit') }],
  },
  // 站台設置-上傳牌照
  {
    ManagementId: 6,
    options: [{ '00602': i18n.t('add') }, { '00603': i18n.t('edit') }, { '00604': i18n.t('delete') }],
  },
  // 權限管理-權限名稱
  {
    ManagementId: 7,
    options: [{ '00703': i18n.t('add') }, { '00704': i18n.t('edit') }],
  },
  // 權限管理-後台帳號
  {
    ManagementId: 8,
    options: [{ '00802': i18n.t('add') }, { '00803': i18n.t('edit') }, { '00804': i18n.t('modifyPassword') }],
  },
  // 公告設定
  {
    ManagementId: 9,
    options: [{ '00902': i18n.t('add') }, { '00903': i18n.t('edit') }, { '00904': i18n.t('delete') }],
  },
  // 維護設定
  {
    ManagementId: 11,
    options: [{ '01102': i18n.t('add') }, { '01103': i18n.t('edit') }, { '01104': i18n.t('delete') }],
  },
  // 操作記錄
  {
    ManagementId: 13,
    options: [{}],
  },
  /* 會員 */
  // 會員列表
  {
    ManagementId: 14,
    options: [{ 10102: i18n.t('add') }, { 10103: `${i18n.t('remark')}-${i18n.t('edit')}` }, { 10104: i18n.t('export') }, { 10105: i18n.t('batchSetting') }],
  },
  // 會員列表-基本資料
  {
    ManagementId: 15,
    options: [{ 10202: i18n.t('edit') }, { 10204: `${i18n.t('remark')}-${i18n.t('delete')}` }, { 10205: `${i18n.t('remark')}-${i18n.t('edit')}` }],
  },
  // 會員列表-遊戲商資料
  {
    ManagementId: 16,
    options: [{ 10302: i18n.t('edit') }, { 10303: i18n.t('batchSetting') }],
  },
  // 會員列表-聯絡資訊
  {
    ManagementId: 17,
    options: [{ 10402: i18n.t('add') }, { 10403: i18n.t('edit') }, { 10404: i18n.t('delete') }],
    // 會員列表-銀行卡列表
  },
  {
    ManagementId: 18,
    options: [{ 10503: i18n.t('add') }, { 10504: i18n.t('delete') }, { 10505: i18n.t('edit') }],
  },
  // 會員列表-返水列表
  {
    ManagementId: 19,
    options: [{ 10602: i18n.t('edit') }],
    // 帳戶紀錄
  },
  {
    ManagementId: 20,
    options: [{}],
  },
  // 實名驗證
  {
    ManagementId: 21,
    options: [{ 10802: i18n.t('add') }, { 10803: i18n.t('edit') }, { 10804: i18n.t('audit') }],
  },
  // 銀行卡驗證
  {
    ManagementId: 22,
    options: [{ 10903: i18n.t('audit') }],
  },
  // 即時訊息
  {
    ManagementId: 23,
    options: [{ 11002: i18n.t('add') }],
  },
  // 會員IP檢核
  {
    ManagementId: 24,
    options: [{ 11102: i18n.t('freezeMember') }, { 11103: i18n.t('blockIP') }, { 11104: i18n.t('unblockIP') }],
  },
  // 會員IP檢核-IP封鎖管理
  {
    ManagementId: 25,
    options: [{ 11202: i18n.t('add') }, { 11203: i18n.t('edit') }, { 11204: i18n.t('delete') }],
  },
  // 遊戲IP檢核
  {
    ManagementId: 26,
    options: [{ 11302: i18n.t('add') }, { 11303: `${i18n.t('remark')}-${i18n.t('edit')}` }],
    /* 財務 */
  },
  // 提款查詢
  {
    ManagementId: 27,
    options: [{
      20110: i18n.t('export')
    }, { 20104: `${i18n.t('audit')}-${i18n.t('customerServiceReview')}` },
    { 20106: `${i18n.t('audit')}-${i18n.t('customerServiceSupervisorReview')}` }, {
      20108: `${i18n.t('audit')}-${i18n.t('financialAudit')}`,
    }],
  },
  // 入款查詢
  {
    ManagementId: 28,
    options: [{ 20205: i18n.t('export') }, { 20203: `${i18n.t('audit')}-${i18n.t('financialAudit')}` }],
  },
  // 調帳查詢
  {
    ManagementId: 29,
    options: [{ 20306: i18n.t('export') }, { 20303: i18n.t('add') }, { 20304: i18n.t('audit'), }],
  },
  // 轉帳出入管管理-提款
  {
    ManagementId: 30,
    options: [{ 20402: i18n.t('add') }, { 20403: i18n.t('edit') }, { 20404: i18n.t('delete') }],
  },
  // 轉帳出入管管理-提款-總計
  {
    ManagementId: 32,
    options: [{}],
  },
  // 轉帳出入管管理-入款
  {
    ManagementId: 33,
    options: [{ 20702: i18n.t('add') }, { 20703: i18n.t('edit') }, { 20705: i18n.t('delete') }],
  },
  // 轉帳出入管管理-入款-總計
  {
    ManagementId: 35,
    options: [{}],
  },
  // 三方出入款管理-提款
  {
    ManagementId: 36,
    options: [{ 21003: i18n.t('edit') }],
    // 三方出入款管理-提款-總計
  },
  {
    ManagementId: 37,
    options: [{}],
  },
  // 三方出入款管理-入款
  {
    ManagementId: 38,
    options: [{ 21203: i18n.t('edit') }],
  },
  // 三方出入款管理-入款-總計
  {
    ManagementId: 39,
    options: [{}],
  },
  /* 優惠 */
  // 優惠活動管理
  {
    ManagementId: 40,
    options: [{ 30102: i18n.t('add') }, { 30103: i18n.t('edit') }, { 30104: i18n.t('delete') }],
  },
  // 會員等級設定
  {
    ManagementId: 41,
    options: [{ 30202: i18n.t('add') }, { 30203: i18n.t('edit') }, { 30204: i18n.t('delete') }],
  },
  // 優惠活動查詢
  {
    ManagementId: 42,
    options: [{ 30302: i18n.t('add') }, { 30303: i18n.t('audit') }],
  },
  // 返水優惠查詢
  {
    ManagementId: 43,
    options: [{ 30402: i18n.t('audit') }],
  },
  // 會員等級優惠查詢
  {
    ManagementId: 44,
    options: [{}],
  },
  /* 代理 */
  // 總代列表
  {
    ManagementId: 45,
    options: [{ 40102: i18n.t('export') }, { 40103: i18n.t('accounting') }],
  },
  // 總代列表-編輯
  {
    ManagementId: 46,
    options: [{ 40202: `${i18n.t('add')}-${i18n.t('generalAgent')}` },
    { 40203: i18n.t('edit') }, { 40204: i18n.t('currencyExchange') },
    { 40205: `${i18n.t('add')}-${i18n.t('agent')}`, 40206: i18n.t('manualVerification') }],
  },
  // 總代列表-結算報表
  {
    ManagementId: 47,
    options: [{}],
  },
  // 總代列表-每日報表
  {
    ManagementId: 48,
    options: [{}],
  },
  // 代理提款查詢
  {
    ManagementId: 49,
    options: [{ 40502: i18n.t('export') }, { 40504: `${i18n.t('audit')}-${i18n.t('customerServiceReview')}` },
    { 40506: `${i18n.t('audit')}-${i18n.t('customerServiceSupervisorReview')}` }, { 40508: `${i18n.t('audit')}-${i18n.t('financialAudit')}` }],
  },
  // 代理入款查詢
  {
    ManagementId: 50,
    options: [{ 40602: i18n.t('export') }, { 40603: `${i18n.t('audit')}-${i18n.t('financialAudit')}` }],
  },
  // 代理調帳查詢
  {
    ManagementId: 51,
    options: [{ 40702: i18n.t('export') }, { 40703: i18n.t('add'), 40704: i18n.t('audit') }],
  },
  // 代理銀行卡查詢
  {
    ManagementId: 52,
    options: [{ 40803: i18n.t('delete') }],
  },
  // 代理報表審核
  {
    ManagementId: 53,
    options: [{ 40906: i18n.t('manualDispatch') }, { 40908: i18n.t('batchDistribution') }],
  },
  // 代理錢包紀錄
  {
    ManagementId: 54,
    options: [{ 41002: i18n.t('export') }],
  },
  // 代理標籤
  {
    ManagementId: 55,
    options: [{ 41102: i18n.t('add') }, { 41103: i18n.t('edit') }, { 41105: `${i18n.t('agentAccount')}-${i18n.t('edit')}` }],
  },
  /* 前台頁面 */
  // 前台文案
  {
    ManagementId: 56,
    options: [{ 50102: i18n.t('add') }, { 50103: i18n.t('edit') }, { 50104: i18n.t('delete') }],
  },
  // 輪播圖
  {
    ManagementId: 57,
    options: [{ 50202: i18n.t('add') }, { 50203: i18n.t('edit') }, { 50204: i18n.t('delete') }],
  },
  // 彈窗設定
  {
    ManagementId: 58,
    options: [{ 50302: i18n.t('edit') }],
  },
  // SEO設置
  {
    ManagementId: 59,
    options: [{ 50402: i18n.t('add') }, { 50403: i18n.t('edit') }, { 50405: i18n.t('delete') }],
  },
  /* 統計報表 */
  // 投注報表
  {
    ManagementId: 60,
    options: [{}],
  },
  // 數據總覽
  {
    ManagementId: 61,
    options: [{}],
  },
  // 營運報表
  {
    ManagementId: 62,
    options: [{ 60302: i18n.t('export') }],
  },
  // 遊戲統計分析
  {
    ManagementId: 63,
    options: [{ 60402: i18n.t('export') }],
  },
  // 代理統計分析
  {
    ManagementId: 64,
    options: [{}],
  },
  // 代理優惠統計
  {
    ManagementId: 65,
    options: [{}],
  },
  // 會員排名
  {
    ManagementId: 66,
    options: [{ 60702: i18n.t('export') }],
  },
  // 會員輸贏報表
  {
    ManagementId: 67,
    options: [{ 60802: i18n.t('export') }],
  },
  // 交收報表
  {
    ManagementId: 72,
    options: [{ 60902: i18n.t('export') }],
  },
  /* 聊天室 */
  // 聊天室紀錄
  {
    ManagementId: 68,
    options: [{ 70103: i18n.t('privateMessage') }],
  },
  // 聊天室設置-帳號查詢
  {
    ManagementId: 69,
    options: [{ 70202: i18n.t('edit') }],
  },
  // 聊天室設置-快捷回覆
  {
    ManagementId: 70,
    options: [{ 70302: `${i18n.t('add')}-${i18n.t('type')}` },
    { 70303: `${i18n.t('add')}-${i18n.t('content')}` },
    { 70304: i18n.t('edit') }, { 70305: i18n.t('delete') }],
  },
  // 聊天室設置-功能設定
  {
    ManagementId: 71,
    options: [{ 70402: i18n.t('edit') }]
  },
]