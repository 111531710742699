import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, Tooltip, message, DatePicker } from "antd";
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormInputPosition } from 'components/form.component';
import { LayoutNav, UploadImage } from 'components/layout.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import { AMOUNT_MAX } from 'constants/system';
import dayjs from 'dayjs';
import { PROMOTION_STATE } from 'enum/promotion';
import { WHETHER } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, timeL2S, verify } from "utils/common";

// 新增/編輯體驗金

const PageMain: React.FC = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopUp, setIsPopUp] = useState();
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

// 禁用今天之前的所有日期
  const disabledStartDate = (current: any) => {
    return current && current < dayjs().startOf('day');
  };
  // 禁用开始时间之前的日期
  const disabledEndDate = (current: any) => {
    return current && startDate && current < dayjs(startDate).startOf('day');
  };
  // 禁用开始时间之前的时间
  const disabledStartTime = () => {
    const now = dayjs();
    return {
      disabledHours: () => {
        const hours = [];
        for (let i = 0; i < 24; i++) {
          if (i < now.hour()) {
            hours.push(i);
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour: any) => {
        const minutes = [];
        if (selectedHour === now.hour()) {
          for (let i = 0; i < 60; i++) {
            if (i < now.minute()) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      },
      disabledSeconds: (selectedHour: any, selectedMinute: any) => {
        const seconds = [];
        if (selectedHour === now.hour() && selectedMinute === now.minute()) {
          for (let i = 0; i < 60; i++) {
            if (i < now.second()) {
              seconds.push(i);
            }
          }
        }
        return seconds;
      },
    };
  };
  // 禁用开始时间之前的结束时间
  const disabledEndTime = (endDate: any) => {
    const start = dayjs(startDate);
    return {
      disabledHours: () => {
        const hours = [];
        if (startDate && dayjs(endDate).isSame(start, 'day')) {
          for (let i = 0; i < 24; i++) {
            if (i < start.hour()) {
              hours.push(i);
            }
          }
        }
        return hours;
      },
      disabledMinutes: (selectedHour: any) => {
        const minutes = [];
        if (
          startDate &&
          dayjs(endDate).isSame(start, 'day') &&
          selectedHour === start.hour()
        ) {
          for (let i = 0; i < 60; i++) {
            if (i < start.minute()) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      },
      disabledSeconds: (selectedHour: any, selectedMinute: any) => {
        const seconds = [];
        if (
          startDate &&
          dayjs(endDate).isSame(start, 'day') &&
          selectedHour === start.hour() &&
          selectedMinute === start.minute()
        ) {
          for (let i = 0; i < 60; i++) {
            if (i < start.second()) {
              seconds.push(i);
            }
          }
        }
        return seconds;
      },
    };
  };

  const { data: BonusInfo, isValidating } = $get({
    url: 'api/bonus/trial/info',
  })

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (BonusInfo && BonusInfo?.Data) {
      const { RewardAmount, SameIpReceiveCount } = JSON.parse(BonusInfo.Data.BonusRuleSetting || '{}');
      const { StartDate, EndDate } = BonusInfo?.Data;
      form.setFieldsValue({
        promotionName: BonusInfo.Data.Name,
        IsPopUp: BonusInfo.Data.IsPopUp,
        IsRedirect: BonusInfo.Data.IsRedirect,
        oneTimePromotion: 1,
        promotionAmount: RewardAmount,
        rolloverRate: BonusInfo.Data.ValidBetTimes,
        applicationMethod: 0,
        distributionMethod: BonusInfo.Data.SettleMethod,
        sameIpReceiveCount: SameIpReceiveCount,
        status: BonusInfo.Data.Status,
        Position: BonusInfo.Data.Position,
        StartDate: dayjs(StartDate) ,
        internalContent: BonusInfo.Data.Content
      })
      setImageData(BonusInfo.Data.Photo ? 
        [{
          uid: BonusInfo.Data.Photo,
          name: BonusInfo.Data.Photo,
          url: BonusInfo.Data.Photo,
        }] : []
      )
      setEditorContent(BonusInfo.Data.Content);
      if (EndDate === '0000-00-00 00:00:00') {
        form.setFieldValue('EndDate', null);
        setEndDate(null);
      } else {
        form.setFieldValue('EndDate', dayjs(EndDate));
        setEndDate(dayjs(EndDate));
      }
      setStartDate(dayjs(StartDate));
      setIsPopUp(BonusInfo.Data.IsPopUp);
    }
    else {
      form.setFieldsValue({
        IsRedirect: 0,
        oneTimePromotion: 1,
        applicationMethod: 0,
      })
    }
  }, [BonusInfo]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/bonus/trial/upsert',
      send: {
        Name: formData.promotionName,
        IsPopUp: formData.IsPopUp,
        IsRedirect: formData.IsRedirect,
        RewardAmount: Number(formData.promotionAmount),
        ValidBetTimes: Number(formData.rolloverRate),
        SettleMethod: formData.distributionMethod,
        SameIpReceiveCount: Number(formData.sameIpReceiveCount),
        Status: formData.status,
        Position: Number(formData.Position),
        StartDate: timeL2S(dayjs(startDate).format('YYYY-MM-DD HH:mm:ss')),
        EndDate: endDate ? timeL2S(dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')) : null,
        Photo: imageData[0] ? [imageData[0].url] : [],
        Content: editorContent,
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        navigate('/promotion');
      },
      resCode: RESPONSE_CODE_BONUS
    }, setLoading)
  }

  // 相同IP可領取次數欄位已上架不可編輯
  const isShelved = BonusInfo?.Data?.Status === PROMOTION_STATE.shelved;

  // 相同IP可領取次數欄位欲編輯的數字需大於當下數字規則
  const { SameIpReceiveCount } = JSON.parse(BonusInfo?.Data?.BonusRuleSetting || '{}') ;
  const greaterThanSameIpReceiveCountRule = {
    validator: (_:any, value: any) => {
      if (value < SameIpReceiveCount) {
        return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${SameIpReceiveCount}`);
      }
      return Promise.resolve();
    },
  };

  return (
    <div id="container">
      <Content className="ph-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            {/* 類別與確認 */}
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item name="promotionName" className="w-12" label={i18n.t('promotionName')}
                             rules={[{ required: true, message: `${i18n.t('required')}` },
                               { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}>
                    <Input className="w-24" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="IsPopUp" className="w-12" label={i18n.t('popupDisplay')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      onChange={e => {
                        setIsPopUp(e);
                        form.setFieldValue('IsRedirect', WHETHER.none);
                      }}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="IsRedirect" className="w-12" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: isPopUp, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      disabled={!isPopUp}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 週期 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="oneTimePromotion" className="w-12" label={i18n.t('oneTimePromotion')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="promotionAmount" label={i18n.t('promotionAmount')} 
                    rules={verify({ point: 4,min: 1, max: AMOUNT_MAX })}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="applicationMethod" className="w-12" label={i18n.t('applicationMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('autoApplication')}` },
                        { value: 1, label: `${i18n.t('memberManualApplication')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="distributionMethod" className="w-12" label={i18n.t('distributionMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('directClaim')}` },
                        { value: 1, label: `${i18n.t('manualDispatch')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="sameIpReceiveCount" label={
                    <Row gutter={5}>
                      <Col>{i18n.t('sameIpReceiveCount')}</Col>
                    </Row>
                  } rules={[
                    ...verify({ point: 0, min : 1 }),
                    greaterThanSameIpReceiveCountRule
                  ]}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} disabled={isShelved}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="status" className="w-12" label={i18n.t('status')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <FormInputPosition className="w-12" />
                </Col>
                <Col>
                  <Form.Item name="StartDate" label={i18n.t('promotionStartTime')}
                             rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <DatePicker
                      showTime
                      disabledDate={disabledStartDate}
                      disabledTime={disabledStartTime}
                      onChange={(value) => setStartDate(value)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="EndDate"
                             label={
                               <Row gutter={6} align="middle">
                                 <Col>{i18n.t('promotionEndTime')}</Col>
                                 <Col>
                                   <Tooltip placement='top'
                                            title={i18n.t('IfTheEndTimeIsNotSetItWillBeRegardedAsAPermanentActivity')}>
                                     <InfoCircleFilled />
                                   </Tooltip>
                                 </Col>
                               </Row>
                             }>
                    <DatePicker
                      showTime
                      allowClear
                      disabled={!startDate}
                      disabledDate={disabledEndDate}
                      disabledTime={disabledEndTime}
                      placeholder={`${!endDate && i18n.t('permanent')}`}
                      onChange={(value) => setEndDate(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 上傳封面圖片 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="Photo" label={i18n.t('image')} 
                    rules={[{ required: !imageData[0], message: `${i18n.t('required')}` }]} required>
                    <UploadImage
                      name="Photo"
                      url={'/upload/images/public/bonus'}
                      preUrl={BonusInfo?.Data?.Photo || ''}
                      imageData={imageData}
                      setImageData={setImageData}
                      form={form}
                      remove
                      w={1920}
                      h={700}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 內文編輯器 */}
            <Col span={24}>
              <Form.Item name="internalContent" label={i18n.t('internalContent')}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} image />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content >
    </div >
  );
};

export default PageMain;