import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import dayjs from 'dayjs';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { MemberGroupSelect, MemberLevelSelect, ViewMemberList } from "components/member.component";
import { MEMBER_FREEZE, MEMBER_STATE } from 'enum/state';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { $get } from 'services';
import { enumToOptions, timeL2S, tzShift } from 'utils/common';

import { setSearchHistory } from 'slice/search.slice';
import { RootType } from 'store/configureStore';

// 會員列表

enum SEARCH_TYPE {
  phoneNumber = 1,
  email = 2,
  realName = 3,
  idNum = 4
}

const PageMain: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const [date, setDate] = useState([
    tzShift(dayjs().startOf('day'), 'YYYY-MM-DD HH:mm:ss'),
    tzShift(dayjs().endOf('day'), 'YYYY-MM-DD HH:mm:ss')
  ]);
  const [reset, setReset] = useState<boolean>(false);

  // 搜尋儲存
  const searchMember = useSelector((state: RootType) => state.Search.member);

  const defaultDate: number = 2;
  const [timeMode, setTimeMode] = useState<number>(defaultDate);
  const [memberSearchParam, SetMemberSearchParam] = useState<any>({
    Type: 0,
    PageIndex: 1,
    PageSize: 10,
  });

  const { data, mutate, isValidating } = $get({
    url: 'api/member/list',
    params: searchMember || memberSearchParam
  });

  useEffect(() => {
    if (location.search) {
      const IsOnline = Number(location.search.split('')[location.search.length - 1]);
      form.setFieldsValue({
        onlineType: IsOnline,
        TimeMode: 2
      });
      setTimeMode(2);
      SetMemberSearchParam({
        PageIndex: 1,
        PageSize: 10,
        Type: 0,
        IsOnline,
      })
    }
  }, [location.search]);

  const handleSearch = (formData: any) => {
    const obj: any = {
      PageIndex: 1,
      PageSize: memberSearchParam.PageSize,
      Type: 0,
      Account: formData.account,
      RefCode: formData.referralCode,
      IsOnline: formData.onlineType,
      UPMemberAccount: formData.recommendedMembers,
      AGAccount: formData.agentAccount,
      SAAccount: formData.masterAgent,
      Status: formData.Status,
      IsFreeze: formData.IsFreeze ? (formData.IsFreeze === MEMBER_FREEZE.normal ? 0 : 1) : '',
      LevelId: formData.LevelId,
      MemberGroupId: formData.MemberGroupId
    }
    switch (formData.searchType) {
      case 1:
        obj.PhoneNumber = formData.searchData;
        break;
      case 2:
        obj.Email = formData.searchData;
        break;
      case 3:
        obj.Name = formData.searchData;
        break;
      case 4:
        obj.IDNumber = formData.searchData;
        break;
    }

    switch (formData.TimeMode) {
      case 0:
        obj.LastLoginStartDate = timeL2S(date[0])
        obj.LastLoginEndDate = timeL2S(date[1])
        break;
      case 1:
        obj.CreateStartDate = timeL2S(date[0])
        obj.CreateEndDate = timeL2S(date[1])
        break;
    }

    SetMemberSearchParam(obj);
    dispatch(setSearchHistory({ member: obj }));
    mutate();
  };

  const onClear = () => {
    form.resetFields();
    setReset(!reset);
    SetMemberSearchParam({
      ...memberSearchParam,
      PageSize: 10,
      PageIndex: 1,
    })
  };

  const handlePageChange = ([current, pageSize]: any) => {
    const param = searchMember || memberSearchParam
    SetMemberSearchParam({ ...param, PageIndex: current, PageSize: pageSize })
    dispatch(setSearchHistory({ member: { ...param, PageIndex: current, PageSize: pageSize } }));
  }

  return (
    <div id="container">
      <Content className="pr-2 pl-2 pb-2">
        <Form form={form} onFinish={handleSearch} initialValues={{
          TimeMode: 2,
          searchType: 1
        }}>
          <Row align="middle" gutter={[12, 12]}>
            {/* 三排搜尋 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="account">
                    <Input placeholder={`${i18n.t('memberAccount')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="IsFreeze">
                    <Select
                      placeholder={i18n.t('status')}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...enumToOptions(MEMBER_FREEZE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="Status">
                    <Select
                      placeholder={i18n.t('audit')}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...enumToOptions(MEMBER_STATE)
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="LevelId">
                    <MemberLevelSelect name="LevelId" form={form} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="searchType">
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      onSelect={() => form.resetFields(['searchData'])}
                      options={enumToOptions(SEARCH_TYPE)}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="searchData">
                    <Input placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="referralCode">
                    <Input placeholder={`${i18n.t('promotionCode')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="recommendedMembers">
                    <Input placeholder={`${i18n.t('recommendedMembers')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="agentAccount">
                    <Input placeholder={`${i18n.t('agentAccount')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="masterAgent">
                    <Input placeholder={`${i18n.t('masterAgent')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="onlineType">
                    <Select
                      placeholder={i18n.t('onlineStatus')}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        { value: 1, label: i18n.t('Online') },
                        { value: 0, label: i18n.t('Offline') },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="MemberGroupId">
                    <MemberGroupSelect name="MemberGroupId" form={form} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col className="w-12">
                  <Form.Item name="TimeMode">
                    <Select
                      options={[
                        { value: 0, label: `${i18n.t('lastLoginTime')}` },
                        { value: 1, label: `${i18n.t('createTime')}` },
                        { value: 2, label: `${i18n.t('noTimeLimit')}` },
                      ]}
                      onChange={setTimeMode}
                    />
                  </Form.Item>
                </Col>
                <DatePickerCol width={180} date={date} setDate={setDate} initDate={reset} displayCom={['Picker']} disabled={timeMode === 2} />
                <Col>
                  <Button type="primary" htmlType="submit" className="mr-1">{i18n.t('search')}</Button>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <ViewMemberList data={data} mutate={mutate} searchParam={searchMember || memberSearchParam} isValidating={isValidating} />
          </Row>
          <LayoutPagination total={data?.TotalRecord} setPage={handlePageChange}
            page={[(searchMember || memberSearchParam).PageIndex, (searchMember || memberSearchParam).PageSize]} />
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;