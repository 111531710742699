import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import type { ActionFunctionArgs } from "react-router-dom";
import { useRouteError } from "react-router-dom";

import PageMember from "pages/admin/member";
import PageMemberAccount from "pages/admin/member/account";
import PageMemberBank from "pages/admin/member/bank";
import PageMemberBlockIpGame from "pages/admin/member/block-ip-game";
import PageMemberBlockIpMember from "pages/admin/member/block-ip-member";
import PageMemberContact from "pages/admin/member/contact";
import PageMemberInfo from "pages/admin/member/info";
import PageMemberGameIp from "pages/admin/member/ip-game";
import PageMemberIp from "pages/admin/member/ip-mamber";
import PageMemberMessage from "pages/admin/member/message";
import PageMemberProvider from "pages/admin/member/provider";
import PageMemberRebate from "pages/admin/member/rebate";
import PageMemberVerification from "pages/admin/member/verification";
import PageMemberWithdraw from "pages/admin/member/withdraw";
import PageMemberTag from "pages/admin/member/tag";
import PageMemberGroup from "pages/admin/member/group";
import PageMemberGroupInfo from "pages/admin/member/group-member-info";

async function loader() {
  try {

    return {
      profile: {}
    };
    
  } catch (e) {
    throw new Error("無法取得loader資料");
  };
};

async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  switch (formData.get("action")) {
    case "add":
      const todo = formData.get("todo");
      if (!todo) throw new Error("表單資料有缺失");
    break;
    case "delete":
      const id = formData.get("todoId");
      return { ok: true };

    default:
      break;
  }

  return { ok: true };
  // return new Response(null, {
  //   status: 302,
  //   headers: { Location: '/ddd' },
  // });
}

function ErrorBoundary() {
  const error = useRouteError() as Error;
  return (
    <div id="container">
      {/*<LayoutNav />*/}
      <Content className="pr-2 pl-2 pb-2">
        {`系統錯誤：${error.message}`}
      </Content>
    </div>
  );
};

export const memberModule = [
  {
    path: "/", ErrorBoundary,
    children: [
      { path: '/member', element: <PageMember/> },
      { path: '/member/info/:id/:account/:agId', element: <PageMemberInfo/> },
      { path: '/member/provider/:id/:account/:agId', element: <PageMemberProvider/> },
      { path: '/member/contact/:id/:account/:agId', element: <PageMemberContact/> },
      { path: '/member/bank/:id/:account/:agId', element: <PageMemberBank/> },
      { path: '/member/rebate/:id/:account/:agId', element: <PageMemberRebate/> },
      { path: '/member/account/:account?', element: <PageMemberAccount/> },
      { path: '/member/verification', element: <PageMemberVerification/> },
      { path: '/member/withdraw/:status?', element: <PageMemberWithdraw/> },
      { path: '/member/message', element: <PageMemberMessage/> },

      { path: '/member/ip-member', element: <PageMemberIp/> },
      { path: '/member/block-ip-member', element: <PageMemberBlockIpMember/> },
      { path: '/member/ip-game', element: <PageMemberGameIp/> },
      { path: '/member/block-ip-game', element: <PageMemberBlockIpGame/> },
      { path: '/member/tag', element: <PageMemberTag/> },
      { path: '/member/group', element: <PageMemberGroup/> },
      { path: '/member/group/member-info/:id', element: <PageMemberGroupInfo/> },
    ],
  },
]